/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ClassRelationshipsCard } from "frontend/components/stages/classbrowser/cards/ClassRelationshipsCard";
import "../cards/CardContainer.scss";
import { ElementAttributes } from "frontend/api/Interfaces";
import { ExternalDocsSummaryCard } from "../../../controls/ExternalDocsSummaryCard";
import { UsesCard } from "frontend/components/stages/classbrowser/cards/UsesCard";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { AspectTabView } from "frontend/components/controls/AspectTabView";

type Props = ElementAttributes;

export class EntityClassDetails extends React.Component<Props> {
  public static modelDocsPath = "https://www.itwinjs.org/bis/intro/model-fundamentals/";
  public static elementAspectDocsPath = "https://www.itwinjs.org/bis/intro/elementaspect-fundamentals/";
  public static elementDocsPath = "https://www.itwinjs.org/bis/intro/element-fundamentals/";
  private static _dataProvider = async (id: string) => ImseFrontend.instance.getMixinsUsedByEntityClass(id);

  private getDocUrl(name: string) {
    switch (name) {
      case "Model":
        return EntityClassDetails.modelDocsPath;
      case "ElementAspect":
        return EntityClassDetails.elementAspectDocsPath;
      case "Element":
        return EntityClassDetails.elementDocsPath;
      default:
        return "";
    }
  }

  public override render() {
    return (
      <div className="details-page">
        <div className="card-container">
          <ClassRelationshipsCard/>
          <ExternalDocsSummaryCard resourcePath={this.getDocUrl(this.props.name)}/>
          <UsesCard title="Implements:" dataProvider={EntityClassDetails._dataProvider} id={this.props.id}/>
        </div>
        <AspectTabView/>
      </div>
    );
  }
}
