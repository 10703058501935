import React from "react";
import { Icon } from "@itwin/itwinui-react";

export function BinaryArrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="binary_array_24">
        <path fill="#fff" d="M1 2h22v13H1z" />
        <path d="M4 1.5H.5v14H4 .5v-14z" stroke="#000" />
        <path d="M19 2.5H5" stroke="#008be1" fill="none" />
        <path d="M20 1.5h3.5v14H20h3.5v-14z" stroke="#000" />
        <path d="M19 14.5H5" stroke="#008be1" fill="none" />
        <path d="M7.3 8.5c0 1.1-.2 1.9-.5 2.4s-.9.8-1.6.8c-.7 0-1.2-.3-1.6-.8-.4-.5-.5-1.3-.5-2.4 0-1.1.2-1.9.5-2.4.4-.5.9-.8 1.6-.8.7 0 1.2.3 1.6.8.4.6.5 1.4.5 2.4zm-3.1 0c0 .8.1 1.4.3 1.8.2.4.4.5.8.5s.7-.2.8-.5c.2-.4.3-1 .3-1.8s-.1-1.4-.3-1.8c-.2-.4-.5-.6-.9-.6s-.6.2-.8.6c-.2.4-.2 1-.2 1.8zm6.8 3.1h-1V6.5l-.2.2c-.1.1-.4.3-.9.8l-.5-.6 1.8-1.4h.8v6.1zm6.2-3.1c0 1.1-.2 1.9-.5 2.4s-.9.8-1.6.8c-.7 0-1.2-.3-1.6-.8-.3-.6-.5-1.4-.5-2.4 0-1.1.2-1.9.5-2.4.4-.5.9-.8 1.6-.8.7 0 1.2.3 1.6.8.3.6.5 1.4.5 2.4zm-3.2 0c0 .8.1 1.4.3 1.8.2.4.4.5.8.5s.7-.2.8-.5c.2-.4.3-1 .3-1.8s-.1-1.4-.3-1.8c-.2-.4-.5-.6-.8-.6-.3 0-.7.2-.8.5-.2.5-.3 1.1-.3 1.9zm6.9 3.1h-1V6.5l-.2.2c-.1.1-.4.3-.9.8l-.5-.6L20 5.4h.8v6.2z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function BinaryIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="binary_24">
        <path d="M.5 2.5h23v12H.5z" fill="#fff" stroke="#008be1" strokeLinecap="round" />
        <path d="M7.15 8.4c0 1.1-.2 1.9-.5 2.4s-1 .8-1.7.8-1.2-.3-1.6-.8c-.4-.5-.6-1.3-.6-2.4 0-1.1.2-1.9.5-2.4.4-.5 1-.7 1.7-.7s1.3.3 1.6.8c.4.5.6 1.3.6 2.3zm-3.3 0c0 .8.1 1.4.3 1.8.2.4.5.5.9.5s.7-.2.9-.5c.2-.4.3-1 .3-1.8s-.1-1.4-.3-1.8c-.3-.3-.6-.5-1-.5s-.7.2-.9.5c-.1.4-.2 1-.2 1.8zm7.1 3.1h-1V6.4l-.2.2c-.1.1-.4.3-1 .8l-.5-.6 1.9-1.4h.8v6.1zm6.4-3.1c0 1.1-.2 1.9-.5 2.4s-.9.8-1.6.8c-.7 0-1.2-.3-1.6-.8-.4-.5-.7-1.3-.7-2.4 0-1.1.2-1.9.5-2.4.4-.5.9-.8 1.6-.8.7 0 1.3.3 1.6.8.5.6.7 1.4.7 2.4zm-3.3 0c0 .8.1 1.4.3 1.8.2.4.5.5.9.5s.7-.2.9-.5c.2-.4.3-1 .3-1.8s-.1-1.4-.3-1.8c-.2-.4-.5-.6-.9-.6s-.7.2-.9.5c-.2.5-.3 1.1-.3 1.9zm7.1 3.1h-1V6.4l-.2.2c-.1.1-.4.3-1 .8l-.5-.6 1.9-1.4h.9v6.1z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function BooleanArrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="boolean_array_24">
        <path fill="#fff" d="M1 2h22v13H1z" />
        <path d="M4 1.5H.5v14H4 .5v-14z" stroke="#000" />
        <path d="M19 2.5H5" stroke="#008be1" fill="none" />
        <path d="M20 1.5h3.5v14H20h3.5v-14z" stroke="#000" />
        <path d="M19 14.5H5" stroke="#008be1" fill="none" />
        <path d="M6.4 11.6h-1V6.3H3.5v-.9h4.6v.9H6.4v5.3zm7.5-6.2l-2.3 6.2h-.9L13 5.4h.9zm4.1 6.2h-1V5.4h3.5v.9H18v1.9h2.3v.9H18v2.5z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function BooleanIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="boolean_24">
        <path d="M.5 2.5h23v12H.5z" fill="#fff" stroke="#008be1" />
        <path d="M6.4 11.5h-1V6.2H3.5v-.9h4.6v.9H6.4v5.3zm7.5-6.2l-2.3 6.2h-.9L13 5.3h.9zm4.1 6.2h-1V5.3h3.5v.9H18v1.9h2.3V9H18v2.5z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function DateArrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="date_array_24">
        <path fill="#fff" d="M1 2h22v13H1z" />
        <path d="M4 1.5H.5v14H4 .5v-14z" stroke="#000" />
        <path d="M19 2.5H5" stroke="#008be1" fill="none" />
        <path d="M20 1.5h3.5v14H20h3.5v-14z" stroke="#000" />
        <path d="M19 14.5H5" stroke="#008be1" fill="none" />
        <path d="M5.15 11.6h-1V6.5l-.2.2c-.1.1-.4.3-.9.8l-.5-.6 1.8-1.5h.8v6.2zm6.2-3.6c0 1.2-.2 2.1-.7 2.7-.5.6-1.2.9-2.2.9-.4 0-.6 0-.8-.1v-.8c.3.1.5.1.8.1.7 0 1.2-.2 1.5-.5.3-.4.5-.9.5-1.7h-.1c-.2.3-.4.4-.6.5-.3.2-.5.3-.9.3-.5 0-1-.2-1.3-.5-.3-.3-.5-.8-.5-1.4 0-.6.2-1.2.5-1.5.4-.4.9-.6 1.5-.6.4 0 .8.1 1.1.3.3.2.6.5.8.9.3.4.4.9.4 1.4zm-2.2-1.8c-.3 0-.6.1-.8.3-.2.2-.2.5-.2.9s.1.6.3.8c.2.2.4.3.8.3.3 0 .6-.1.8-.3.2-.2.3-.4.3-.7 0-.3 0-.5-.1-.7-.1-.2-.2-.4-.4-.5-.3-.1-.5-.1-.7-.1zm5-.9c.6 0 1 .1 1.4.4.3.3.5.6.5 1.1 0 .6-.4 1.1-1.1 1.5.5.2.8.5 1 .8.2.3.3.6.3.9 0 .5-.2.9-.6 1.2-.4.3-.9.5-1.5.5-.7 0-1.2-.1-1.6-.4-.4-.3-.6-.7-.6-1.2 0-.3.1-.7.3-.9.2-.2.5-.5.9-.7-.4-.2-.6-.5-.8-.7-.2-.3-.2-.5-.2-.8 0-.4.2-.8.5-1.1.3-.3 1-.6 1.5-.6zm-1.1 4.7c0 .3.1.5.3.7.2.2.5.2.8.2.4 0 .6-.1.8-.3.2-.2.3-.4.3-.7 0-.2-.1-.4-.3-.6-.2-.2-.5-.4-.8-.5l-.1-.1c-.4.2-.6.3-.8.5-.2.2-.2.5-.2.8zm1.1-3.9c-.3 0-.5.1-.7.2-.2.1-.3.3-.3.6 0 .1 0 .3.1.4l.3.3c.1.1.3.2.6.3.3-.1.6-.3.7-.5.1-.2.2-.4.2-.6 0-.2-.1-.4-.3-.6-.1 0-.3-.1-.6-.1zm7.3 4.1h-.8v1.3h-1v-1.3h-2.8v-.8l2.8-4h1v4h.8v.8zm-1.8-.8V6.5c-.1.2-.2.4-.4.7l-1.5 2.2h1.9z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function DateIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="date_24">
        <path d="M.5 2.5h23v12H.5z" fill="#fff" stroke="#008be1" />
        <path d="M5.15 11.5h-1V6.4c0 .1-.1.2-.2.2-.1.1-.4.3-.9.8l-.5-.6 1.8-1.4h.8v6.1zm6.2-3.5c0 1.2-.2 2.1-.7 2.7-.5.6-1.3.9-2.2.9-.4 0-.6 0-.8-.1v-.8c.3.1.5.1.8.1.7 0 1.2-.2 1.5-.5.3-.4.5-.9.5-1.7h-.1c-.2.3-.4.4-.6.5-.2.1-.5.2-.8.2-.5 0-1-.2-1.3-.5-.3-.3-.5-.8-.5-1.4 0-.6.2-1.2.5-1.5.4-.4.9-.6 1.5-.6.4 0 .8.1 1.1.3.3.2.6.5.8.9.2.4.3.9.3 1.5zm-2.2-1.9c-.3 0-.6.1-.8.3-.2.2-.3.5-.3.9s.1.6.3.8c.2.2.4.3.8.3.3 0 .6-.1.8-.3.2-.2.3-.4.3-.7 0-.3 0-.5-.1-.7 0-.1-.2-.3-.4-.4-.1-.1-.3-.2-.6-.2zm5-.8c.6 0 1 .1 1.4.4.3.3.5.6.5 1.1 0 .6-.4 1.1-1.1 1.5.5.2.8.5 1 .8.2.3.3.6.3.9 0 .5-.2.9-.6 1.2-.4.3-.9.5-1.5.5-.7 0-1.2-.1-1.6-.4-.4-.3-.5-.8-.5-1.3 0-.3.1-.7.3-.9.2-.2.5-.5.9-.7-.4-.2-.6-.5-.8-.7-.2-.3-.2-.5-.2-.8 0-.4.2-.8.5-1.1.3-.3.9-.5 1.4-.5zm-1.1 4.6c0 .3.1.5.3.7.2.2.5.2.8.2.4 0 .6-.1.8-.3.2-.2.3-.4.3-.7 0-.2-.1-.4-.3-.6-.2-.2-.5-.4-.8-.5h-.1c-.4.2-.6.3-.8.5-.2.2-.2.5-.2.7zm1.1-3.9c-.3 0-.5.1-.7.2-.2.1-.3.3-.3.6 0 .1 0 .3.1.4l.3.3c.1.1.3.2.6.3.3-.1.6-.3.7-.5.1-.2.2-.4.2-.6 0-.2-.1-.4-.3-.6 0 0-.3-.1-.6-.1zm7.3 4.2h-.8v1.3h-1v-1.3h-2.8v-.8l2.8-4.1h1v4h.8v.9zm-1.8-.8V6.5c-.1.2-.2.4-.4.7l-1.5 2.2h1.9z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function EnumArrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="enum_array_24">
        <path fill="#fff" d="M1 2h22v12H1z" />
        <path d="M4 1.5H.5v14H4 .5v-14z" stroke="#000" />
        <path d="M19 2.5H5" stroke="#008be1" fill="none" />
        <path d="M20 1.5h3.5v14H20h3.5v-14z" stroke="#000" />
        <path d="M23 13.5H1" stroke="#008be1" fill="none" />
        <path d="M9 3h14v10H9z" fill="#a6ddff" />
        <path d="M6.07 9H4.02C3.41 9 3 8.6 3 8s.4-1 1.02-1h1.94C6.58 7 7 7.4 7 8c.1.6-.41 1-.92 1z" fill="#f18b12" />
        <path d="M12.5 6.5c-.6 0-1 .6-1 1.5s.4 1.5 1 1.5h7c.6 0 1-.6 1-1.5s-.4-1.5-1-1.5h-7z" fill="#56aa1c" stroke="#fff" strokeMiterlimit="4.6" />
      </svg>
    </Icon>
  );
}

export function EnumIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="enum_24">
        <path d="M.7 3h22.6v10H.7z" fill="#fdfeff" />
        <path d="M.5 2.5h23v11H.5z" fill="#fff" stroke="#008be1" />
        <path d="M9 3h14v10H9z" fill="#a6ddff" />
        <path d="M6.07 9H4.02C3.41 9 3 8.6 3 8s.4-1 1.02-1h1.94C6.58 7 7 7.4 7 8c.1.6-.41 1-.92 1z" fill="#f18b12" />
        <path d="M12.5 6.5c-.6 0-1 .6-1 1.5s.4 1.5 1 1.5h7c.6 0 1-.6 1-1.5s-.4-1.5-1-1.5h-7z" fill="#56aa1c" stroke="#fff" strokeMiterlimit="4.6" />
      </svg>
    </Icon>
  );
}

export function DoubleArrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="double_array_24">
        <path fill="#fff" d="M1 2h22v13H1z" />
        <path d="M4 1.5H.5v14H4 .5v-14z" stroke="#000" />
        <path d="M19 2.5H5" stroke="#008be1" fill="none" />
        <path d="M20 1.5h3.5v14H20h3.5v-14z" stroke="#000" />
        <path d="M19 14.5H5" stroke="#008be1" fill="none" />
        <path d="M7.65 6.7c0 .4-.1.7-.3 1-.2.3-.5.4-1 .5.5.1.9.2 1.1.5.2.2.4.6.4 1 0 .6-.2 1.1-.6 1.4-.4.3-1 .5-1.8.5-.7 0-1.3-.1-1.7-.3v-.9c.3.1.5.2.8.3.3.1.6.1.8.1.5 0 .8-.1 1.1-.3.2-.2.4-.5.4-.8 0-.3-.1-.6-.4-.7-.3-.2-.7-.2-1.2-.2h-.7V8h.5c1 0 1.5-.3 1.5-1 0-.3-.1-.5-.3-.6-.1-.3-.3-.3-.7-.3-.2 0-.5 0-.7.1-.2.1-.5.2-.8.4l-.4-.7c.6-.4 1.2-.6 2-.6.6 0 1.1.1 1.5.4.3.2.5.6.5 1zm1.1 4.3c0-.2.1-.4.2-.5.1-.1.3-.2.5-.2s.4.1.5.2c.1.1.2.3.2.5s-.1.4-.2.5c-.1.1-.3.2-.5.2s-.4-.1-.5-.2c-.1-.2-.2-.3-.2-.5zm5.1.5h-1V6.4l-.2.2c-.1.1-.4.3-.9.8l-.5-.6 1.8-1.5h.8v6.2zm6.5-1.4h-.8v1.3h-1v-1.3h-2.8v-.8l2.8-4h1v4h.8v.8zm-1.8-.8V6.4c-.1.2-.2.4-.4.7l-1.5 2.2h1.9z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function DoubleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="double_24">
        <path d="M.5 2.5h23v12H.5z" fill="#fff" stroke="#008be1" />
        <path d="M7.65 6.8c0 .4-.1.7-.3 1-.2.3-.5.4-1 .5.5.1.9.2 1.1.5.2.2.4.6.4 1 0 .6-.2 1.1-.6 1.4-.4.3-1 .5-1.8.5-.7 0-1.3-.1-1.7-.3v-.9c.3.1.5.2.8.3.3.1.6.1.8.1.5 0 .8-.1 1.1-.3.2-.2.4-.5.4-.8 0-.3-.1-.6-.4-.7-.3-.2-.7-.2-1.2-.2h-.7v-.8h.5c1 0 1.5-.3 1.5-1 0-.3-.1-.5-.3-.6-.1-.3-.3-.4-.7-.4-.2 0-.5 0-.7.1-.2.1-.5.2-.8.4l-.4-.7c.6-.4 1.2-.6 2-.6.6 0 1.1.1 1.5.4.3.3.5.7.5 1.1zm1.1 4.2c0-.2.1-.4.2-.5.1-.1.3-.2.5-.2s.4.1.5.2c.1.1.2.3.2.5s-.1.4-.2.5c-.1.1-.3.2-.5.2s-.4-.1-.5-.2c-.1-.1-.2-.3-.2-.5zm5.1.6h-1V6.5l-.2.2c-.1.1-.4.3-.9.8l-.5-.6 1.8-1.4h.8v6.1zm6.5-1.4h-.8v1.3h-1v-1.3h-2.8v-.7l2.8-4.1h1v4h.8v.8zm-1.8-.8V6.5c-.1.2-.2.4-.4.7l-1.5 2.2h1.9z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function GeometryArrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="geometry_array_24">
        <path d="M9.5 11.5l-3-3 3-3h5l3 3-3 3z" fill="#fff" stroke="#008be1" strokeLinejoin="round" />
        <path d="M8 15.5l-7.5-7h6l3 3z" fill="#fff" stroke="#008be1" strokeLinejoin="round" />
        <path d="M9.5 11.5l-1.5 4h8l-1.5-4H12zM8 1.5l-7.5 7h6l3-3zM16 1.5l7.5 7h-6l-3-3z" fill="#fff" stroke="#008be1" strokeLinejoin="round" />
        <path d="M16 15.5l7.5-7h-6l-3 3zM9.5 5.5L8 1.5h8l-1.5 4H12z" fill="#fff" stroke="#008be1" strokeLinejoin="round" />
        <path d="M4 1.5H.5v14H4 .5v-14zM20 1.5h3.5v14H20h3.5v-14z" stroke="#000" />
      </svg>
    </Icon>
  );
}

export function GeometryIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="geometry_24">
        <path d="M9.5 11.5l-3-3 3-3h5l3 3-3 3z" fill="#fff" stroke="#008be1" strokeLinejoin="round" />
        <path d="M8 15.5l-7.5-7h6l3 3z" fill="#fff" stroke="#008be1" strokeLinejoin="round" />
        <path d="M9.5 11.5l-1.5 4h8l-1.5-4H12zM8 1.5l-7.5 7h6l3-3zm8 0l7.5 7h-6l-3-3z" fill="#fff" stroke="#008be1" strokeLinejoin="round" />
        <path d="M16 15.5l7.5-7h-6l-3 3zm-6.5-10L8 1.5h8l-1.5 4H12z" fill="#fff" stroke="#008be1" strokeLinejoin="round" />
      </svg>
    </Icon>
  );
}

export function IntegerArrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="integer_array_24">
        <path fill="#fff" d="M1 2h22v13H1z" />
        <path d="M4 1.5H.5v14H4 .5v-14z" stroke="#000" />
        <path d="M19 2.5H5" stroke="#008be1" fill="none" />
        <path d="M20 1.5h3.5v14H20h3.5v-14z" stroke="#000" />
        <path d="M19 14.5H5" stroke="#008be1" fill="none" />
        <path d="M7.1 11.5h-1V6.4c0 .1-.1.2-.2.3-.1.1-.4.3-.9.8l-.5-.6 1.8-1.4h.8v6zm6.9 0H9.8v-.8l1.6-1.6c.5-.5.8-.8.9-1 .1-.2.3-.4.3-.6.1-.2.1-.4.1-.6 0-.3-.1-.5-.3-.7-.2-.2-.4-.2-.7-.2-.2 0-.5 0-.7.1-.2.1-.5.2-.8.5l-.4-.5c.3-.3.7-.5 1-.6.3-.1.7-.2 1-.2.6 0 1 .2 1.4.5.3.3.5.7.5 1.2 0 .3-.1.5-.2.8-.1.3-.3.5-.5.8-.2.3-.5.6-1 1.1l-1.1 1H14v.8zm5.3-4.7c0 .4-.1.7-.3 1-.2.3-.5.4-1 .5.5.1.9.2 1.1.5.2.2.4.6.4 1 0 .6-.2 1.1-.6 1.4-.4.3-1 .5-1.8.5-.7 0-1.3-.1-1.7-.3v-.9c.3.1.5.2.8.3.3.1.6.1.8.1.5 0 .8-.1 1.1-.3.2-.2.4-.5.4-.8 0-.3-.1-.6-.4-.7-.3-.2-.7-.2-1.2-.2h-.5v-.8h.5c1 0 1.5-.3 1.5-1 0-.3-.1-.5-.3-.6-.2-.1-.4-.2-.8-.2-.2 0-.5 0-.7.1-.2.1-.5.2-.8.4l-.5-.7c.6-.4 1.2-.6 2-.6.6 0 1.1.1 1.5.4.4.1.5.4.5.9z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function IntegerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="integer_24">
        <path d="M.5 2.5h23v12H.5z" fill="#fff" stroke="#008be1" />
        <path d="M7.1 11.5h-1V6.4c0 .1-.1.2-.2.3-.1.1-.4.3-.9.8l-.5-.6 1.8-1.4h.8v6zm6.9 0H9.8v-.8l1.6-1.6c.5-.5.8-.8.9-1 .1-.2.3-.4.3-.6.1-.2.1-.4.1-.6 0-.3-.1-.5-.3-.7-.2-.2-.4-.2-.7-.2-.2 0-.5 0-.7.1-.2.1-.5.2-.8.5l-.4-.5c.3-.3.7-.5 1-.6.3-.1.7-.2 1-.2.6 0 1 .2 1.4.5.3.3.5.7.5 1.2 0 .3-.1.5-.2.8-.1.3-.3.5-.5.8-.2.3-.5.6-1 1.1l-1.1 1H14v.8zm5.3-4.7c0 .4-.1.7-.3 1-.2.3-.5.4-1 .5.5.1.9.2 1.1.5.2.2.4.6.4 1 0 .6-.2 1.1-.6 1.4-.4.3-1 .5-1.8.5-.7 0-1.3-.1-1.7-.3v-.9c.3.1.5.2.8.3.3.1.6.1.8.1.5 0 .8-.1 1.1-.3.2-.2.4-.5.4-.8 0-.3-.1-.6-.4-.7-.3-.2-.7-.2-1.2-.2h-.5v-.8h.5c1 0 1.5-.3 1.5-1 0-.3-.1-.5-.3-.6-.2-.1-.4-.2-.8-.2-.2 0-.5 0-.7.1-.2.1-.5.2-.8.4l-.5-.7c.6-.4 1.2-.6 2-.6.6 0 1.1.1 1.5.4.4.1.5.4.5.9z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function LongArrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="long_array_24">
        <path fill="#fff" d="M1 2h22v13H1z" />
        <path d="M4 1.5H.5v14H4 .5v-14z" stroke="#000" />
        <path d="M19 2.5H5" stroke="#008be1" fill="none" />
        <path d="M20 1.5h3.5v14H20h3.5v-14z" stroke="#000" />
        <path d="M19 14.5H5" stroke="#008be1" fill="none" />
        <path d="M4.6 11.6h-1V6.5l-.2.2c-.1.1-.4.3-.9.8L2 6.8l1.8-1.4h.8v6.2zm6.9 0H7.3v-.8l1.6-1.6c.5-.5.8-.8.9-1 .1-.2.3-.4.3-.6.1-.2.1-.4.1-.6 0-.3-.1-.5-.3-.7-.1 0-.3-.1-.6-.1-.2 0-.5 0-.7.1-.2.1-.5.2-.8.5l-.5-.7c.3-.3.7-.5 1-.6.3-.1.7-.2 1.1-.2.6 0 1 .2 1.4.5.3.3.5.7.5 1.2 0 .3-.1.5-.2.8-.1.3-.3.5-.5.8-.2.3-.5.6-1 1.1l-1.1 1h2.9v.9zm5.3-4.8c0 .4-.1.7-.3 1-.2.3-.5.4-1 .5.5.1.9.2 1.1.5.2.2.4.6.4 1 0 .6-.2 1.1-.6 1.4-.4.3-1 .5-1.8.5-.7 0-1.3-.1-1.7-.3v-.9c.3.1.5.2.8.3.3.1.6.1.8.1.5 0 .8-.1 1.1-.3.2-.2.4-.5.4-.8 0-.3-.1-.6-.4-.7-.3-.2-.7-.2-1.2-.2h-.5v-.8h.5c1 0 1.5-.3 1.5-1 0-.3-.1-.5-.3-.6-.2-.1-.4-.2-.8-.2-.2 0-.5 0-.7.1-.2.1-.5.2-.8.4l-.5-.7c.6-.4 1.2-.6 2-.6.6 0 1.1.1 1.5.4.4.1.5.5.5.9zm1.6 4.8V5.4h1v5.3H22v.9h-3.6z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function LongIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="long_24">
        <path d="M.5 2.5h23v12H.5z" fill="#fff" stroke="#008be1" />
        <path d="M4.5 11.5h-1V6.4l-.2.2c-.1.1-.4.3-.9.8l-.5-.6 1.8-1.4h.8v6.1zm6.9 0H7.2v-.8l1.6-1.6c.5-.5.8-.8.9-1 .1-.2.3-.4.3-.6.1-.2.1-.4.1-.6 0-.3-.1-.5-.3-.7-.1 0-.3-.1-.6-.1-.2 0-.5 0-.7.1-.2.2-.4.3-.7.5l-.6-.6c.3-.3.7-.5 1-.6.3-.1.7-.2 1-.2.6 0 1 .2 1.4.5.3.3.5.7.5 1.2 0 .3-.1.5-.2.8-.1.3-.3.5-.5.8-.2.3-.5.6-1 1.1l-1.1 1h2.9v.8zm5.3-4.7c0 .4-.1.7-.3 1-.2.3-.5.4-1 .5.5.1.9.2 1.1.5.2.2.4.6.4 1 0 .6-.2 1.1-.6 1.4-.4.3-1 .5-1.8.5-.7 0-1.3-.1-1.7-.3v-.9c.3.1.5.2.8.3.3.1.6.1.8.1.5 0 .8-.1 1.1-.3.2-.2.4-.5.4-.8 0-.3-.1-.6-.4-.7-.3-.2-.7-.2-1.2-.2h-.5v-.8h.5c1 0 1.5-.3 1.5-1 0-.3-.1-.5-.3-.6-.2-.1-.4-.2-.8-.2-.2 0-.5 0-.7.1-.2.1-.5.2-.8.4l-.5-.7c.6-.4 1.2-.6 2-.6.6 0 1.1.1 1.5.4.4.1.5.4.5.9zm1.6 4.7V5.4h1v5.3h2.6v.8h-3.6z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function NavigationArrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="navigation_array_24">
        <path fill="#fff" d="M1 2h22v13H1z" />
        <path d="M4 1.5H.5v14H4 .5v-14z" stroke="#000" />
        <path d="M19 2.5H5" stroke="#008be1" fill="none" />
        <path d="M20 1.5h3.5v14H20h3.5v-14z" stroke="#000" />
        <path d="M19 14.5H5" stroke="#008be1" fill="none" />
        <path d="M17.82 9.11l-2.6 1.9-2.6 1.9c-.35.27-.7-.13-.7-.54V10H6V7h5.91V4.63c0-.4.36-.81.71-.54l2.6 1.9 2.6 1.9c.24.4.24.95 0 1.22z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function NavigationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="navigation_24">
        <path d="M.5 2.5h23v12H.5z" fill="#fff" stroke="#008be1" />
        <path d="M17.82 9.11l-2.6 1.9-2.6 1.9c-.35.27-.7-.13-.7-.54V10H6V7h5.91V4.63c0-.4.36-.81.71-.54l2.6 1.9 2.6 1.9c.24.4.24.95 0 1.22z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function Point2DArrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="point2d_array_24">
        <path fill="#fff" d="M1 2h22v13H1z" />
        <path d="M4 1.5H.5v14H4 .5v-14z" stroke="#000" />
        <path d="M19 2.5H5" stroke="#008be1" fill="none" />
        <path d="M20 1.5h3.5v14H20h3.5v-14z" stroke="#000" />
        <path d="M19 14.5H5" stroke="#008be1" fill="none" />
        <path d="M8.65 11.05h-1.1l-1.5-2.5-1.6 2.5h-1.1l2-3.2-1.9-3h1.1l1.5 2.4 1.4-2.3h1.1l-1.9 3 2 3.1zm4-.9c-.1.5-.4 1.2-.7 2h-.7c.2-.8.3-1.5.4-2.1l1 .1zm5.4-2.5l1.5-2.8h1.1l-2.1 3.8v2.4h-1v-2.4l-2-3.8h1.1l1.4 2.8z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function Point2DIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="point2d_24">
        <path d="M.5 2.5h23v12H.5z" fill="#fff" stroke="#008be1" />
        <path d="M8.6 11.05H7.5L6 8.55l-1.6 2.5H3.3l2-3.2-1.9-3h1.1L6 7.15l1.4-2.3h1.1l-1.9 3 2 3.2zm4-.9c-.1.5-.4 1.2-.7 2h-.7c.2-.8.3-1.5.4-2.1l1 .1zm5.4-2.5l1.5-2.8h1.1l-2.1 3.8v2.4h-1v-2.4l-2-3.8h1.1l1.4 2.8z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function Point3DArrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="point3d_array_24">
        <path fill="#fff" d="M1 2h22v13H1z" />
        <path d="M4 1.5H.5v14H4 .5v-14z" stroke="#000" />
        <path d="M19 2.5H5" stroke="#008be1" fill="none" />
        <path d="M20 1.5h3.5v14H20h3.5v-14z" stroke="#000" />
        <path d="M19 14.5H5" stroke="#008be1" fill="none" />
        <path d="M7.45 11h-1.2l-1.5-2.5-1.6 2.5h-1l2-3.2-1.9-2.9h1.1l1.4 2.3 1.5-2.3h1.1l-1.9 3 2 3.1zm1.7-.9c-.1.5-.4 1.2-.7 2h-.7c.2-.8.3-1.5.4-2.1l1 .1zm3.2-2.4l1.4-2.8h1.1l-2.1 3.8v2.4h-1V8.7l-2-3.8h1.1l1.5 2.8zm4.3 2.4c-.1.5-.4 1.2-.7 2h-.7c.2-.8.3-1.5.4-2.1l1 .1zm5.2.9h-4.4v-.7l3.1-4.6h-3v-.8h4.2v.7l-3.1 4.6h3.2v.8z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function Point3DIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="point3d_24">
        <path d="M.5 2.5h23v12H.5z" fill="#fff" stroke="#008be1" />
        <path d="M7.45 11.05h-1.2l-1.5-2.5-1.6 2.5h-1l2-3.2-1.9-3h1.1l1.4 2.3 1.4-2.3h1.1l-1.9 3 2.1 3.2zm1.7-.9c-.1.5-.4 1.2-.7 2h-.7c.2-.8.3-1.5.4-2.1l1 .1zm3.2-2.5l1.5-2.8h1.1l-2.1 3.8v2.4h-1v-2.4l-2-3.8h1.1l1.4 2.8zm4.3 2.5c-.1.5-.4 1.2-.7 2h-.7c.2-.8.3-1.5.4-2.1l1 .1zm5.2.9h-4.4v-.7l3.1-4.6h-3v-.9h4.2v.7l-3.1 4.6h3.2v.9z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function StructArrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 16" id="struct_array_24">
        <defs>
          <linearGradient xlinkHref="#a" id="c" x1="-1.36" y1="-4.9" x2="2.64" y2="-4.9" gradientUnits="userSpaceOnUse" gradientTransform="matrix(.75 0 0 1 2.02 6.4)" />
          <linearGradient id="a">
            <stop offset="0" stopColor="#fff" stopOpacity="0" />
            <stop offset="1" stopColor="#fff" />
          </linearGradient>
          <clipPath id="b">
            <path d="M-4.04-15.23v1h-3v12h3v1h16v-1h3v-12h-3v-1h-16z" fill="red" strokeMiterlimit="3.4" />
          </clipPath>
          <linearGradient xlinkHref="#a" id="d" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-.75 0 0 1 21.98 6.4)" x1="-1.36" y1="-4.9" x2="2.64" y2="-4.9" />
          <linearGradient xlinkHref="#a" id="e" x1="-1.36" y1="-4.9" x2="2.64" y2="-4.9" gradientUnits="userSpaceOnUse" gradientTransform="matrix(.75 0 0 1 2.02 19.4)" />
          <linearGradient xlinkHref="#a" id="f" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-.75 0 0 1 21.98 19.4)" x1="-1.36" y1="-4.9" x2="2.64" y2="-4.9" />
        </defs>
        <rect width="23" height="13" x="-7.54" y="-14.73" ry="4" clipPath="url(#b)" transform="translate(8.04 16.23)" fill="#fff" stroke="#008be1" strokeLinecap="round" filter="url(#filter20876)" />
        <circle cx="6" cy="5" r="2" fill="#f18b12" />
        <path d="M19.26 6h-7.52c-.37 0-.74-.38-.74-1 0-.5.28-1 .74-1h7.52c.37 0 .74.38.74 1-.1.5-.37 1-.74 1z" fill="#f18b12" />
        <circle cx="6" cy="11" r="2" fill="#56aa1c" />
        <path d="M19.26 12h-7.52c-.37 0-.74-.38-.74-1 0-.5.28-1 .74-1h7.52c.37 0 .74.38.74 1-.1.63-.37 1-.74 1z" fill="#56aa1c" />
        <path d="M4 .5H.5v15H4 .5V.5zm16 0h3.5v15H20h3.5V.5z" stroke="#000" />
        <path d="M1 1v1h4V1H1z" fill="url(#c)" filter="url(#filter20888)" />
        <path d="M19 1v1h4V1h-4z" fill="url(#d)" filter="url(#filter20888)" />
        <path d="M1 14v1h4v-1H1z" fill="url(#e)" filter="url(#filter20888)" />
        <path d="M19 14v1h4v-1h-4z" fill="url(#f)" filter="url(#filter20888)" />
      </svg>
    </Icon>
  );
}

export function StructIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="struct_24">
        <rect width="23" height="13" x=".5" y="1.5" ry="4" fill="#fff" stroke="#008be1" strokeLinecap="round" />
        <circle cx="6" cy="5" r="2" fill="#f18b12" />
        <path d="M19.26 6h-7.52c-.37 0-.74-.38-.74-1 0-.5.28-1 .74-1h7.52c.37 0 .74.38.74 1-.1.5-.37 1-.74 1z" fill="#f18b12" />
        <circle cx="6" cy="11" r="2" fill="#56aa1c" />
        <path d="M19.26 12h-7.52c-.37 0-.74-.38-.74-1 0-.5.28-1 .74-1h7.52c.37 0 .74.38.74 1-.1.63-.37 1-.74 1z" fill="#56aa1c" />
      </svg>
    </Icon>
  );
}

export function StringArrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="string_array_24">
        <path fill="#fff" d="M1 2h22v13H1z" />
        <path d="M4 1.5H.5v14H4 .5v-14z" stroke="#000" />
        <path d="M19 2.5H5" stroke="#008be1" fill="none" />
        <path d="M20 1.5h3.5v14H20h3.5v-14z" stroke="#000" />
        <path d="M19 14.5H5" stroke="#008be1" fill="none" />
        <path d="M8 11.55l-.6-1.7H5l-.6 1.7h-1l2.3-6.2h1.1l2.3 6.2H8zm-.9-2.6l-.5-1.7c0-.1-.1-.3-.2-.5-.1-.3-.1-.5-.2-.6-.1.3-.2.7-.3 1.1l-.6 1.6h1.8zm3-3.6h1.8c.8 0 1.5.1 1.8.4.4.2.6.6.6 1.2 0 .4-.1.7-.3.9-.2.2-.5.4-.8.5.4.1.8.2 1 .5.2.2.3.6.3 1 0 .6-.2 1-.6 1.3-.4.3-.9.5-1.6.5h-2.2v-6.3zm1 2.5h1c.4 0 .7-.1.9-.2.2-.1.3-.4.3-.7 0-.3-.1-.5-.3-.6-.2-.1-.5-.2-1-.2h-.9v1.7zm0 .9v2h1.1c.4 0 .7-.1 1-.2.2-.2.3-.4.3-.8 0-.3-.1-.6-.3-.7-.2-.2-.6-.2-1-.2h-1.1zm7.6-2.6c-.6 0-1 .2-1.4.6-.3.4-.5 1-.5 1.7 0 .8.2 1.3.5 1.7.3.4.8.6 1.4.6.3 0 .5 0 .8-.1.2-.1.5-.1.8-.2v.9c-.5.2-1 .3-1.6.3-.9 0-1.6-.3-2.1-.8s-.7-1.3-.7-2.3c0-.6.1-1.2.4-1.7a2.73 2.73 0 0 1 2.6-1.5c.6 0 1.2.1 1.7.4l-.4.8c-.2-.1-.4-.2-.7-.3-.3-.1-.6-.1-.8-.1z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function StringIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon data-iui-icon-size="l">
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" id="string_24">
        <path d="M.5 2.5h23v12H.5z" fill="#fff" stroke="#008be1" />
        <path d="M8.1 11.55l-.6-1.7H5.1l-.6 1.7H3.4l2.3-6.2h1.1l2.3 6.2h-1zm-.9-2.5l-.6-1.7c0-.1-.1-.3-.2-.5 0-.3-.1-.5-.1-.6-.1.3-.2.7-.3 1.1l-.6 1.6h1.8zm3-3.6H12c.8 0 1.5.1 1.8.4.4.2.6.6.6 1.2 0 .4-.1.7-.3.9-.2.2-.5.4-.8.5.4.1.8.2 1 .5.2.2.3.6.3 1 0 .6-.2 1-.6 1.3-.4.3-.9.3-1.6.3h-2.2v-6.1zm1 2.5h1c.4 0 .7-.1.9-.2.2-.1.3-.4.3-.7 0-.3-.1-.5-.3-.6-.2-.1-.5-.2-1-.2h-.9v1.7zm0 .8v2h1.1c.4 0 .7-.1 1-.2.2-.2.3-.4.3-.8 0-.3-.1-.6-.3-.7-.2-.2-.6-.2-1-.2h-1.1zm7.5-2.6c-.6 0-1 .2-1.4.6-.3.4-.5 1-.5 1.7 0 .8.2 1.3.5 1.7.3.4.8.6 1.4.6.3 0 .5 0 .8-.1.2-.1.5-.1.8-.2v.9c-.5.2-1 .3-1.6.3-.9 0-1.6-.3-2.1-.8s-.7-1.3-.7-2.3c0-.6.1-1.2.4-1.7a2.73 2.73 0 0 1 2.6-1.5c.6 0 1.2.1 1.7.4l-.5.8c-.2-.1-.4-.2-.7-.3-.2 0-.4-.1-.7-.1z" fill="#008be1" />
      </svg>
    </Icon>
  );
}
