/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { ConstantAttributes, ElementAttributes } from "frontend/api/Interfaces";
import { ElementLink } from "frontend/components/controls/ElementLink";
import { UsesCard } from "../cards/UsesCard";

type Props = ElementAttributes;
type State = Partial<ConstantAttributes>;

export class ConstantDetails extends React.Component<Props, State> {
  private static _dataProvider = async (id: string) => ImseFrontend.instance.getUnitsThatUseConstant(id);
  private static _defaultState = {
    phenomenon: undefined,
  };

  public override readonly state: State = ConstantDetails._defaultState;
  public initialized?: Promise<any>;

  private async fetchData() {
    const data = ImseFrontend.instance.getSchemaItemAttributes(this.props.id) as ConstantAttributes;
    this.setState({...ConstantDetails._defaultState, ...data});
  }

  public override componentDidMount() {
    this.initialized = this.fetchData();
  }

  public override componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id)
      this.initialized = this.fetchData();
  }

  public override render() {
    const phenomenon = this.state.phenomenon ? (<ElementLink {...this.state.phenomenon} />) : ("");

    return (
      <div className="details-page">
        <div className="element-attributes">
          <div className="item">
            <span className="label">Phenomenon: </span> <span className="value">{phenomenon}</span>
          </div>
        </div>
        <div className="card-container">
          <UsesCard title="Used by:" dataProvider={ConstantDetails._dataProvider} id={this.props.id} />
        </div>
      </div>
    );
  }
}
