/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ElementLinkNoTag } from "frontend/components/controls/ElementLinkNoTag";
import { ElementLinkData } from "frontend/api/Interfaces";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { Definition, parseDefinition } from "frontend/api/DefinitionParser";
import "./FractionalDefinitionNotation.scss";

interface Props {
  id: string;
  currentUnit: string;
}

interface State {
  definition?: Definition;
  numeratorTerms: Term[];
  denominatorTerms: Term[];
  numerator?: number;
  denominator?: number;
  offset?: number;
}

export interface Term extends ElementLinkData {
  exponent: number;
}

export class FractionalDefinitionNotation extends React.Component<Props, State> {
  private static _defaultState: State = {
    definition: undefined,
    numeratorTerms: [],
    denominatorTerms: [],
    numerator: undefined,
    denominator: undefined,
    offset: undefined,
  };

  public override readonly state: State = FractionalDefinitionNotation._defaultState;
  public initialized?: Promise<any>;

  public override componentDidMount() {
    this.initialized = this.fetchData();
  }

  public override componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id)
      this.initialized = this.fetchData();
  }

  private async fetchData() {
    let newState: State = { ...FractionalDefinitionNotation._defaultState };
    const numeratorTerms: Term[] = [];
    const denominatorTerms: Term[] = [];

    const defData = await ImseFrontend.instance.getDefinition(this.props.id);
    if (defData !== undefined) {
      const definition = parseDefinition(defData.definition);
      for (const term of definition) {
        const data = await ImseFrontend.instance.searchDefinitionTermByName(term.name, defData.alias);
        if (data === undefined)
          break;

        if (term.exponent >= 0)
          numeratorTerms.push({ ...data, exponent: Math.abs(term.exponent) });
        else
          denominatorTerms.push({ ...data, exponent: Math.abs(term.exponent) });
      }

      newState = {
        ...defData,
        definition,
        numeratorTerms,
        denominatorTerms,
      };
    }

    this.setState({ ...FractionalDefinitionNotation._defaultState, ...newState });
  }

  private separator(char: string, heavy: boolean = true): JSX.Element {
    let className = "separator";
    if (heavy) className += " heavy";
    return (
      <span className={className}>
        {char}
      </span>
    );
  }

  private renderTerm(term: Term): JSX.Element {
    const exponent = (term.exponent > 1) ? term.exponent : null;
    return (
      <span className="unit">
        <span className="link"><ElementLinkNoTag {...term} /></span>
        <span className="exponent">{exponent}</span>
      </span>
    );
  }

  private renderNumerator(): JSX.Element {
    const numerator = this.state.numeratorTerms.map((term, idx, arr) => {
      return (
        <div key={`numerator${term.id}`}>
          <div className="unit-container" key={`numerator${term.id}`}>
            {this.renderTerm(term)}
          </div>
          {(idx !== arr.length - 1) ? this.separator("*") : null}
        </div>
      );
    });

    const constant = this.state.denominator || 1;
    if (numerator.length > 0)
      return (
        <span className="numerator" key="numerator">
          {(constant !== 1) ? (<><span className="constant" key="const">{constant}</span>{this.separator("*")}</>) : null}
          {numerator}
        </span>
      );

    return (
      <span className="numerator">
        <span className="constant" key="const">
          {constant}
        </span>
      </span>
    );
  }

  private renderDenominator(): JSX.Element | null {
    const denominator = this.state.denominatorTerms.map((term, idx, arr) => {
      return (
        <div key={`denominator${term.id}`}>
          <div className="unit-container" key={`denominator${term.id}`}>
            {this.renderTerm(term)}
          </div>
          {(idx !== arr.length - 1) ? this.separator("*") : null}
        </div>
      );
    });
    const constant = this.state.numerator || 1;
    if (denominator.length > 0 || (this.state.denominator !== 1 && this.state.denominator !== null)) {
      return (
        <span className="denominator">
          {(constant !== 1) ? (<><span className="constant" key="const">{constant}</span>{(denominator.length > 0) ? this.separator("*") : null}</>) : null}
          {denominator}
        </span>
      );
    }
    if (constant !== 1) {
      return (
        <span className="denominator">
          <span className="constant" key="const">
            {constant}
          </span>
        </span>
      );
    }
    return null;
  }

  private renderOffset(): JSX.Element | null {
    if (this.state.offset && this.state.offset !== 0) {
      return (
        <span className="offset">
          {this.separator((this.state.offset > 0) ? "-" : "+", false)}
          <span className="constant">{Math.abs(this.state.offset)}</span>
        </span>
      );
    }
    return null;
  }

  public override render() {
    const numerator = this.renderNumerator();
    const denominator = this.renderDenominator();
    const offset = this.renderOffset();

    if (this.state.definition !== undefined)
      return (
        <div className="def-container">
          <span className="label">{this.props.currentUnit} = </span>
          <div className="definition">
            {numerator}
            {denominator}
          </div>
          {offset}
        </div>
      );

    return null;
  }
}
