/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { reducer as tree } from "frontend/state/reducers/tree";
import { reducer as splitters } from "frontend/state/reducers/splitters";
import { reducer as schemas } from "frontend/state/reducers/schemas";
import { reducer as settings } from "frontend/state/reducers/settings";
import { reducer as location } from "frontend/state/reducers/location";
import { combineReducers } from "@reduxjs/toolkit";
export const rootReducer = combineReducers({
  tree,
  splitters,
  schemas,
  settings,
  location,
});

export type RootState = ReturnType<typeof rootReducer>;
export type PartialRootState = { [P in keyof RootState]?: Partial<RootState[P]> };
