/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import "./RuleMessageTip.scss";
import * as React from "react";

export interface Props {
  message?: string;
}

/**
 * Component that renders Element summary information typically displayed within
 * a tooltip.
 */
export class RuleMessageTip extends React.Component<Props> {
  public override render() {
    return (
      <div className="tooltip">
        <span className="iui-text-title">
          <span className="message">{this.props.message}&nbsp;</span>
        </span>
      </div>
    );
  }
}
