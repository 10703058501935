/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { ElementLink } from "frontend/components/controls/ElementLink";
import { SchemaLink } from "frontend/components/controls/SchemaLink";
import { ElementLinkData, NavPropertyLinkData, PropertyLinkData, PropertyTableRowTypeInfo, SchemaLinkData, SchemaType } from "frontend/api/Interfaces";
import { ElementDescription } from "frontend/components/ElementDescription";
import { PropertyTypeIcon, SmallElementTypeIcon } from "../ElementIcons";
import * as React from "react";
import { RuleCodeLink } from "../validation/RuleCodeLink";
import { Tooltip } from "../Tooltip";
import { RuleMessageTip } from "../validation/RuleMessageTip";

export class PropertyNameCell extends React.Component<{value?: any}> {
  public override render() {
    return  (
      <div className="property-name-cell">
        <PropertyTypeIcon propertyType={this.props.value.icon} />
        <div className="property-name">
          {this.props.value.name}
        </div>
      </div>
    );
  }
}

export class PropertyLinkCell extends React.Component<{ value?: any }> {
  public override render() {
    if (!this.props.value)
      return (<NullableCell />);

    return (
      <div className="property-link-cell">
        <PropertyTypeIcon propertyType={this.props.value.propertyType} />
        <ElementLinkCell value={this.props.value} />
      </div>
    );
  }
}

export class SchemaItemLinkCell extends React.Component<{ value?: any }> {
  public override render() {
    if (!this.props.value)
      return (<NullableCell />);

    return (
      <div className="schema-item-link-cell">
        <SmallElementTypeIcon elementType={this.props.value.elementType} />
        <ElementLinkCell value={this.props.value} />
      </div>
    );
  }
}

export class ElementLinkCell extends React.Component<{value?: any}> {
  public override render() {
    if (!this.props.value)
      return (<NullableCell/>);

    return  (<ElementLink {...this.props.value} />);
  }
}

export class SchemaLinkCell extends React.Component<{value?: any}> {
  public override render() {
    if (!this.props.value)
      return (<NullableCell/>);

    return (
      <div className="schema-link-cell">
        <SmallElementTypeIcon elementType={SchemaType.schema} />
        <SchemaLink {...this.props.value} />
      </div>
    );
  }
}

export class PropertyTypeCell extends React.Component<{value?: PropertyTableRowTypeInfo}> {
  public static formatPrimitiveId(id: number) {
    switch (id) {
      case 257:  return "Binary";
      case 513:  return "Boolean";
      case 769:  return "DateTime";
      case 1025: return "Double";
      case 1281: return "Integer";
      case 1537: return "Long";
      case 1793: return "Point2d";
      case 2049: return "Point3d";
      case 2305: return "String";
      case 2561: return "IGeometry";
    }
    return "UNKNOWN PRIMITIVE TYPE!";
  }

  private formatExtendedType(ext?: string) {
    if (ext)
      return ` (${ext})`;
    else
      return undefined;
  }
  public override render() {
    if (!this.props.value)
      return (<NullableCell/>);

    const suffix = this.props.value.isArray ? " [ ] " : "";
    if (this.props.value.enumeration)
      return (
        <div className="property-type-cell">
          <ElementLinkCell value={this.props.value.enumeration} />
          {suffix}
        </div>
      );

    if (this.props.value.struct)
      return (
        <div className="property-type-cell">
          <ElementLinkCell value={this.props.value.struct} />
          {suffix}
        </div>
      );

    if (this.props.value.nav)
      return (
        <NavigationPropertyLink {...this.props.value.nav} />
      );

    if (!this.props.value.primitiveType)
      throw new Error("Unknown Property Kind!");

    return (
      <div className="property-type-cell">
        <span>
          {PropertyTypeCell.formatPrimitiveId(this.props.value.primitiveType.id)}
          {this.formatExtendedType(this.props.value.primitiveType.ext)}
          {suffix}
        </span>
      </div>
    );
  }
}

export class ArrayBoundsCell extends React.Component<{value?: any}> {
  private formatEnd(end: number) {
    if (end < 0 || undefined === end)
      return "<unbounded>";

    return end;
  }

  public override render() {
    if (!this.props.value)
      return (<NullableCell/>);

    if (this.props.value.min === 0 && undefined === this.props.value.max)
      return (<div className="array-bounds-cell">{"<unbounded>"}</div>);

    return (
      <div className="array-bounds-cell" >
        {this.formatEnd(this.props.value.min)}
        &nbsp;-&nbsp;
        {this.formatEnd(this.props.value.max)}
      </div>
    );
  }
}

export class NullableCell extends React.Component<{value?: any}> {
  public override render() {
    if (this.props.value)
      return (
        <span>
          {this.props.value}
        </span>
      );

    return (
      <div className="null-cell" />
    );
  }
}

export class DescriptionCell extends React.Component<{value?: any}> {
  public override render() {
    return (
      <ElementDescription description={this.props.value} />
    );
  }
}

export class BooleanCell extends React.Component<{value?: any}> {
  public override render() {
    return (
      <div className="bool-cell" >{this.props.value ? "True" : "False"}</div>
    );
  }
}

export class NavigationPropertyLink extends React.Component<NavPropertyLinkData> {
  public override render() {
    return (
      <span className="nav-prop-link">
        <ElementLink {...this.props.roleLabel} />
        <ElementLink {...this.props.endpoint} />
      </span>
    );
  }
}

function isElementLinkData(arg: any): arg is ElementLinkData {
  return arg.id !== undefined && arg.name !== undefined && arg.schemaName !== undefined && arg.elementType !== undefined;
}

function isPropertyLinkData(arg: any): arg is PropertyLinkData {
  return isElementLinkData(arg) && arg.propertyName !== undefined;
}

function isSchemaLinkData(arg: any): arg is SchemaLinkData {
  return arg.id !== undefined && arg.name !== undefined;
}

export class ValidationLinkCell extends React.Component<{ value?: any }> {
  public override render() {
    if (!this.props.value)
      return (<NullableCell/>);

    if (isPropertyLinkData(this.props.value))
      return <PropertyLinkCell value={this.props.value} />;

    if (isElementLinkData(this.props.value))
      return <SchemaItemLinkCell value={this.props.value} />;

    if (isSchemaLinkData(this.props.value))
      return <SchemaLinkCell value={this.props.value} />;

    return undefined;
  }
}

export class RuleMessageCell extends React.Component<{value?: string}> {
  public override render() {
    if (!this.props.value)
      return (<NullableCell/>);

    return (
      <Tooltip content={<RuleMessageTip message={this.props.value}/>}>
        <span> {this.props.value}</span>
      </Tooltip>
    );
  }
}

export class RuleCodeLinkCell extends React.Component<{value?: any}> {
  public override render() {
    if (!this.props.value)
      return (<NullableCell/>);

    return (<RuleCodeLink ruleCode={this.props.value} />);
  }
}
