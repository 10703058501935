/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import "./Toggle.scss";
import * as React from "react";

interface Props {
  checked?: boolean;
  disabled?: boolean;
  noLabel?: boolean;
  onClick(): void;
}

export class Toggle extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    disabled: false,
    noLabel: false,
    checked: false,
  };

  private switchToggle() {
    this.props.onClick?.();
  }

  public override render() {
    const cName = ["toggle"];
    if (this.props.disabled)
      cName.push("disabled");

    return (
      <label className={cName.join(" ")}>
        <input
          className="toggle-input"
          disabled={ this.props.disabled }
          type="checkbox"
          onClick = {() => this.switchToggle()}
          checked={this.props.checked}
          onChange={()=>{}}
        />
        <span
          className="toggle-label"
          data-off={ this.props.noLabel ? "" : "Off" }
          data-on={ this.props.noLabel ? "" : "On" }
        />
        <span className="toggle-handle" />
      </label>
    );
  }
}
