/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "frontend/state/rootReducer";
import { Selectors } from "frontend/state/selectors";
import { Actions } from "frontend/state/reducers/location";
import "./SchemaTabView.css";

interface Props {
  activeTab?: string; // Connected to redux
  updateTabSource(tab: string): any; // Connected to redux
}

const mapState = (state: RootState) => ({
  activeTab: Selectors.activeTabSelector(state),
});

const mapDispatch = {
  updateTabSource: Actions.updateSchemaTabView,
};

export class SchemaTabViewComponent extends React.Component<Props> {
  public override render() {
    return (
      <div className="iui-tabs-wrapper iui-horizontal">
        <ul className="iui-tabs iui-default tab-list-props" role="tablist">
        </ul>
      </div>
    );
  }
}

export const SchemaTabView = connect(mapState, mapDispatch)(SchemaTabViewComponent);
