/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { ElementAttributes, PropertyCategoryAttributes } from "frontend/api/Interfaces";
import { UsesCard } from "../cards/UsesCard";

type Props = ElementAttributes;
type State = Partial<PropertyCategoryAttributes>;

export class PropertyCategoryDetails extends React.Component<Props, State> {
  private static _dataProvider = async (id: string) => ImseFrontend.instance.getPropertiesThatUsePropertyCategory(id);
  private static _defaultState = {
    priority: -1,
  };

  public override readonly state: State = PropertyCategoryDetails._defaultState;
  public initialized?: Promise<any>;

  private async fetchData() {
    const data = ImseFrontend.instance.getSchemaItemAttributes(this.props.id) as PropertyCategoryAttributes;
    this.setState({...PropertyCategoryDetails._defaultState, ...data});
  }

  public override componentDidMount() {
    this.initialized = this.fetchData();
  }

  public override componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id)
      this.initialized = this.fetchData();
  }

  public override render() {
    return (
      <div className="details-page">
        <div className="element-attributes">
          <div className="item">
            <span className="label">Priority: </span><span className="value">{this.state.priority}</span>
          </div>
        </div>
        <div className="card-container">
          <UsesCard title="Used by:" dataProvider={PropertyCategoryDetails._dataProvider} id={this.props.id} />
        </div>
      </div>
    );
  }
}
