/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as ReactDOMClient from "react-dom/client";
import { App } from "./App";
import { ErrorBoundary } from "./components/stages/ErrorBoundary";
import { StrictMode } from "react";

const container = document.getElementById("root") as HTMLElement;
const root = ReactDOMClient.createRoot(container);

root.render(
  <StrictMode><ErrorBoundary><App /></ErrorBoundary></StrictMode>,
);
