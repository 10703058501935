/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import React from "react";
import { Tab, Tabs } from "@itwin/itwinui-react";
import { connect } from "react-redux";
import { RootState } from "frontend/state/rootReducer";
import { Selectors } from "frontend/state/selectors";
import { Actions } from "frontend/state/reducers/location";

interface Props {
  activeTab?: string; // Connected to redux
  updateTabSource(tab: string): any; // Connected to redux
}

const mapState = (state: RootState) => ({
  activeTab: Selectors.activeTabSelector(state),
});

const mapDispatch = {
  updateTabSource: Actions.updateSchemaTabView,
};

export class AspectTabViewComponent extends React.Component<Props> {

  public override render() {
    const aciveIndex = this.props.activeTab === "aspect" ? 1 : 0;
    return (
      <Tabs
        activeIndex= {aciveIndex}
        labels={[<Tab key={1} label='Class Properties'/>, <Tab key={2} label='Aspect Properties'/>]}
        onTabSelected={(index: number) => {
          switch (index) {
            case 0:
              this.props.updateTabSource("class");
              break;
            case 1:
              this.props.updateTabSource("aspect");
          }
        }}
      />
    );
  }
}

export const AspectTabView = connect(mapState, mapDispatch)(AspectTabViewComponent);
