/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import "./SettingsItem.css";
import { Toggle } from "frontend/components/controls/Toggle";

interface Props {
  title: string;
  description?: string;
  onClick?(): void;
  toggleState?: boolean;
}

export class SettingsItem extends React.Component<Props> {
  private switchToggle() {
    this.props.onClick!();
  }

  public override render() {
    const description = this.props.description ? (<span className="description">{this.props.description}</span>) : ("");
    return (
      <div className="settings-item">
        <span className="title">{this.props.title}</span>
        <Toggle onClick={() => this.switchToggle()} checked={this.props.toggleState} />
        {description}
      </div>
    );
  }
}
