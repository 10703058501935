/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import React from "react";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { connect } from "react-redux";
import { Selectors } from "frontend/state/selectors";
import { RootState } from "frontend/state/rootReducer";
import { NodeData, Tree, TreeNode, NodeRenderProps } from "@itwin/itwinui-react";
import "./NavTree.scss";
import "./ClassRelationshipTree.scss";
import { ClassRelationshipsTreeNode } from "frontend/api/Interfaces";
import { ElementLink } from "../ElementLink";

interface Props {
  id?: string; // Connected to redux
}

const mapState = (state: RootState) => ({
  id: Selectors.elementIdSelector(state),
});

/**
 * The class relationships tree
 */
export const ClassRelationshipTreeComponent: React.FC<Props> = (props: Props) => {
  const [data, setData] = React.useState<ClassRelationshipsTreeNode[]>([]);
  const [minHeight, setMinHeight] = React.useState(0);
  const [expandedNodes, setExpandedNodes] = React.useState(new Set<string>());


  React.useEffect(() => {
    const fetchData = async () => {
      if (!props.id)
        return;

      const nodeData = await ImseFrontend.instance.getClassRelationships(props.id);
      const data = Object.keys(nodeData).sort().map((root) => ({
        id: root,
        children: nodeData[root],
        text: root,
        selectable: () => false,
      }));
      setData(data);
      setMinHeight(Object.keys(nodeData).length * 25);
    };
    fetchData();
  }, [props.id]);


  const _collapseNode = (nodeId: string) => {
    setExpandedNodes((prevState) => {
      const newState = new Set([...prevState]);
      newState.delete(nodeId);
      return newState;
    });
  };

  const _expandNode = (nodeId: string, autoExpanded?: boolean) => {
    // A collapse action was triggered
    if (!autoExpanded) {
      _collapseNode(nodeId);
      return;
    }
    setExpandedNodes((prevState) => {
      return new Set([...prevState, nodeId]);
    });
  };

  return (
    <div style={{ minHeight: minHeight }}>
      <Tree<ClassRelationshipsTreeNode>
        data={data}
        getNode={(node: ClassRelationshipsTreeNode): NodeData<ClassRelationshipsTreeNode> => {
          const isExpanded = expandedNodes.has(node.id);
          return {
            subNodes: node.children,
            nodeId: node.id,
            node,
            isExpanded,
            hasSubNodes: node.children !== undefined ? node.children.length > 0 : false,
          };
        }}
        nodeRenderer={(node: NodeRenderProps<ClassRelationshipsTreeNode>) => {
          const label = () => {
            if (node.node.relationship) {
              return (
                <div className="class-relationship-node" >
                  <span>{node.node.roleLabel}</span>
                  <ElementLink {...node.node.relatedClass!} />
                  <span>via</span>
                  <ElementLink {...node.node.relationship} />
                </div>
              );
            }
            return node.node.text;
          };
          return <TreeNode className="wimse-tree-node relationship-node-color"
            nodeId={node.node.id}
            label={label()}
            hasSubNodes={node.node.children ? true : false}
            onExpanded={_expandNode}
            isExpanded={node.isExpanded}
            isSelected={node.isSelected}
          />;
        }
        }
      />
    </div>
  );

}

export const ClassRelationshipTree = connect(mapState)(ClassRelationshipTreeComponent);