/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";

import { Actions as NavActions } from "frontend/state/reducers/location";
import { connect } from "react-redux";
import "./ModalFrontstage.css";

interface Props {
  children?: React.ReactNode;
  title: string;
  isOpen?: boolean;
}

const mapDispatch = {
  navigateBack: NavActions.navigateBack,
  closeModal: NavActions.closeModalFrontstage,
};

export class ModalFrontstageComponent extends React.Component<Props> {

  public override render() {
    const openClass = (this.props.isOpen) ? " open" : "";
    return (
      <div className={`modal-frontstage${openClass}`}>
        <div className="app-bar">
          <span id="modal-frontstage-title" className="iui-text-headline">{this.props.title}</span>
        </div>
        <div className="content">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export type ModalFrontstageProps = Props;

export const ModalFrontstage = connect(undefined, mapDispatch)(ModalFrontstageComponent);
