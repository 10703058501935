/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { CLEAR_HISTORY, CLOSE_MODAL_FRONTSTAGE, NAVIGATE_BACK, NAVIGATE_BACK_N_TIMES, NAVIGATE_FORWARD, NAVIGATE_FORWARD_N_TIMES, NAVIGATE_TO, NAVIGATE_TO_ELEMENT, OPEN_FRONTSTAGE, OPEN_MODAL_FRONTSTAGE, UPDATE_NAV_SOURCE, UPDATE_SCHEMA_TAB_VIEW } from "frontend/state/actionTypes";
import { createAction, createReducer } from "@reduxjs/toolkit";
import { AnyElementType, OtherNodeType, SchemaType } from "frontend/api/Interfaces";
import { parseSchemaItemType, SchemaItemType, schemaItemTypeToString, SchemaKey } from "@itwin/ecschema-metadata";
import { ImseFrontend } from "frontend/api/ImseFrontend";

export const Actions = {
  openFrontstage:  createAction(OPEN_FRONTSTAGE, (stage: string) => {return { payload: stage }}),
  openModalFrontstage: createAction(OPEN_MODAL_FRONTSTAGE, (modal: string) => {return { payload: modal }}),
  closeModalFrontstage: createAction(CLOSE_MODAL_FRONTSTAGE),
  navigateTo: createAction(NAVIGATE_TO, 
    (stage?: string, elementType?: AnyElementType, id?: string, source?: string, propertyName?: string, tab = "items") => {
      return { payload: { stage, elementType, id, source, propertyName, tab }
    }}),
  navigateBack: createAction(NAVIGATE_BACK),
  navigateBackNTimes: createAction(NAVIGATE_BACK_N_TIMES, (n: number) => { return { payload: n }}),
  navigateForwardNTimes: createAction(NAVIGATE_FORWARD_N_TIMES, (n: number) => { return { payload: n }}),
  navigateForward: createAction(NAVIGATE_FORWARD),
  changeSelection: createAction(NAVIGATE_TO_ELEMENT, (elementType: AnyElementType, id?: string) => { return { payload: { elementType, id } }}),
  clearHistory: createAction(CLEAR_HISTORY),
  updateNavigationSource: createAction(UPDATE_NAV_SOURCE, (source: string) => { return { payload: source }}),
  updateSchemaTabView: createAction(UPDATE_SCHEMA_TAB_VIEW, (tab: string) => { return { payload: tab }}),
};


export interface ImseLocation {
  readonly contextId?: string;
  readonly iModelId?: string;
  readonly changeSetId?: string;
  readonly stage?: string;
  readonly modal?: string;
  readonly elementType?: AnyElementType;
  readonly id?: string;
  readonly propertyName?: string;
}

export interface LocationState {
  readonly past: ReadonlyArray<ImseLocation>;
  readonly current: ImseLocation;
  readonly future: ReadonlyArray<ImseLocation>;
  readonly source?: string;
  readonly tab?: string;
}

const getValueOrUndefined = (key: string, searchParams: URLSearchParams): string | undefined => {
  const value = searchParams.get(key);
  if (null === value) { return undefined; }
  return value;
};

export const parseStringToElementType = (key: string | undefined): AnyElementType | undefined => {
  if (undefined === key) {
    return undefined;
  }
  switch (key) {
    case "schema":
      return SchemaType.schema;
    case "root":
      return OtherNodeType.root;
    default:
      return parseSchemaItemType(key);
  }
};

export const parseTypeToString = (key: AnyElementType | undefined): string | undefined => {
  if (undefined === key) {
    return undefined;
  }
  if (key === SchemaType.schema || key === SchemaType.none) {
    return "schema";
  }
  if (key === OtherNodeType.root) {
    return "root";
  }
  // if it's defined and not a schema it's a schema item
  return schemaItemTypeToString(key as SchemaItemType).toLowerCase();
};

export const lowerCaseURLSearchParams = (searchParams: string): URLSearchParams => {
  return new URLSearchParams(searchParams.toLowerCase());
};

export const createLocationFromSearchParams = (searchParams: string): ImseLocation | undefined => {
  const parsedParams = lowerCaseURLSearchParams(searchParams);
  const imseLocation: ImseLocation = {
    stage: getValueOrUndefined("stage", parsedParams),
    modal: getValueOrUndefined("modal", parsedParams),
    elementType: parseStringToElementType(getValueOrUndefined("elementtype", parsedParams)?.toLowerCase().split("def")[0]),
    id: getValueOrUndefined("id", parsedParams),
    propertyName: getValueOrUndefined("propertyname", parsedParams),
  };
  if (imseLocation.stage) { return imseLocation; }
  return undefined;
};
/**
 * Remove any duplicate query params, and then update based on ImseLocation
 * @param location Expected current ImseLocation.
 * @param currentParams The URL string.
 * @returns A URLSearchParams object.
 */
export const updateUrlQueryParams = (location: ImseLocation, currentParams: string): URLSearchParams => {
  const searchParams = lowerCaseURLSearchParams(currentParams);
  for (const [key, value] of Object.entries(location)) {
    if (key === "modal" || key === "tab" || key === "source") { continue; }
    if (undefined !== value && value !== "") {
      if (key === "elementType") {
        const elementType = parseTypeToString(value);
        if (undefined !== elementType) {
          searchParams.set(key.toLowerCase(), elementType);
        }
        continue;
      } else if (key === "id") {
        searchParams.set(key, value);
      } else {
        searchParams.set(key.toLowerCase(), value);
      }
    } else {
      searchParams.delete(key.toLowerCase());
    }
  }
  return searchParams;
};

export const updateWindowUrlToLocation = (location: ImseLocation): void => {
  const newSearchParams = updateUrlQueryParams(location, window.location.search).toString();
  const newUrl = `${window.location.protocol}//${window.location.host}/?${newSearchParams}`;
  window.history.replaceState({}, document.title, newUrl);
};

const cleanValue = (value: string | undefined): string | undefined => {
  if (!value) return undefined;
  return value.replace("}", "");
};

const cleanElementType = (elementType: AnyElementType | undefined): AnyElementType => {
  return undefined !== elementType ? elementType : SchemaType.none;
};

const cleanLocation = (location: ImseLocation): ImseLocation => {
  return {
    stage: cleanValue(location.stage),
    modal: cleanValue(location.modal),
    elementType: cleanElementType(location.elementType),
    id: cleanValue(location.id),
    propertyName: cleanValue(location.propertyName),
  };
};

const getBackwardState = (state: LocationState): LocationState => {
  if (state.past.length <= 0)
    return state;

  const allowImseLocation = true;
  // const allowImseLocation = ImseFeatures.allowImseLocation(state.past[state.past.length - 1])

  if (!allowImseLocation) {
    const newState = {
      past: state.past.slice(0, -1),
      current: state.current,
      future: state.future,
    };
    return getBackwardState(newState);
  }

  const nextState: LocationState = {
    past: state.past.slice(0, -1),
    current: cleanLocation(state.past[state.past.length - 1]),
    future: state.future.concat(state.current),
    source: undefined,
    tab: "items",
  };

  updateWindowUrlToLocation(nextState.current);
  return nextState;
};

const getNthBackwardState = (state: LocationState, n: number): LocationState => {
  if (state.past.length <= 0 || n > state.past.length || n === 0)
    return state;
  if (n === 1) {
    return getBackwardState(state);
  }
  const newFuture = [...state.future, state.current, ...state.past.slice(-n+1).reverse()] as ReadonlyArray<ImseLocation>;
  const nextState: LocationState = {
    past: state.past.slice(0, -n),
    current: cleanLocation(state.past[state.past.length - n]),
    future: newFuture,
    source: undefined,
    tab: "items",
  };
  updateWindowUrlToLocation(nextState.current);
  return nextState;
};
const getForwardState = (state: LocationState): LocationState => {
  if (state.future.length <= 0)
    return state;

  const allowImseLocation = true;
  // const allowImseLocation = ImseFeatures.allowImseLocation(state.future[state.future.length - 1])

  if (!allowImseLocation) {
    const newState = {
      past: state.past,
      current: state.current,
      future: state.future.slice(0, -1),
    };
    return getForwardState(newState);
  }

  const nextState: LocationState = {
    past: state.past.concat(state.current),
    current: cleanLocation(state.future[state.future.length - 1]),
    future: state.future.slice(0, -1),
    source: undefined,
    tab: "items",
  };

  updateWindowUrlToLocation(nextState.current);
  return nextState;
};

const getNthForwardState = (state: LocationState, n: number): LocationState => {
  if (state.future.length <= 0 || n > state.future.length || n === 0)
    return state;
  if (n === 1) {
    return getForwardState(state);
  }

  const newPast = [...state.past, state.current, ...state.future.slice(-n+1).reverse()] as ReadonlyArray<ImseLocation>;
  const nextState: LocationState = {
    past: newPast,
    current: cleanLocation(state.future[state.future.length - n]),
    future: state.future.slice(0,-n),
    source: undefined,
    tab: "items",
  };
  updateWindowUrlToLocation(nextState.current);
  return nextState;
};
export function validateLocation(dest: ImseLocation): boolean {
  const validateIdAndType = () => {
    if (undefined === dest.id) { return false; }
    const id = dest.id.split(".");
    const schemaKey = new SchemaKey(id[0]);
    if (id.length > 2) { return false; }
    if (dest.elementType === OtherNodeType.root) {
      return validateRootId(dest.id);
    }
    const context = ImseFrontend.instance.getCurrentSchemaContext();
    const schema = context.getCachedSchemaSync(schemaKey);
    if (undefined === schema) { return false; }
    if (id.length === 2) {
      const item = schema.getItemSync(id[1]);
      return undefined !== item ? item.schemaItemType === dest.elementType : false;
    } else { // Just the schema prefix, so check if the location has element type of schema.
      return SchemaType.schema === dest.elementType ? true : false;
    }
  };

  const validateStage = () => {
    if (dest.stage) { // if a new stage was passed then verify it.
      if (!(dest.stage === "browse" || dest.stage === "diagram")) { return false; }
    }
    return true;
  };

  const validateRootId = (id: string) => {
    switch (id) {
      case "schema":
      case "entityclass":
      case "mixin":
      case "structclass":
      case "customattributeclass":
      case "relationshipclass":
      case "enumeration":
      case "unit":
      case "constant":
      case "phenomenon":
      case "unitsystem":
      case "format":
      case "kindofquantity":
      case "propertycategory":
        return true;
      default:
        return false;
    }
  };

  if (undefined === dest.elementType || SchemaType.none === dest.elementType) { return false; }
  if (!validateStage()) { return false; }
  if (!validateIdAndType()) { return false; }
  return true;
}

const defaultState: LocationState = { past: [], current: {}, future: [] };

const same = (a: string | undefined, b: string | undefined): boolean => { 
if (a === b) return true;
if (a === undefined || b === undefined) return false;

return a.toLowerCase() === b.toLowerCase();
};

const historyReducer = createReducer(defaultState, (builder) => {

  const push = (state: LocationState, args: ImseLocation & { source?: string, tab?: string }): LocationState => {
    const { source, tab, ...dest } = args;

    if (!validateLocation(dest)) {
      if (validateLocation(state.current)) {
        updateWindowUrlToLocation(state.current);
        return { ...state, source, tab };
      }
      // If the new and current location is invalid just bail and send the user to the default start screen.
      updateWindowUrlToLocation({});
      return defaultState;
    }
    // Don't push a location identical to state.current
    if (same(state.current.stage, dest.stage) && same(state.current.modal, dest.modal) && state.current.elementType === dest.elementType
      && same(state.current.id, dest.id)
      && same(state.current.propertyName, dest.propertyName))
      return { ...state, source, tab }; // but always update the source and tab

    updateWindowUrlToLocation(args);

    return {
      past: state.past.concat(state.current),
      current: cleanLocation(dest),
      future: [],
      source,
      tab,
    };
  };

  builder
    .addCase(Actions.clearHistory, (state, _action) => { return { past: [], current: { ...state.current }, future: [], source: undefined, tab: "items" }})
    .addCase(Actions.openFrontstage, (state, action) => {
      return push(state, { ...state.current, stage: action.payload });
    })
    .addCase(Actions.openModalFrontstage, (state, action) => {
      return push(state, { ...state.current, modal: action.payload });
    })
    .addCase(Actions.closeModalFrontstage, (state) => {
      return push(state, { stage: state.current.stage, elementType: state.current.elementType, id: state.current.id, propertyName: state.current.propertyName, modal: undefined });
    })
    .addCase(Actions.navigateTo, (state, action) => {
      return push(state, { ...action.payload, modal: state.current.modal });
    })
    .addCase(Actions.changeSelection, (state, action) => {
      return push(state, { ...action.payload, modal: state.current.modal, stage: state.current.stage });
    })
    .addCase(Actions.navigateBack, (state) => getBackwardState(state))
    .addCase(Actions.navigateForward, (state) => getForwardState(state))
    .addCase(Actions.navigateBackNTimes, (state, action) => getNthBackwardState(state, action.payload))
    .addCase(Actions.navigateForwardNTimes, (state, action) => getNthForwardState(state, action.payload))
    .addCase(Actions.updateNavigationSource, (state, action) => {
      return { ...state, source: action.payload };
    })
    .addCase(Actions.updateSchemaTabView, (state, action) => {
      return { ...state, tab: action.payload };
    });
});

export const reducer = historyReducer;
