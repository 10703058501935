/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { Table } from "@itwin/itwinui-react";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Actions as NavActions } from "frontend/state/reducers/location";
import { connect } from "react-redux";
import { ArrayBoundsCell, BooleanCell, DescriptionCell, ElementLinkCell, NullableCell, PropertyLinkCell, PropertyNameCell, PropertyTypeCell } from "./CellFormatters";
import { PropertyTableRowData } from "frontend/api/Interfaces";
import "./Table.css";

enum TypeSortKey {
  Primitive = 1,
  Enumeration = 2,
  Struct = 3,
  Navigation = 4,
}

const AspectPropertiesTable = (props: any) => {
  const columns: any[] = useMemo(() => [
    {
      id: "name",
      accessor: "name",
      Header: "Property Name",
      width: 200,
      sticky: "left",
      Cell: ({ value }: { value: any }) => {
        return (
          <PropertyNameCell value={value}/>
        );
      },
    },
    {
      id: "owningClass",
      accessor: "owningClass",
      Header: "Defined In",
      width: 200,
      Cell: ({ value }: { value: any }) => {
        return (
          <ElementLinkCell value={value}/>
        );
      },
    },
    {
      id: "description",
      accessor: "description",
      Header: "Description",
      width: 600,
      Cell: ({ value }: { value: any }) => {
        return (
          <DescriptionCell value={value}/>
        );
      },
    },
    {
      id: "typeInfo",
      accessor: "typeInfo",
      Header: "Type",
      width: 200,
      Cell: ({ value }: { value: any }) => {
        return (
          <PropertyTypeCell value={value}/>
        );
      },
    },
    {
      id: "displayLabel",
      accessor: "displayLabel",
      Header: "Display Label",
      width: 225,
      Cell: ({ value }: { value: any }) => {
        return (
          <NullableCell value={value}/>
        );
      },
    },
    {
      id: "baseProperty",
      accessor: "baseProperty",
      Header: "Base Property",
      width: 200,
      Cell: ({ value }: { value: any }) => {
        return (
          <PropertyLinkCell value={value}/>
        );
      },
    },
    {
      id: "kindOfQuantity",
      accessor: "kindOfQuantity",
      Header: "Kind of Quantity",
      width: 135,
      Cell: ({ value }: { value: any }) => {
        return (
          <ElementLinkCell value={value}/>
        );
      },
    },
    {
      id: "arrayBounds",
      accessor: "arrayBounds",
      Header: "Array Bounds",
      width: 115,
      Cell: ({ value }: { value: any }) => {
        return (
          <ArrayBoundsCell value={value}/>
        );
      },
    },
    {
      id: "isReadonly",
      accessor: "isReadonly",
      Header: "Is Readonly",
      width: 100,
      Cell: ({ value }: { value: any }) => {
        return (
          <BooleanCell value={value}/>
        );
      },
    },
  ], []);

  const [data, setData] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});

  const onSort = useCallback((state: any) => {
    for (const sortBy of state.sortBy) {
      const sortDirection = sortBy.desc ? "DESC" : "ASC";
      const sortedRows = handleTableSort(sortBy.id, sortDirection, data);
      setData(sortedRows);
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const {id, properties} = await ImseFrontend.instance.getAspectProperties(props.id);
      if (id === props.id) {
        const rowData = properties.map((item: any) => {
          const newRow: any = {};
          columns.forEach((column: any) => {
            const columnData: any = item[column.id];
            newRow[column.id] = columnData;
          });
          return newRow;
        });
        setData(rowData);
        if (props.propertyName) {
          const rowIndex = properties.findIndex((value) => value.name.name === props.propertyName);
          const selected: Record<string, boolean> = {[rowIndex]: true};
          setSelectedRows(selected);
        }
      }
    };
    void fetchData();
  }, [props.id]);

  return (
    <div className="imsejs-table">
      <Table columns={columns}
        data={data}
        emptyTableContent='No data.'
        density="extra-condensed"
        isResizable
        columnResizeMode="expand"
        isSortable
        manualSortBy
        onSort={onSort}
        isSelectable
        selectionMode="single"
        initialState={{ selectedRowIds: selectedRows}}
        autoResetPage={false}
        style={{
        }}
      />
    </div>
  );
};

function handleTableSort(sortColumn: string, sortDirection: "ASC" | "DESC" | "NONE", rowData: any []) {
  const comparer = (a: PropertyTableRowData, b: PropertyTableRowData) => {
    const aValue = (a as any)[sortColumn];
    const bValue = (b as any)[sortColumn];
    if (sortColumn === "typeInfo")
      return sortCompareTypes(aValue, bValue, sortDirection);

    return sortCompare(aValue, bValue, sortDirection);
  };
  return rowData.sort(comparer).slice();
}

export function sortCompare(a: any, b: any, sortDirection: "ASC" | "DESC" | "NONE"): number {
  const aValue = typeof(a) === "string" ? a : a !== undefined ? a.name : "";
  const bValue = typeof(b) === "string" ? b : b !== undefined ? b.name : "";
  if (sortDirection === "ASC") {
    return aValue.localeCompare(bValue);
  } else if (sortDirection === "DESC") {
    return bValue.localeCompare(aValue);
  }
  return 0;
}

export function sortCompareTypes(aValue: any, bValue: any, sortDirection: "ASC" | "DESC" | "NONE"): number {
  const aTypeKey = getTypeSortKey(aValue);
  const bTypeKey = getTypeSortKey(bValue);
  const aTypeValue = getTypeValue(aValue, aTypeKey);
  const bTypeValue = getTypeValue(bValue, bTypeKey);
  const aArray = getArraySortValue(aValue, aTypeKey);
  const bArray = getArraySortValue(bValue, bTypeKey);

  if (sortDirection === "ASC") {
    if (aTypeKey < bTypeKey)
      return -1;
    if (aTypeKey > bTypeKey)
      return 1;
    const result = aTypeValue.localeCompare(bTypeValue);
    if (result !== 0)
      return result;
  } else if (sortDirection === "DESC") {
    if (aTypeKey < bTypeKey)
      return 1;
    if (aTypeKey > bTypeKey)
      return -1;
    const result = bTypeValue.localeCompare(aTypeValue);
    if (result !== 0)
      return result;
  }
  return aArray > bArray ? 1 : -1;
}

function getTypeValue(rowValue: any, typeSortKey: TypeSortKey): string {
  switch (typeSortKey) {
    case TypeSortKey.Primitive:
      return PropertyTypeCell.formatPrimitiveId(rowValue.primitiveType.id);
    case TypeSortKey.Struct:
      return rowValue.struct.name;
    case TypeSortKey.Navigation:
      return rowValue.nav.roleLabel.name;
    case TypeSortKey.Enumeration:
      return rowValue.enum.name;
    default:
      return "";
  }
}

function getTypeSortKey(value: any): TypeSortKey {
  if (value.hasOwnProperty("primitiveType") && value.primitiveType !== undefined)
    return TypeSortKey.Primitive;
  if (value.hasOwnProperty("struct") && value.struct !== undefined)
    return TypeSortKey.Struct;
  if (value.hasOwnProperty("nav") && value.nav !== undefined)
    return TypeSortKey.Navigation;

  return TypeSortKey.Enumeration;
}

function getArraySortValue(value: any, typeSortKey: TypeSortKey): number {
  return ((typeSortKey === TypeSortKey.Primitive || typeSortKey === TypeSortKey.Struct) && value.isArray) ? 1 : 0;
}

// Add redux actions here
const mapDispatchToProps = (dispatch: any) => {
  return {
    navigateTo: (stage: any, elementType: any, id: any, propertyName: any) => {
      dispatch(NavActions.navigateTo(stage, elementType, id, undefined, propertyName));
    },
  };
};

// Connect to the Redux store
export default connect(null, mapDispatchToProps)(AspectPropertiesTable);
