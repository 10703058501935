/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";

import { NavigationWidget } from "frontend/components/widgets/NavigationWidget";
import { ContentLayer } from "frontend/components/ContentLayer";
import { connect } from "react-redux";

const mapDispatch = {
};

interface Props {
  children?: React.ReactNode;
}

export class FrontstageComponent extends React.Component<Props> {
  public override render() {
    return (
      <div>
        <ContentLayer>
          {this.props.children}
        </ContentLayer >
        <NavigationWidget />
      </div>
    );
  }
}

export const Frontstage = connect(undefined, mapDispatch)(FrontstageComponent);
