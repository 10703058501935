import { STORE_IMODEL_CONTEXT } from "../actionTypes";
import { createAction, createReducer, combineReducers} from "@reduxjs/toolkit";
import { IModelRpcPropsProxy } from "frontend/api/IModelApiProxies";
// tslint:disable:variable-name

export const Actions = {
  storeIModelProps: createAction(STORE_IMODEL_CONTEXT, (iModelProps: IModelRpcPropsProxy) => { return { payload: iModelProps }}),
};


const diagnostics = createReducer({key: ""}, (builder) => {
  builder.addCase(Actions.storeIModelProps, (_state, action) => action.payload);
});

export const reducer = combineReducers({
  diagnostics,
});
