/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import "./LogViewer.css";

interface State {
  lines: any[];
  showLog?(): void;
  logPath?: string;
}

export class LogViewer extends React.Component<{}, State> {
  constructor(props: any, context?: any) {
    super(props, context);
    this.state = { lines: [] };
  }
  public override render() {
    return (
      <div className="log-view">
        <span>Logs are located at: <a role="button" onClick={this.state.showLog}>{this.state.logPath}</a></span>
        <pre>
          {this.state.lines.map((line, idx) => {
            const date = new Date(line.time);
            const dateString = date.toLocaleDateString(undefined, { year: "numeric", month: "2-digit" , day: "2-digit" }).replace(/\//g, "-");
            const timeString = date.toLocaleTimeString(undefined, { hour12: false, hour: "2-digit", minute: "2-digit" });
            const timestamp = `${dateString} ${timeString}`;
            const category = line.loggerCategory || "<No Category>";
            return (
              <span key={idx} className={`row log-level-${line.level}`}>
                { timestamp }{" "}{category}{"\t"}<b>{line.msg}</b><br />
                <span className="error">{line.err && `\t\t\t${line.err.stack.replace(/\n/g, "\n\t\t\t")}` }</span>
              </span>
            );
          })}
        </pre>
      </div>
    );
  }
}
