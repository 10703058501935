/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ListRowRenderer } from "react-virtualized";
import { FuzzySearchResult } from "frontend/api/FuzzySearch";
import { SearchResultComponent } from "./SearchResult";
import { SearchResultsList } from "./SearchResultsList";
import "./SearchBox.scss";
import { ElementSearchResultData } from "frontend/api/Interfaces";
import { SvgSearch } from "@itwin/itwinui-icons-react";
import { Input } from "@itwin/itwinui-react";

export type FuzzySearchResults<T> = Array<FuzzySearchResult<T>>;

export interface SearchProviderProps<T> {
  searchInput: string;
  children(results: FuzzySearchResults<T>): React.ReactNode;
}

export type SearchProviderComponent<T> = React.FunctionComponent<SearchProviderProps<T>>;

interface Props<T> {
  resultComponent: SearchResultComponent<T>;
  providerComponent: SearchProviderComponent<T>;
  onSelectResult(result: T): void;
}

interface State {
  prevPattern: string;
  curPattern: string;
  curRow: number;
}

export class SearchBox<T> extends React.Component<Props<T>, State> {
  private _timeoutId: number = 0;
  private _inputRef = React.createRef<HTMLInputElement>();
  private _maxWidthResultList: number = 0;
  private _defaultState: State = {
    curPattern: "",
    prevPattern: "",
    curRow: 0,
  };
  private _prevState: State = {
    curPattern: "",
    prevPattern: "",
    curRow: 0,
  };
  public override readonly state: State = this._defaultState;

  private _onInputChange = (_e: React.ChangeEvent<HTMLInputElement>) => {
    if (this._inputRef.current) {
      clearTimeout(this._timeoutId);
      this._timeoutId = window.setTimeout(() => this.setState( (prevState) => {
        return {
          curRow: 0,
          prevPattern: prevState.curPattern,
          curPattern: this._inputRef.current!.value,
        };
      }), 150);
    }
  };

  private closeResultsList() {
    if (this.state.curPattern !== "") {
      this._prevState = this.state;
      this.setState(this._defaultState);
      if (this._inputRef.current) {
        this._inputRef.current.blur();
      }
    }
    this._maxWidthResultList = 0;
  }

  private restorePrevState() {
    if(this._inputRef.current?.value !== "")
      this.setState(this._prevState);
  }

  private _onInputFocus = (_e: React.FocusEvent<HTMLInputElement>) => {
    this.restorePrevState();
  };

  private selectItem(item: T) {
    this.props.onSelectResult(item);
    this.closeResultsList();
  }

  private renderResultsList(resultsData: FuzzySearchResults<T>) {
    if (!this.state.curPattern)
      return undefined;
    const calculateMaxWidth = (maxCheck: number) => {
      const inputWidth = (this._inputRef.current) ? this._inputRef.current.width || 0 : 0;
      if (this.state.prevPattern === this.state.curPattern) {
        return Math.max(this._maxWidthResultList,inputWidth);
      }
      let maxChars = 0;
      let count = 0;
      for (const item of resultsData) {
        if (count >= maxCheck) break; // maxCheck limits the number of strings used to resize the list component's width.
        const result = item.getResult() as any as ElementSearchResultData;
        const lengthId = result.id.length;
        const lengthLabel = result.label ? result.label.length : 0;
        maxChars = Math.max(maxChars, lengthId + lengthLabel);
        count++;
      }

      this._maxWidthResultList = Math.max(maxChars * 7  + 100, inputWidth); // Adjust maxChars into maximum width.

      return this._maxWidthResultList;
    };
    const noResultsRenderer = () => <span className="no-results">No results found for &quot;{this.state.curPattern}&quot;</span>;
    const resultRenderer: ListRowRenderer = ({ key, index, style }) => {

      const Result = this.props.resultComponent;
      return (
        <Result
          key={key}
          data={resultsData[index]}
          style={style}
          isSelected={this.state.curRow === index}
          onHover={() => this.setState((prevState) => ({...prevState, prevPattern: prevState.curPattern, curRow: index}))}
          onClick={() => this.selectItem(resultsData[index].getResult())} />
      );
    };

    return (
      <SearchResultsList
        searchBoxWidth={calculateMaxWidth(20)}
        currentResultIndex={this.state.curRow}
        numResults={resultsData.length}
        resultRenderer={resultRenderer}
        noResultsRenderer={noResultsRenderer}
      />
    );
  }

  private renderInputBox(resultsData: FuzzySearchResults<T>) {
    const callbacks = {
      onClose: () => this.closeResultsList(),
      onFocus: () => {
        this._inputRef.current && this._inputRef.current.focus();
        this.restorePrevState();
      },
      onSelectCurrentItem: () => { if (resultsData[this.state.curRow]) {return this.selectItem(resultsData[this.state.curRow].getResult()); }},
      onScroll: (offset: number) => {
        const newIndex = Math.max(0, Math.min(resultsData.length - 1, this.state.curRow + offset));
        this.setState((prevState) => ({...prevState, prevPattern: prevState.curPattern, curRow: newIndex}));
      },
    };

    const className = `imse-search${(this.state.curPattern) || this._inputRef.current?.value !== "" ? " has-results" : ""}`;
    return (
      <>
        <SearchBoxKeyboardHandler {...callbacks} >
          {
            (events) =>
              <Input type="search" className={className} placeholder="Search (Ctrl+F)" ref={this._inputRef} onFocus={this._onInputFocus} onChange={this._onInputChange} {...events} />
          }
        </SearchBoxKeyboardHandler>
        <SvgSearch />
      </>
    );
  }

  public override render() {
    const Provider = this.props.providerComponent; // tslint:disable-line:variable-name
    return (
      <Provider searchInput={this.state.curPattern}>
        {
          (results) =>
            <>
              {this.renderInputBox(results)}
              {this.renderResultsList(results)}
            </>
        }
      </Provider>
    );
  }
}

interface EventHandlers {
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
}

interface KeyboardHandlerProps {
  children(handlers: EventHandlers): React.ReactNode;
  onFocus(): void;
  onClose(): void;
  onScroll(offset: number): void;
  onSelectCurrentItem(): void;
}

class SearchBoxKeyboardHandler extends React.Component<KeyboardHandlerProps> {
  private _globalKeyboardHook = (e: KeyboardEvent) => {
    if ((e.key === "F" || e.key === "f") && e.ctrlKey) {
      this.props.onFocus();
      e.preventDefault();
    }
  };

  public override componentDidMount() {
    window.addEventListener("keydown", this._globalKeyboardHook, true);
  }

  public override componentWillUnmount() {
    window.removeEventListener("keydown", this._globalKeyboardHook, true);
  }

  private _onBlur = (_e: React.FocusEvent<HTMLInputElement>) => {
    this.props.onClose();
  };

  private _onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const scroll = (offset: number) => {
      this.props.onScroll(offset);
      e.preventDefault();
    };

    switch (e.key) {
      case "ArrowUp": return scroll(-1);
      case "ArrowDown": return scroll(1);
      case "PageUp": return scroll(-20);
      case "PageDown": return scroll(20);
      case "Enter": {
        this.props.onSelectCurrentItem();
      }
    }
  };

  public override render() {
    return (
      <>
        {this.props.children({ onBlur: this._onBlur, onKeyDown: this._onKeyDown })}
      </>
    );
  }
}
