/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { Schema } from "@itwin/ecschema-metadata";

export const STANDARD_SCHEMAS: string[] = [
  /* "Bentley_Common_Classes",
  "Bentley_ECSchemaMap",
  "Bentley_Standard_Classes",
  "Bentley_Standard_CustomAttributes",
  "CoreClasses",
  "CoreCustomAttributes",
  "Dimension_Schema",
  "ECDbMap",
  "ECDb_FileInfo",
  "ECDb_System",
  "ECObjects",
  "ECv3ConversionAttributes",
  "EditorCustomAttributes",
  "Formats",
  "KindOfQuantity_Schema",
  "SIUnitSystemDefaults",
  "SchemaLocalizationCustomAttributes",
  "USCustomaryUnitSystemDefaults",
  "Unit_Attributes",
  "Units",
  "Units_Schema",
  "iip_mdb_customAttributes",
  "rdl_customAttributes",*/
];

export function isStandardSchema(schema: Schema | string): boolean {
  const name = schema instanceof Schema ? schema.name : schema;
  return STANDARD_SCHEMAS.includes(name);
}

export function wrapForEcsql(schemaList: string[]): string {
  return schemaList.map((s) => `'${s}'`).join(",");
}
