/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { ISchemaColorSet, SchemaColorCoder } from "frontend/components/controls/SchemaColorCoder";
import "./SchemaTag.scss";
import * as React from "react";

interface Props {
  children?: React.ReactNode;
  schemaName: string;
  schemaAlias: string;
  isSchema?: boolean;
}

interface State {
  background?: string;
  foreground?: string;
  border?: string;
}

export class SchemaTag extends React.Component<Props, State> {
  private _unmounted = false;

  constructor(props: any, context?: any) {
    super(props, context);
    const colors = SchemaColorCoder.getColorSetForSchema(props.schemaName);
    this.state = this.stateFromColor(colors, props);
  }

  private stateFromColor(colors: ISchemaColorSet, props: any): State {
    return {
      background: colors.background,
      foreground: props.isSchema ? "transparent" : colors.text,
      border: colors.border,
    };
  }

  private updateColor(props: Props) {
    const colors = SchemaColorCoder.getColorSetForSchema(props.schemaName);
    if (!this._unmounted)
      this.setState(this.stateFromColor(colors, props));
  }

  public override componentWillUnmount() {
    this._unmounted = true;
  }

  public override async componentDidUpdate(prevProps: Props) {
    if (prevProps.schemaName !== this.props.schemaName || prevProps.isSchema !== this.props.isSchema)
      this.updateColor(this.props);
  }

  public override render() {
    const style: React.CSSProperties = {
      backgroundColor: this.state.background,
      color: this.state.foreground,
      borderColor: this.state.border,
    };

    return (
      <div className="schema-tag">
        <span style={style}>
          {(!this.props.isSchema) ? this.props.schemaAlias : "_"}
        </span>
      </div>
    );
  }
}

export type SchemaTagProps = Props;
