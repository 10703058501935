/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { connect } from "react-redux";
import { RootState } from "frontend/state/rootReducer";
import { Actions } from "frontend/state/reducers/settings";
import "./SchemaFilterButton.css";
import * as React from "react";
import { SvgVisibilityHide, SvgVisibilityShow } from "@itwin/itwinui-icons-react";

interface Props {
  schemaName: string;
  // These are all connected to redux:
  toggleExcludeSchema(s: string): any;
  excludeSchemas: string[];
}

interface State {
  icon: JSX.Element;
}

const mapState = (state: RootState) => ({
  excludeSchemas: state.settings.schemaFilters.excludeSchemas,
});

const mapDispatch = {
  toggleExcludeSchema: (schema: string) => Actions.toggleExcludeSchema(schema),
};

export class SchemaFilterButtonComponent extends React.Component<Props, State> {
  public initialized?: Promise<any>;

  constructor(props: any, context?: any) {
    super(props, context);
    this.onClick = this.onClick.bind(this);
    this.state = { icon: props.excludeSchemas.indexOf(props.schemaName) === -1 ? visibleIcon : notVisibleIcon };
  }

  public onClick(e: React.MouseEvent<any>) {
    this.props.toggleExcludeSchema(this.props.schemaName);
    e.stopPropagation();
  }

  public override async componentDidMount() {
    this.initialized = Promise.resolve();
  }

  public override componentDidUpdate(prevProps: Props) {
    if (this.props.excludeSchemas !== prevProps.excludeSchemas) {
      if (this.props.excludeSchemas.indexOf(this.props.schemaName) === -1)
        this.setState({ icon: visibleIcon });
      else
        this.setState({ icon: notVisibleIcon });
    }
  }

  public override render() {
    return (
      <div className="schema-filter-container" onClick={this.onClick} role="button">
        {this.state.icon}
      </div>
    );
  }
}

export const SchemaFilterButton = connect(mapState, mapDispatch)(SchemaFilterButtonComponent);
export const visibleIcon = <SvgVisibilityShow className="schema-filter"/>;
export const notVisibleIcon = <SvgVisibilityHide className="schema-filter" />;
