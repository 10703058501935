/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { RelationshipConstraintInfo } from "frontend/api/Interfaces";
import { ElementLink } from "frontend/components/controls/ElementLink";
import { ExpansionToggle } from "@itwin/core-react";
import { Text } from "@itwin/itwinui-react";

import "./UmlEndpoint.scss";

export interface Props extends RelationshipConstraintInfo {
  expanded?: boolean;
  small?: boolean;
  onToggleExpand?(): void;
}

export class UmlEndpoint extends React.Component<Props> {

  private renderExpansionToggle() {
    if (!this.props.onToggleExpand)
      return undefined;

    const onClick = () => this.props.onToggleExpand && this.props.onToggleExpand();
    return (<ExpansionToggle style={{float: "left"}} isExpanded={this.props.expanded} onClick={onClick} />);
  }

  private renderDetails() {
    if (this.props.small)
      return undefined;

    const constraintClassLinks = this.props.classes.map((linkData) => {
      return (
        <span key={linkData.id} className="value">
          <ElementLink {...linkData} />
        </span>
      );
    });

    return (
      <div className="details">
        <span className="label">Constraint Classes:</span>
        <div className="constraint-classes">
          {constraintClassLinks}
        </div>
        <span className="label">Is Polymorphic:</span>
        <span className="value">{(this.props.isPolymorphic) ? "True" : "False"}</span>
      </div>
    );
  }

  public override render() {
    const classNames = ["uml-endpoint"];
    if (this.props.onToggleExpand) {
      classNames.push("collapsible");

      if (!this.props.expanded)
        classNames.push("collapsed");
    }

    if (this.props.small)
      classNames.push("small");

    const variant = this.props.small ? "small" : "leading";

    return (
      <div className={classNames.join(" ")}>
        {this.renderExpansionToggle()}
        <Text variant={variant} as="span" className="main-link">
          <ElementLink {...this.props.abstractConstraint} />
        </Text>

        {this.renderDetails()}
      </div>
    );
  }
}
