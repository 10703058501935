import React from "react";
import { Icon } from "@itwin/itwinui-react";

export function SmallConstantIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="constant_16">
        <title>Constant</title>
        <path d="M2.0202,11.9703c1.6414-1.8489,2.6515-5.1336,2.601-8.9547H3.3586C1.5909,2.9663,1.0859,4.476.6313,5.2402H0C1.3131,2.7257.4545,1,5.5556,1L15,1.0004v2l-3.5858.0152c-.8334,7.5437-.7071,8.8315.505,9.5217.5556.3205,2.2727.4684,2.5-1.7256H15c0,5.0784-6.1869,5.6207-6.3131,1.2573-.0253-.6657.202-3.6052.707-9.078H6.0101s-.6313,7.9686-.9091,9.0533c-.202.7889-.3535,2.6378-1.9949,2.8597C1.9697,15.1012.3535,13.8193,2.0202,11.9703Z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function SmallCustomAttributeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="custom_attribute_16">
        <defs>
          <linearGradient id="caGradientA" x1="9.81" y1="9.9" x2="12.22" y2="14.07" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#fff" />
            <stop offset="1" stopColor="#eef0f3" />
          </linearGradient>
          <linearGradient id="caGradientB" x1="8.75" y1="11.44" x2="10.75" y2="14.92" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#008be1" />
            <stop offset="1" stopColor="#0073ba" />
          </linearGradient>
        </defs>
        <title>CustomAttribute</title>
        <path d="M6.6 10.93l.03-.03.03-.03 4.03-4A2.95 2.95 0 0 1 12 6.1V3L6 0 0 3v6.25L6 12l.08-.04a2.58 2.58 0 0 1 .52-1.03z" fill="#cdecff" fillRule="evenodd" />
        <path d="M6.6 10.93l.03-.03.03-.03 4.03-4A2.95 2.95 0 0 1 12 6.1V3L6 5.75V12l.08-.04a2.58 2.58 0 0 1 .52-1.03z" fill="#7fceff" fillRule="evenodd" />
        <path d="M1.5 7.43a.68.68 0 0 1 .38.55c0 .2-.17.3-.38.2a.68.68 0 0 1-.38-.55c0-.2.17-.3.38-.2zm1.5.7l1.5.7a.68.68 0 0 1 .38.55c0 .2-.17.3-.38.2L3 8.88a.68.68 0 0 1-.38-.55c0-.2.17-.3.38-.2z" fill="#56aa1c" fillRule="evenodd" />
        <path d="M1.5 5.18a.68.68 0 0 1 .38.55c0 .2-.17.3-.38.2a.68.68 0 0 1-.38-.55c0-.2.17-.3.38-.2zm1.5.7l1.5.7a.68.68 0 0 1 .38.55c0 .2-.17.3-.38.2L3 6.63a.68.68 0 0 1-.38-.55c0-.2.17-.3.38-.2z" fill="#f18b12" fillRule="evenodd" />
        <path d="M6.6 10.93l.03-.03.03-.03.5-.5L6 10.9 1 8.6V3.63l5-2.5 5 2.5v2.97a2.9 2.9 0 0 1 1-.5V3L6 0 0 3v6.25L6 12l.08-.04a2.58 2.58 0 0 1 .52-1.03z" fill="#008be1" />
        <path d="M14.88 7h-2.26a1.85 1.85 0 0 0-1.23.57l-4.03 4.01a1.28 1.28 0 0 0 0 1.72l2.3 2.29a1.15 1.15 0 0 0 1.73 0l4.03-4.01a2.1 2.1 0 0 0 .58-1.2V8.12A1.1 1.1 0 0 0 14.87 7zm-.38 2a.5.5 0 1 1 .5-.5.5.5 0 0 1-.5.5z" fill="#2a2f34" fillRule="evenodd" />
        <path d="M13.8 10.83c.45.46 0 1.1 0 1.1l-2.7 2.74a.82.82 0 0 1-1.22.12l-1.66-1.67c-.58-.58.17-1.2.17-1.2l2.7-2.73c.44-.43 1.09 0 1.09 0z" fillRule="evenodd" fill="url(#caGradientA)" />
        <path d="M9.03 11.28l-.64.65s-.75.6-.17 1.2l1.66 1.66a.82.82 0 0 0 1.21-.12l.66-.67z" fillRule="evenodd" fill="url(#caGradientB)" />
      </svg>
    </Icon>
  );
}

export function SmallSchemaIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="schema_16">
        <title>Schema</title>
        <path fill="#cdecff" fillRule="evenodd" d="M12 7V3L8 1 4 3v4L0 9v4l4 2 4-2 4 2 4-2V9l-4-2z" />
        <path d="M12 11.1l4-2.05V13l-4 2zM4 15l4-2V9.04L4 11.1zm4-6l4-2V3.06L8 5.1z" fill="#7fceff" fillRule="evenodd" />
        <path d="M9.25 11.81a.46.46 0 0 1 .25.38c0 .13-.11.2-.25.13a.46.46 0 0 1-.25-.37c0-.14.11-.2.25-.14zm1 .48l1 .47a.46.46 0 0 1 .25.37c0 .14-.1.2-.25.13l-1-.47a.46.46 0 0 1-.25-.37c0-.14.11-.2.25-.13zm-9-.48a.46.46 0 0 1 .25.37c0 .14-.11.2-.25.13a.46.46 0 0 1-.25-.37c0-.14.11-.2.25-.13zm1 .47l1 .47a.46.46 0 0 1 .25.37c0 .14-.1.2-.25.14l-1-.48a.46.46 0 0 1-.25-.37c0-.14.11-.2.25-.13zm3-6.47a.46.46 0 0 1 .25.38c0 .13-.11.2-.25.13A.46.46 0 0 1 5 5.95c0-.14.11-.2.25-.14zm1 .48l1 .47a.46.46 0 0 1 .25.37c0 .14-.1.2-.25.13l-1-.47A.46.46 0 0 1 6 6.42c0-.14.11-.2.25-.13z" fill="#56aa1c" fillRule="evenodd" />
        <path d="M9.25 10.3a.46.46 0 0 1 .25.38c0 .14-.11.2-.25.13a.46.46 0 0 1-.25-.37c0-.14.11-.2.25-.13zm1 .48l1 .47a.46.46 0 0 1 .25.38c0 .14-.1.2-.25.13l-1-.47a.46.46 0 0 1-.25-.37c0-.14.11-.2.25-.14zm-9-.48a.46.46 0 0 1 .25.37c0 .14-.11.2-.25.14a.46.46 0 0 1-.25-.38c0-.14.11-.2.25-.13zm1 .47l1 .48a.46.46 0 0 1 .25.37c0 .14-.1.2-.25.13l-1-.47a.46.46 0 0 1-.25-.37c0-.14.11-.2.25-.14zm3-6.46a.46.46 0 0 1 .25.37c0 .14-.11.2-.25.13A.46.46 0 0 1 5 4.44c0-.14.11-.2.25-.13zm1 .47l1 .47a.46.46 0 0 1 .25.38c0 .14-.1.2-.25.13l-1-.47A.46.46 0 0 1 6 4.92c0-.14.11-.2.25-.14z" fill="#f18b12" fillRule="evenodd" />
        <path d="M12 7V3L8 1 4 3v4L0 9v4l4 2 4-2 4 2 4-2V9zM5 3.62l3-1.5 3 1.5v2.77l-3 1.5-3-1.5zm2 8.76l-3 1.5-3-1.5V9.61l3-1.5 3 1.5zm8 0l-3 1.5-3-1.5V9.63l3-1.5 3 1.5z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function SmallPropertyCategoryIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="property_category_16">
        <title>PropertyCategory</title>
        <path d="M14.75 5H8.24a4.5 4.5 0 1 0 0 5h6.51A1.25 1.25 0 0 0 16 8.75v-2.5A1.25 1.25 0 0 0 14.75 5z" fill="#cdecff" fillRule="evenodd" />
        <path d="M4.5 5A2.5 2.5 0 1 1 2 7.5 2.5 2.5 0 0 1 4.5 5z" fill="#fff" fillRule="evenodd" />
        <path d="M8.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zm-4-1A1.5 1.5 0 1 1 3 7.5 1.5 1.5 0 0 1 4.5 6z" fill="#f18b12" fillRule="evenodd" />
        <path d="M4.5 4a3.5 3.5 0 0 1 2.9 1.56l.3.44h7.05a.25.25 0 0 1 .25.25v2.5a.25.25 0 0 1-.25.25H7.71l-.3.44A3.5 3.5 0 1 1 4.5 4m0-1a4.5 4.5 0 1 0 3.74 7h6.51A1.25 1.25 0 0 0 16 8.75v-2.5A1.25 1.25 0 0 0 14.75 5H8.24A4.5 4.5 0 0 0 4.5 3z" fill="#008be1" />
        <path d="M12 0v1h3v3h1V0zm4 11h-1v3h-3v1h4zM0 11h1v3h3v1H0zM4 0v1H1v3H0V0z" fill="#3c434a" fillRule="evenodd" />
      </svg>
    </Icon>
  );
}

export function SmallEntityClassIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="entity_class_16">
        <title>Entity</title>
        <path d="M8 0L0 4v8.33L8 16l8-3.67V4z" fill="#cdecff" fillRule="evenodd" />
        <path fill="#7fceff" fillRule="evenodd" d="M8 7.67V16l8-3.67V4L8 7.67z" />
        <path d="M2 9.9a.9.9 0 0 1 .5.74c0 .28-.22.4-.5.27a.9.9 0 0 1-.5-.73c0-.28.22-.4.5-.27zm2 .94l2 .93a.9.9 0 0 1 .5.74c0 .27-.22.4-.5.26l-2-.93a.9.9 0 0 1-.5-.73c0-.28.22-.4.5-.27z" fill="#56aa1c" fillRule="evenodd" />
        <path d="M2 6.9a.9.9 0 0 1 .5.74c0 .28-.22.4-.5.27a.9.9 0 0 1-.5-.73c0-.28.22-.4.5-.27zm2 .94l2 .93a.9.9 0 0 1 .5.74c0 .27-.22.4-.5.26l-2-.93a.9.9 0 0 1-.5-.73c0-.28.22-.4.5-.27z" fill="#f18b12" fillRule="evenodd" />
        <path d="M8 1.12l7 3.5v7.07L8 14.9l-7-3.2V4.61l7-3.5M8 0L0 4v8.33L8 16l8-3.67V4L8 0z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function SmallEnumerationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="enumeration_16">
        <title>Enumeration</title>
        <path d="M2 0h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" fill="#cdecff" fillRule="evenodd" />
        <path d="M4.5 2A2.5 2.5 0 1 1 2 4.5 2.5 2.5 0 0 1 4.5 2zm4 1h4a1.5 1.5 0 0 1 0 3h-4a1.5 1.5 0 0 1 0-3zm-4 6A2.5 2.5 0 1 1 2 11.5 2.5 2.5 0 0 1 4.5 9zm4 1h4a1.5 1.5 0 0 1 0 3h-4a1.5 1.5 0 0 1 0-3z" fill="#fff" fillRule="evenodd" />
        <path d="M4.5 10A1.5 1.5 0 1 1 3 11.5 1.5 1.5 0 0 1 4.5 10m8 1a.5.5 0 0 1 0 1h-4a.5.5 0 0 1 0-1h4" fill="#56aa1c" />
        <path d="M4.5 3A1.5 1.5 0 1 1 3 4.5 1.5 1.5 0 0 1 4.5 3m8 1a.5.5 0 0 1 0 1h-4a.5.5 0 0 1 0-1h4" fill="#f18b12" />
        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12m0-1H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function SmallFormatIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="format_16">
        <title>Format</title>
        <path d="M11.23073,11.12841a2.61607,2.61607,0,0,1-.413,1.60937,1.44362,1.44362,0,0,1-1.219.53767,1.388,1.388,0,0,1-1.18007-.5563A2.60533,2.60533,0,0,1,8,11.12841Q8,9,9.59866,9a1.43212,1.43212,0,0,1,1.209.55215A2.54121,2.54121,0,0,1,11.23073,11.12841Zm-2.25327.00931a2.77931,2.77931,0,0,0,.14472,1.03394.49314.49314,0,0,0,.47648.34q.66129,0,.66129-1.37392T9.59866,9.76381a.49312.49312,0,0,0-.47648.34A2.77925,2.77925,0,0,0,8.97746,11.13772Zm5.5352-2.03992-4.06345,6.80905H9.47843L13.54077,9.0978ZM16,13.85762a2.59178,2.59178,0,0,1-.41525,1.60627A1.43939,1.43939,0,0,1,14.37239,16a1.40206,1.40206,0,0,1-1.1823-.55164,2.58617,2.58617,0,0,1-.42082-1.59074q0-2.12841,1.60312-2.12841a1.4356,1.4356,0,0,1,1.20011.54957A2.52652,2.52652,0,0,1,16,13.85762Zm-2.25327,0a2.79357,2.79357,0,0,0,.147,1.03911.49716.49716,0,0,0,.47871.3436q.65628,0,.65683-1.38271,0-1.36461-.65683-1.36461a.49746.49746,0,0,0-.47871.33533A2.71,2.71,0,0,0,13.74673,13.85762Z" fill="#008be1" />
        <path d="M2.8274,9.81853H1.71781v4.09325H.748V9.81853H0v-.4852l.748-.31758V8.69817A1.85851,1.85851,0,0,1,1.126,7.41707,1.48147,1.48147,0,0,1,2.28082,7a2.89216,2.89216,0,0,1,1.00274.18084L3.02877,7.966a2.0891,2.0891,0,0,0-.68219-.1235.53753.53753,0,0,0-.47854.21858,1.15677,1.15677,0,0,0-.15023.65477V9.0334H2.8274Zm2.46575,3.34341A2.22846,2.22846,0,0,0,6,13.04334v.78023a1.82966,1.82966,0,0,1-.41279.12546A2.62563,2.62563,0,0,1,5.063,14q-1.37259,0-1.3726-1.55261V9.81853H3.06986V9.3598l.66576-.37933.32876-1.03214h.59589V9.0334H5.95479v.78513H4.66027v2.61121a.767.767,0,0,0,.17443.55381A.61433.61433,0,0,0,5.29315,13.16194Z" fill="#f18b12" />
        <path d="M9.75,5.26444a1.46861,1.46861,0,0,1-.5021,1.14279,2.53557,2.53557,0,0,1-1.44538.55465V8h-.6292V6.99057A4.82537,4.82537,0,0,1,5.25,6.62717V5.61828a4.76743,4.76743,0,0,0,.94958.33736,4.274,4.274,0,0,0,.97374.14079V4.30814l-.39706-.14823a2.79654,2.79654,0,0,1-1.13235-.71935A1.54261,1.54261,0,0,1,5.28782,2.4051a1.3542,1.3542,0,0,1,.50735-1.0854A2.50294,2.50294,0,0,1,7.17332.80329V0h.6292V.789A5.15941,5.15941,0,0,1,9.62185,1.181l-.34454.875a5.03649,5.03649,0,0,0-1.47479-.35383V3.44269l.35924.13866a3.2807,3.2807,0,0,1,1.24265.73635A1.37283,1.37283,0,0,1,9.75,5.26444ZM6.39811,2.39554a.64174.64174,0,0,0,.16807.45424,1.71313,1.71313,0,0,0,.60714.35861v-1.487a1.09857,1.09857,0,0,0-.57668.2343A.56533.56533,0,0,0,6.39811,2.39554ZM8.63445,5.33617a.59845.59845,0,0,0-.19222-.4569,1.97426,1.97426,0,0,0-.63971-.3464V6.05818C8.35609,5.97211,8.63445,5.73144,8.63445,5.33617Z" fill="#56a91c" />
      </svg>
    </Icon>
  );
}

export function SmallKindOfQuantityIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="kind_of_quantity_16">
        <defs>
          <linearGradient id="koqGradientA" x1="2.25" y1="18.25" x2="12.75" y2="7.75" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#e3b568" />
            <stop offset="1" stopColor="#d19d54" />
          </linearGradient>
        </defs>
        <title>KindOfQuantity</title>
        <path d="M13.75 2H8.24a4.5 4.5 0 1 0 0 5h5.51A1.25 1.25 0 0 0 15 5.75v-2.5A1.25 1.25 0 0 0 13.75 2z" fill="#cdecff" />
        <path d="M4.5 2A2.5 2.5 0 1 1 2 4.5 2.5 2.5 0 0 1 4.5 2z" fill="#fff" />
        <path d="M8.5 4h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1 0-1zm-4-1A1.5 1.5 0 1 1 3 4.5 1.5 1.5 0 0 1 4.5 3z" fill="#f18b12" />
        <path d="M4.5 1a3.5 3.5 0 0 1 2.9 1.56l.3.44h6.05a.25.25 0 0 1 .25.25v2.5a.25.25 0 0 1-.25.25H7.71l-.3.44A3.5 3.5 0 1 1 4.5 1m0-1a4.5 4.5 0 1 0 3.74 7h5.51A1.25 1.25 0 0 0 15 5.75v-2.5A1.25 1.25 0 0 0 13.75 2H8.24A4.5 4.5 0 0 0 4.5 0z" fill="#008be1" />
        <path fill="url(#koqGradientA)" d="M0 10h15v6H0z" />
        <path d="M0 10v6h15v-6zm14 5H1v-4h1v2h1v-2h1v1h1v-1h1v2h1v-2h1v1h1v-1h1v2h1v-2h1v1h1v-1h1z" fill="#a07033" />
      </svg>
    </Icon>
  );
}

export function SmallMixinIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="mixin_16">
        <defs>
          <linearGradient id="mixinGradientA" x1="13.89" y1="15.23" x2="8.1" y2="9.44" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#dce0e3" />
            <stop offset="1" stopColor="#fff" />
          </linearGradient>
          <linearGradient id="mixinGradientB" x1="11" y1="12.94" x2="11" y2="9.06" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#c7ccd1" />
            <stop offset="1" stopColor="#dce0e3" />
          </linearGradient>
        </defs>
        <title>Mixin</title>
        <path d="M5.04 11.33A1.58 1.58 0 0 1 5 11c0-2.06 3.11-3 6-3 .33 0 .67.01 1 .04V3L6 0 0 3v6.25l5.09 2.33-.05-.25z" fill="#cdecff" fillRule="evenodd" />
        <path d="M11 8c.33 0 .67.01 1 .04V3L6 5.75v3.5A9 9 0 0 1 11 8z" fill="#7fceff" fillRule="evenodd" />
        <path d="M1.5 7.43a.68.68 0 0 1 .38.55c0 .2-.17.3-.38.2a.68.68 0 0 1-.38-.55c0-.2.17-.3.38-.2zm1.5.7l1.5.7a.68.68 0 0 1 .38.55c0 .2-.17.3-.38.2L3 8.88a.68.68 0 0 1-.38-.55c0-.2.17-.3.38-.2z" fill="#56aa1c" fillRule="evenodd" />
        <path d="M1.5 5.18a.68.68 0 0 1 .38.55c0 .2-.17.3-.38.2a.68.68 0 0 1-.38-.55c0-.2.17-.3.38-.2zm1.5.7l1.5.7a.68.68 0 0 1 .38.55c0 .2-.17.3-.38.2L3 6.63a.68.68 0 0 1-.38-.55c0-.2.17-.3.38-.2z" fill="#f18b12" fillRule="evenodd" />
        <path d="M6 0L0 3v6.25l5.09 2.33-.05-.25A1.58 1.58 0 0 1 5 11a1.9 1.9 0 0 1 .08-.52L1 8.6v-5l5-2.5 5 2.5V8c.33 0 .67.01 1 .04V3z" fill="#008be1" fillRule="evenodd" />
        <path d="M6.26 11.28c.47 2.4.91 4.6 4.75 4.6 3.82 0 4.27-2.3 4.73-4.6z" fill="url(#mixinGradientA)" />
        <path d="M11 9.06c2.67 0 4.84.87 4.84 1.94s-2.17 1.94-4.84 1.94-4.84-.87-4.84-1.94S8.33 9.06 11 9.06z" fillRule="evenodd" fill="url(#mixinGradientB)" />
        <path d="M16 11c0-1.1-2.24-2-5-2s-5 .9-5 2a.85.85 0 0 0 .02.16c.5 2.54.94 4.84 4.99 4.84 4.03 0 4.5-2.44 4.98-4.86A.78.78 0 0 0 16 11zm-5-1c2.35 0 3.72.67 3.98 1-.26.33-1.63 1-3.98 1s-3.72-.67-3.98-1c.26-.33 1.63-1 3.98-1zm0 5c-2.6 0-3.3-.99-3.73-2.68A9.41 9.41 0 0 0 11 13a9.43 9.43 0 0 0 3.72-.67C14.28 13.97 13.57 15 11 15z" fill="#677480" />
        <path d="M11.12 11.98V12a10.02 10.02 0 0 0 2.12-.24 1.8 1.8 0 0 0-.13-.7L15 5.47a.38.38 0 0 0-.33-.46.5.5 0 0 0-.58.27l-1.78 5.3a1.83 1.83 0 0 0-1.18 1.4z" fill="#4d575f" fillRule="evenodd" />
      </svg>
    </Icon>
  );
}

export function SmallPhenomenonIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon>
      <svg {...props} xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16" id="phenomenon_16">
        <defs>
          <linearGradient id="phenomGradientA" x1="5.81657" y1="5.8166" x2="10.1834" y2="10.18343" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#e3b568" />
            <stop offset="1" stopColor="#d19d54" />
          </linearGradient>
          <linearGradient id="phenomGradientB" x1="10.4995" y1="9.49915" x2="10.4995" y2="15.49915" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#fff" />
            <stop offset="1" stopColor="#eef0f3" />
          </linearGradient>
        </defs>
        <title>Phenomenon</title>
        <path d="M4.56854,13.30347a2.012,2.012,0,0,1,0-2.7956,8.26309,8.26309,0,0,1,7.62451-2.33416l3.20612-3.20612a.34234.34234,0,0,0,0-.48529L11.51758.60083a.34212.34212,0,0,0-.48511,0L.60077,11.03241a.34255.34255,0,0,0,0,.48529l3.88159,3.88147a.34245.34245,0,0,0,.48529,0l.83386-.83386Z" fill="url(#phenomGradientA)" />
        <polygon points="4.102 9.042 3.397 9.745 4.336 10.681 5.041 9.979 4.102 9.042" fill="#a07033" />
        <path d="M1.98877,11.14941l1.87811,1.87342.31049-.3097a2.01324,2.01324,0,0,1-.16071-.94592L2.693,10.447Z" fill="#a07033" />
        <path d="M5.24646,13.99725l-.73981.73981L1.26288,11.49335,11.49335,1.263l3.24371,3.24359-3.527,3.527a8.22546,8.22546,0,0,1,1.21491.19934l3.46747-3.46747a.36526.36526,0,0,0,0-.51764L11.75208.10754a.365.365,0,0,0-.51746,0L.10748,11.23456a.36536.36536,0,0,0,0,.51764l4.14038,4.14026a.36523.36523,0,0,0,.51764,0l1.17987-1.17987Z" fill="#a07033" />
        <path d="M5.51019,7.637l-.70434.70264L5.8811,9.41205A8.23429,8.23429,0,0,1,6.7713,8.895Z" fill="#a07033" />
        <polygon points="9.031 4.125 9.971 5.061 10.675 4.359 9.736 3.422 9.031 4.125" fill="#a07033" />
        <polygon points="7.623 5.53 9.501 7.403 10.205 6.701 8.327 4.827 7.623 5.53" fill="#a07033" />
        <polygon points="6.214 6.935 7.154 7.871 7.858 7.169 6.919 6.232 6.214 6.935" fill="#a07033" />
        <polygon points="13.022 3.891 11.144 2.017 10.44 2.72 12.318 4.593 13.022 3.891" fill="#a07033" />
        <path d="M10.49905,14.74821a2.21087,2.21087,0,0,0-1.64048.75094L5.4995,11.78725a6.7302,6.7302,0,0,1,4.99955-2.2881h.00089a6.73022,6.73022,0,0,1,4.99956,2.2881l-3.35907,3.7119a2.21088,2.21088,0,0,0-1.64049-.75094Z" fill="url(#phenomGradientB)" />
        <polygon points="9.853 15.219 9.219 11 11.054 14.824 9.853 15.219" fill="#d30a0a" />
        <path d="M10.4999,9.9983A6.27253,6.27253,0,0,1,15,11.9057L11.9765,15a2.06051,2.06051,0,0,0-1.4766-.626h-.0008A2.06051,2.06051,0,0,0,9.0225,15L5.999,11.9057a6.27253,6.27253,0,0,1,4.5001-1.9074h.0008m0-1a7.24342,7.24342,0,0,0-5.21612,2.2085,1,1,0,0,0,0,1.39778l3.0235,3.0943A1,1,0,0,0,9.022,16h.00048a1,1,0,0,0,.71457-.30042,1.05553,1.05553,0,0,1,1.52488,0A1,1,0,0,0,11.9765,16H11.977a1,1,0,0,0,.71475-.30112l3.0235-3.0943a1,1,0,0,0,0-1.39778A7.2425,7.2425,0,0,0,10.4999,8.9983Z" fill="#677480" />
      </svg>
    </Icon>
  );
}

export function SmallRelationshipClassIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="relationship_class_16">
        <title>Relationship</title>
        <path d="M13.5 3.5A.5.5 0 0 0 13 3H8a.5.5 0 0 0 0 1h3.7l-9.03 8.13a.5.5 0 0 0-.17.37.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1H4.3l9.04-8.13a.5.5 0 0 0 .16-.37z" />
        <path d="M4 0L0 2v4.17L4 8l4-1.83V2z" fill="#cdecff" fillRule="evenodd" />
        <path fill="#7fceff" fillRule="evenodd" d="M4 3.83V8l4-1.83V2L4 3.83z" />
        <path d="M1.48 4.86a.42.42 0 0 1 .24.34c0 .13-.1.18-.24.12a.42.42 0 0 1-.23-.34c0-.13.1-.19.23-.13zm.94.43l.93.44a.42.42 0 0 1 .24.34c0 .13-.1.18-.24.12l-.93-.43a.42.42 0 0 1-.24-.34c0-.13.1-.19.24-.13z" fill="#56aa1c" fillRule="evenodd" />
        <path d="M1.48 3.45a.42.42 0 0 1 .24.35c0 .12-.1.18-.24.12a.42.42 0 0 1-.23-.34c0-.13.1-.19.23-.13zm.94.44l.93.43a.42.42 0 0 1 .24.35c0 .13-.1.18-.24.12l-.93-.43A.42.42 0 0 1 2.18 4c0-.13.1-.18.24-.12z" fill="#f18b12" fillRule="evenodd" />
        <path d="M4 1.12l3 1.5v2.9L4 6.9 1 5.52v-2.9l3-1.5M4 0L0 2v4.17L4 8l4-1.83V2L4 0z" fill="#008be1" />
        <path d="M12 8l-4 2v4.17L12 16l4-1.83V10z" fill="#cdecff" fillRule="evenodd" />
        <path fill="#7fceff" fillRule="evenodd" d="M12 11.83V16l4-1.83V10l-4 1.83z" />
        <path d="M9.48 12.86a.42.42 0 0 1 .24.34c0 .13-.1.18-.24.12a.42.42 0 0 1-.23-.34c0-.13.1-.19.23-.13zm.94.43l.93.44a.42.42 0 0 1 .24.34c0 .13-.1.18-.24.12l-.93-.43a.42.42 0 0 1-.24-.34c0-.13.1-.19.24-.13z" fill="#56aa1c" fillRule="evenodd" />
        <path d="M9.48 11.45a.42.42 0 0 1 .24.35c0 .12-.1.18-.24.12a.42.42 0 0 1-.23-.34c0-.13.1-.19.23-.13zm.94.44l.93.43a.42.42 0 0 1 .24.35c0 .13-.1.18-.24.12l-.93-.43a.42.42 0 0 1-.24-.35c0-.13.1-.18.24-.12z" fill="#f18b12" fillRule="evenodd" />
        <path d="M12 9.12l3 1.5v2.9l-3 1.38-3-1.38v-2.9l3-1.5M12 8l-4 2v4.17L12 16l4-1.83V10l-4-2z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function SmallStructClassIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="struct_class_16">
        <title>Struct</title>
        <path d="M8 2L0 6v4.33L8 14l8-3.67V6z" fill="#cdecff" fillRule="evenodd" />
        <path fill="#7fceff" fillRule="evenodd" d="M8 9.67V14l8-3.67V6L8 9.67z" />
        <path d="M2 7.9a.9.9 0 0 1 .5.74c0 .28-.22.4-.5.27a.9.9 0 0 1-.5-.73c0-.28.22-.4.5-.27zm2 .94l2 .93a.9.9 0 0 1 .5.74c0 .27-.22.4-.5.26l-2-.93a.9.9 0 0 1-.5-.73c0-.28.22-.4.5-.27z" fill="#f18b12" fillRule="evenodd" />
        <path d="M8 3.12l7 3.5v3.07L8 12.9 1 9.7V6.61l7-3.5M8 2L0 6v4.33L8 14l8-3.67V6L8 2z" fill="#008be1" />
      </svg>
    </Icon>
  );
}

export function SmallUnitSystemIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="unit_system_16">
        <defs>
          <linearGradient id="unitSysGradientA" x1="8" y1="5" x2="8" y2="11" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#e3b568" />
            <stop offset="1" stopColor="#d19d54" />
          </linearGradient>
        </defs>
        <title>UnitSystem</title>
        <circle cx="8" cy="8" r="7.5" fill="#fff" />
        <path d="M8 0a8 8 0 1 0 8 8 8 8 0 0 0-8-8zm3 8c0 4-1.58 7-3 7s-3-3-3-7 1.58-7 3-7 3 3 3 7zM1 8a7 7 0 0 1 4.76-6.63A11.61 11.61 0 0 0 4 8a11.61 11.61 0 0 0 1.76 6.63A7 7 0 0 1 1 8zm9.24 6.63A11.61 11.61 0 0 0 12 8a11.61 11.61 0 0 0-1.76-6.63 7 7 0 0 1 0 13.25z" fill="#008be1" />
        <path d="M.14 6.52A6.3 6.3 0 0 0 0 8a6.3 6.3 0 0 0 .13 1.48 21.16 21.16 0 0 0 15.73 0A6.3 6.3 0 0 0 16 8a6.3 6.3 0 0 0-.14-1.48 21.16 21.16 0 0 0-15.72 0z" fillRule="evenodd" fill="url(#unitSysGradientA)" />
        <path d="M15.86 6.52A19.34 19.34 0 0 0 8 5 19.34 19.34 0 0 0 .14 6.52 6.29 6.29 0 0 0 0 8a6.3 6.3 0 0 0 .13 1.48A19.34 19.34 0 0 0 8 11a19.34 19.34 0 0 0 7.86-1.52A6.3 6.3 0 0 0 16 8a6.3 6.3 0 0 0-.14-1.48zm-.9 2.26A18.84 18.84 0 0 1 8 10a18.84 18.84 0 0 1-6.97-1.22 8.01 8.01 0 0 1 0-1.56A14.67 14.67 0 0 1 3 6.6V8h1V6.37c.64-.12 1.31-.22 2-.28V8h1V6.04c.33-.01.66-.04 1-.04.34 0 .67.03 1 .04V8h1V6.1c.69.05 1.36.15 2 .27V8h1V6.6a14.67 14.67 0 0 1 1.97.62A7.76 7.76 0 0 1 15 8a7.65 7.65 0 0 1-.03.78z" fill="#a07033" />
      </svg>
    </Icon>
  );
}

export function SmallUnitIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Icon>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="unit_16">
        <defs>
          <linearGradient id="unitGradientA" x1="8" y1="13" x2="8" y2="3" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#d19d54" />
            <stop offset="1" stopColor="#e3b568" />
          </linearGradient>
        </defs>
        <title>Unit</title>
        <path fill="url(#unitGradientA)" d="M0 3h16v10H0z" />
        <path d="M16 2v3h-1V3h-2v4h-1V3h-2v2H9V3H7v3H6V3H4v2H3V3H1v4H0V2zm-1 9h-1v2h-1v-2h-1v2h-1V9h-1v4H9v-2H8v2H7v-2H6v2H5v-2H4v2H3v-2H2v2H1v-3H0v4h16v-1h-1z" fill="#a07033" />
      </svg>
    </Icon>
  );
}
