/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import React from "react";
import { ElementDetailsCard } from "frontend/components/stages/classbrowser/cards/ElementDetailsCard";
import { ElementLink } from "frontend/components/controls/ElementLink";
import { ElementLinkData } from "frontend/api/Interfaces";

interface Props {
  title: string;
  id: string;
  dataProvider(id: string): Promise<ElementLinkData[]>;
}

export const UsesCard: React.FC<Props> = (props: Props) => {
  const [linkData, setLinkData] = React.useState<ElementLinkData[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await props.dataProvider(props.id);
      setLinkData(data);
    };
    fetchData();
  }, [props.id]);

  const createElementLinks = () => {
    return linkData.map((link: ElementLinkData) => {
      const key = `${link.id}${(link.propertyName !== undefined) ? `.${link.propertyName}` : ""}`;
      return (
        <span key={key} className="link">
          <ElementLink {...link}/>
        </span>
      );
    });
  };

  const links = createElementLinks();

  return (
    links.length > 0 ? (
      <ElementDetailsCard title={props.title}>
        <div className="links">
          {links}
        </div>
      </ElementDetailsCard>
    ) : null
  );

}
