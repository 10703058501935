/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import "./ElementTip.scss";
import { ECClassModifier } from "@itwin/ecschema-metadata";
import { ElementLinkProps } from "frontend/components/controls/ElementLink";
import { ElementDescription } from "frontend/components/ElementDescription";
import { ElementTypeIcon } from "./ElementIcons";
import * as React from "react";

/**
 * Component that renders Element summary information typically displayed within
 * a tooltip.
 */
export class ElementTip extends React.Component<ElementLinkProps> {
  public override render() {
    const schemaName = (this.props.schemaAlias) ? `${this.props.schemaAlias}.` : "";
    const name = `${this.props.name}${(this.props.propertyName) ? `.${this.props.propertyName}` : ""}`;

    const nameStyle: React.CSSProperties | undefined = (this.props.modifier === ECClassModifier.Abstract) ? { fontStyle: "italic" } : undefined;

    return (
      <div className="wimse-tooltip">
        <div className="icon">
          <ElementTypeIcon elementType={this.props.elementType} />
        </div>
        <span className="iui-text-title">
          <span className="schema">{schemaName}</span>
          <span className="name" style={nameStyle}>{name}&nbsp;</span>
        </span>
        <ElementDescription description={this.props.description} />
      </div>
    );
  }
}
