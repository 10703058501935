/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import "./Tooltip.scss";
import * as ReactDOM from "react-dom";
import * as React from "react";

interface PortalProps {
  children?: React.ReactNode;
}

/**
 * A react portal component used to display the Tooltip component.
 */
class TooltipPortal extends React.PureComponent<PortalProps> {
  private _el: HTMLDivElement;
  constructor(props: any) {
    super(props);
    this._el = document.createElement("div");
  }

  public override componentDidMount() {
    document.body.appendChild(this._el);
  }

  public override componentWillUnmount() {
    document.body.removeChild(this._el);
  }

  public override render() {
    return ReactDOM.createPortal(this.props.children, this._el);
  }
}

/**
 * Style used for positioning the tooltip.
 */
interface Style {
  left?: number;
  top?: number;
  bottom?: number;
}

interface Props {
  children?: React.ReactNode;
  content: JSX.Element;
}

/**
 * The state of the Tooltip. 'visible' or 'style' change
 * will force the component to re-render.
 */
interface State {
  visible: boolean;
  style: Style;
}

/**
 * A 'tooltip' component that relies on props.Content for the content and styling of the tooltip. This mainly
 * acts as a container that handles mouse events and location of the tooltip relative to a 'target'
 * element. The 'target' element is assumed to be the child element in the DOM.
 */
export class Tooltip extends React.PureComponent<Props, State> {
  private _space: number;
  private _tipContainer: any;
  private node: HTMLSpanElement | null | undefined;

  /**
   * Initializes a new Tooltip component.
   * @param props The props for the component.
   */
  constructor(props: any) {
    super(props);

    this.state = {
      visible: false,
      style: {left: undefined, top: undefined, bottom: undefined},
    };

    this._space = 5;
    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
    this._tipContainer = React.createRef();
  }

  private showTooltip() {
    const me = this.node as HTMLElement;
    if (me === null || me === undefined) {
      return;
    }
    const style: Style = {};
    const dimensions = me.getBoundingClientRect();
    // use width of header cell because dimensions.width gives content width when we need display width
    const cellWidth = (me.parentElement && me.parentElement.parentElement) ? me.parentElement.parentElement.clientWidth : dimensions.width;

    style.left = (dimensions.left + (cellWidth / 2));
    style.left = Math.max(this._space, style.left);
    style.left = Math.min(style.left, document.body.clientWidth - cellWidth);

    if (dimensions.top < window.innerHeight / 2) {
      // on the top half of the page, tooltip will be positioned below the target element.
      style.top = dimensions.top + dimensions.height + this._space;
    } else {
      // on the bottom half of the page, tooltip will be positioned above the target element.
      style.bottom = (window.innerHeight - dimensions.top) + this._space;
    }

    this.setState({
      visible: true,
      style,
    });
  }

  private hideTooltip() {
    this.setState({visible: false});
  }

  /**
   * Renders the component to the DOM.
   */
  public override render() {
    return (
      <span ref={(node) => this.node = node} onMouseEnter={this.showTooltip} onMouseLeave={this.hideTooltip}>
        {this.props.children}
        {this.state.visible && (
          <TooltipPortal>
            <div
              style={this.state.style}
              className="tooltip-content"
              ref={this._tipContainer}
            >
              {this.props.content}
            </div>
          </TooltipPortal>
        )}
      </span>
    );
  }
}
