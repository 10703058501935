/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { SchemaTagProps } from "frontend/components/controls/SchemaTag";
import "./ElementLink.css";
import { connect } from "react-redux";
import { Selectors } from "frontend/state/selectors";
import { Actions, ImseLocation, updateUrlQueryParams } from "frontend/state/reducers/location";
import { ECClassModifier, SchemaItemType } from "@itwin/ecschema-metadata";
import { RootState } from "frontend/state/rootReducer";
import { Tooltip } from "frontend/components/controls/Tooltip";
import { ElementTip } from "frontend/components/controls/ElementTip";
import { AnyElementType } from "frontend/api/Interfaces";

interface Props extends SchemaTagProps {
  name: string;
  elementType: AnyElementType;
  id: string;
  description: string;
  modifier?: ECClassModifier;
  label?: string;
  currentElement?: string | undefined; // Connected to redux
  propertyName?: string;
  navigate?(id: string, elementType: AnyElementType, propertyName?: string): any; // Connected to redux
}

const mapState = (state: RootState) => ({
  currentElement: Selectors.elementIdSelector(state),
});

const mapDispatch = {
  navigate: (id: string, elementType: AnyElementType | undefined, propertyName?: string) => Actions.navigateTo("browse", elementType, id, undefined, propertyName),
};

export class ElementLinkNoTagComponent extends React.Component<Props> {
  public handleClick = (e: React.MouseEvent<any>) => {
    this.props.navigate!(this.props.id, this.props.elementType, this.props.propertyName);
    e.preventDefault();
  };

  public override render() {
    let label: string;
    if (this.props.label !== undefined)
      label = this.props.label;
    else if (this.props.propertyName !== undefined)
      label = `${this.props.name}.${this.props.propertyName}`;
    else
      label = this.props.name;

    const location: ImseLocation = { stage: "browse", elementType: this.props.elementType, id: this.props.id, propertyName: this.props.propertyName };
    const newSearchParams = updateUrlQueryParams(location, window.location.search).toString();
    let name = (<span>{label}</span>);
    if (this.props.currentElement !== this.props.id) {
      name = (
        <Tooltip content={<ElementTip {...this.props} />} >
          <a href={`/?${newSearchParams}`} onClick={this.handleClick}>
            {label}
          </a>
        </Tooltip>
      );
    }

    // FIXME: Need to store modifier for mixins in imsejs-core
    const isAbstract = this.props.modifier === ECClassModifier.Abstract || this.props.elementType === SchemaItemType.Mixin;
    const abstractClassName = (isAbstract ? " abstract" : "");

    return (
      <span className={`element-link${abstractClassName}`}>
        {name}
        {this.props.children}
      </span>);
  }
}

export type ElementLinkNoTagProps = Props;

export const ElementLinkNoTag = connect(mapState, mapDispatch)(ElementLinkNoTagComponent);
