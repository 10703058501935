/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ElementInfoPage } from "frontend/components/stages/classbrowser/ElementInfoPage";
import { EntityClassDetails } from "frontend/components/stages/classbrowser/details/EntityClassDetails";
import { SchemaDetails } from "frontend/components/stages/classbrowser/details/SchemaDetails";
import { MixinDetails } from "frontend/components/stages/classbrowser/details/MixinDetails";
import { StructClassDetails } from "frontend/components/stages/classbrowser/details/StructClassDetails";
import { RelationshipClassDetails } from "frontend/components/stages/classbrowser/details/RelationshipClassDetails";
import { KindOfQuantityDetails } from "frontend/components/stages/classbrowser/details/KindOfQuantityDetails";
import { EnumerationDetails } from "frontend/components/stages/classbrowser/details/EnumerationDetails";
import { CustomAttributeDetails } from "frontend/components/stages/classbrowser/details/CustomAttributeDetails";
import { PropertyCategoryDetails } from "frontend/components/stages/classbrowser/details/PropertyCategoryDetails";
import { UnitSystemDetails } from "frontend/components/stages/classbrowser/details/UnitSystemDetails";
import { PhenomenonDetails } from "frontend/components/stages/classbrowser/details/PhenomenonDetails";
import { ConstantDetails } from "frontend/components/stages/classbrowser/details/ConstantDetails";
import { UnitDetails } from "frontend/components/stages/classbrowser/details/UnitDetails";
import { FormatDetails } from "frontend/components/stages/classbrowser/details/FormatDetails";
import { RootState } from "frontend/state/rootReducer";
import { connect } from "react-redux";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { AnyElementType, ElementAttributes, OtherNodeType, SchemaType } from "frontend/api/Interfaces";
import { SchemaItemType } from "@itwin/ecschema-metadata";
import { DocumentationPage } from "./classbrowser/DocumentationPage";
import _default from "@itwin/itwinui-react/cjs/core/Alert";
interface Props {
  elementType: AnyElementType | undefined; // connected to redux
  id: string | undefined; // connected to redux
  propertyName?: string; // connected to redux
}

const mapState = (state: RootState) => ({
  elementType: state.location.current.elementType,
  id: state.location.current.id,
  propertyName: state.location.current.propertyName,
});

export const ClassBrowserStageComponent: React.FC<Props> = (props: Props) => {
  const _defaultState: ElementAttributes = {
    id: "",
    description: "",
    displayLabel: "",
    name: "",
    schemaName: "",
    modifier: null,
  };

  const [data, setData] = React.useState<ElementAttributes>(_defaultState);


  React.useEffect(() => {
    const fetchData = async () => {
      let data: ElementAttributes | undefined;
      if (props.elementType === SchemaType.schema) {
        data = props.id ? ImseFrontend.instance.getSchemaAttributes(props.id) : _defaultState;
      } else {
        data = props.id ? ImseFrontend.instance.getSchemaItemAttributes(props.id) : _defaultState;
      }
      if (data === undefined) {
        data = _defaultState;
      }
        setData(data);
    };
    fetchData();
  }, [props.id, props.elementType]);

  const getDetailsComponent = (elementType?: AnyElementType): React.ComponentClass<ElementAttributes> => {
    if (elementType === undefined) {
      throw new Error("Element Type is undefined.");
    } else {
      if (elementType as SchemaType === SchemaType.schema) { return SchemaDetails; } else {
        if (elementType === SchemaType.none) { return SchemaDetails; } else {
          switch (elementType as SchemaItemType) {
            case SchemaItemType.EntityClass: return EntityClassDetails;
            case SchemaItemType.Mixin: return MixinDetails;
            case SchemaItemType.StructClass: return StructClassDetails;
            case SchemaItemType.CustomAttributeClass: return CustomAttributeDetails;
            case SchemaItemType.RelationshipClass: return RelationshipClassDetails;
            case SchemaItemType.Enumeration: return EnumerationDetails;
            case SchemaItemType.KindOfQuantity: return KindOfQuantityDetails;
            case SchemaItemType.PropertyCategory: return PropertyCategoryDetails;
            case SchemaItemType.UnitSystem: return UnitSystemDetails;
            case SchemaItemType.Phenomenon: return PhenomenonDetails;
            case SchemaItemType.InvertedUnit:
            case SchemaItemType.Unit: return UnitDetails;
            case SchemaItemType.Constant: return ConstantDetails;
            case SchemaItemType.Format: return FormatDetails;
            default:
              throw new Error(`Unknown element type: ${elementType}`);
          }
        }
      }
    }

  }

  const renderElementInfo = (elementType: AnyElementType, propertyName?: string) => {
    const Details = getDetailsComponent(elementType); // tslint:disable-line:variable-name
    const childProps = { ...data, elementType, propertyName };
    return (
      <ElementInfoPage {...childProps}>
        <Details {...data} />
      </ElementInfoPage>
    );
  }

  const renderDocumentationPage = (id: string) => {
    return (
      <DocumentationPage id={id}>
      </DocumentationPage>
    );
  }

  const eType = undefined !== props.elementType ? props.elementType : SchemaType.none;

  return (
    undefined === props.id || /UnitDef\.[A-Z]/.test(props.id) ? <div></div> :
    <div className="class-browser">
      {eType === OtherNodeType.root ? renderDocumentationPage(props.id) : renderElementInfo(eType, props.propertyName)}
    </div>
  );

}

export const ClassBrowserStage = connect(mapState)(ClassBrowserStageComponent);
