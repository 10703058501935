/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { ElementAttributes, InvertedUnitAttributes, UnitAttributes } from "frontend/api/Interfaces";
import { ElementLink } from "frontend/components/controls/ElementLink";
import { SchemaItemType } from "@itwin/ecschema-metadata";
import { UsesCard } from "../cards/UsesCard";

type Props = ElementAttributes;
type State = Partial<UnitAttributes | InvertedUnitAttributes>;

export class UnitDetails extends React.Component<Props, State> {
  private static _dataProviders = {
    KindOfQuantities: async (id: string) => ImseFrontend.instance.getKoqThatUseUnit(id),
    Formats: async (id: string) => ImseFrontend.instance.getFormatsThatUseUnit(id),
  };
  private static _defaultState = {
    definition: "",
  };

  public override readonly state: State = UnitDetails._defaultState;
  public initialized?: Promise<any>;

  private async fetchData() {
    const data = ImseFrontend.instance.getSchemaItemAttributes(this.props.id) as UnitAttributes | InvertedUnitAttributes;
    this.setState({ ...UnitDetails._defaultState, ...data });
  }

  public override componentDidMount() {
    this.initialized = this.fetchData();
  }

  public override componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id)
      this.initialized = this.fetchData();
  }

  private renderUnitDetails(attributes: Partial<UnitAttributes>) {
    const unitSystem = attributes.unitSystem ? (<ElementLink {...attributes.unitSystem} />) : ("");
    const phenomenon = attributes.phenomenon ? (<ElementLink {...attributes.phenomenon} />) : ("");

    return (
      <div className="details-page">
        <div className="element-attributes">
          <div className="item">
            <span className="label">Phenomenon: </span> <span className="value">{phenomenon}</span>
          </div>
          <div className="item">
            <span className="label">Unit System: </span> <span className="value">{unitSystem}</span>
          </div>
        </div>
        <div className="card-container">
          <UsesCard title="Used by kind of quantities:" dataProvider={UnitDetails._dataProviders.KindOfQuantities} id={this.props.id} />
          <UsesCard title="Used by formats:" dataProvider={UnitDetails._dataProviders.Formats} id={this.props.id} />
        </div>
      </div>
    );
  }

  private renderInvertedUnitDetails(attributes: Partial<InvertedUnitAttributes>) {
    const invertsUnit = attributes.invertsUnit ? (<ElementLink {...attributes.invertsUnit} />) : ("");
    const unitSystem = attributes.unitSystem ? (<ElementLink {...attributes.unitSystem} />) : ("");

    return (
      <div className="details-page">
        <div className="element-attributes">
          <div className="item">
            <span className="label">Inverts Unit: </span> <span className="value">{invertsUnit}</span>
          </div>
          <div className="item">
            <span className="label">Unit System: </span> <span className="value">{unitSystem}</span>
          </div>
        </div>
        <div className="card-container">
          <UsesCard title="Used by kind of quantities:" dataProvider={UnitDetails._dataProviders.KindOfQuantities} id={this.props.id} />
          <UsesCard title="Used by formats:" dataProvider={UnitDetails._dataProviders.Formats} id={this.props.id} />

        </div>
      </div>
    );
  }

  public override render() {
    if (this.state.elementType === SchemaItemType.Unit)
      return this.renderUnitDetails(this.state as UnitAttributes);

    return this.renderInvertedUnitDetails(this.state as InvertedUnitAttributes);
  }
}
