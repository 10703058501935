import React from "react";

export function ConstantIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="constant_icon_16">
      <title>constant</title>
      <path d="M2.0202,11.9703c1.6414-1.8489,2.6515-5.1336,2.601-8.9547H3.3586C1.5909,2.9663,1.0859,4.476.6313,5.2402H0C1.3131,2.7257.4545,1,5.5556,1L15,1.0004v2l-3.5858.0152c-.8334,7.5437-.7071,8.8315.505,9.5217.5556.3205,2.2727.4684,2.5-1.7256H15c0,5.0784-6.1869,5.6207-6.3131,1.2573-.0253-.6657.202-3.6052.707-9.078H6.0101s-.6313,7.9686-.9091,9.0533c-.202.7889-.3535,2.6378-1.9949,2.8597C1.9697,15.1012.3535,13.8193,2.0202,11.9703Z" fill="#008be1" />
    </svg>
  );
}

export function CustomAttributeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="custom_attribute_icon_24">
      <title>custom_attribute</title>
      <g id="custom_attribute_1">
        <path fill="#cdecff" d="M23.7 6.3v5.2c-.2-.2-.6-.3-1-.3h-3.3s-1 0-1.8.9L11.7 18s-1.2 1.4 0 2.5l2.4 2.4-2 .9L.3 18.4v-12L12 .4l11.7 5.9z" />
        <path fill="#008be1" d="M12 0L0 6.2v12.4L11.9 24h.1l2.3-1-.4-.4-1.9.8L.6 18.2V6.5L12 .7l11.4 5.8v4.8c.3.1.4.3.6.5V6.1L12 0z" />
        <path fill="#7fceff" d="M12 20.8l1.8 1.8-1.8.8zM23.4 6.4v4.8c-.2-.1-.5-.2-.8-.2h-3.3s-1 0-1.8.9L12 17.5v-6.4l11.4-4.7z" />
        <ellipse transform="rotate(-31.94 2.78 11.76)" stroke="#fff" strokeWidth=".432" strokeMiterlimit="10" fill="#f18b12" cx="2.8" cy="11.8" rx=".8" ry="1.1" />
        <path fill="#f18b12" stroke="#fff" strokeWidth=".432" strokeMiterlimit="10" d="M8.4 15.5l-3.2-1.6c-.4-.2-.6-.7-.6-1.2s.4-.8.8-.6l3.3 1.7c.5.2.7.7.6 1.2-.1.5-.5.7-.9.5z" />
        <ellipse transform="rotate(-31.94 2.78 15.72)" stroke="#fff" strokeWidth=".432" strokeMiterlimit="10" fill="#56aa1c" cx="2.8" cy="15.7" rx=".8" ry="1.1" />
        <path fill="#56aa1c" stroke="#fff" strokeWidth=".432" strokeMiterlimit="10" d="M8.4 19.5l-3.2-1.6c-.4-.2-.6-.7-.6-1.2s.4-.8.8-.6l3.3 1.7c.4.2.7.8.6 1.3-.1.4-.5.6-.9.4z" />
        <linearGradient id="custom_attribute_2" gradientUnits="userSpaceOnUse" x1="14.87" y1="32.18" x2="28.37" y2="32.18" gradientTransform="rotate(-45 2.04 28.08)">
          <stop offset="0" stopColor="#2f3439" />
          <stop offset=".11" stopColor="#34383d" />
          <stop offset=".46" stopColor="#3c4146" />
          <stop offset="1" stopColor="#3f4348" />
        </linearGradient>
        <path fill="url(#custom_attribute_2);stroke:#2a2f34;stroke-width:.216;stroke-miterlimit:10;fill-rule:evenodd;clip-rule:evenodd" d="M22.5 12h-3s-.9 0-1.6.8l-5.4 5.3s-1.1 1.2 0 2.3l3.1 3.1s1.1 1.2 2.3 0c1.2-1.2 5.4-5.3 5.4-5.3s.8-.8.8-1.6v-3c-.1-.1-.1-1.6-1.6-1.6zm-.8 3.1a.9.9 0 0 1-.9-.9c0-.5.4-.9.9-.9s.9.4.9.9-.4.9-.9.9z" />
        <path stroke="#2a2f34" strokeWidth=".216" strokeMiterlimit="10" fill="#fcfdfd" fillRule="evenodd" clipRule="evenodd" d="M22.1 17.7l-4.6 4.6c-.3.3-.9.3-1.2 0l-2.6-2.6c-.3-.3-.3-.9 0-1.2l4.6-4.6c.3-.3.9-.3 1.2 0l2.6 2.6c.3.3.3.8 0 1.2z" />
        <path fill="#0082d3;fill-rule:evenodd;clip-rule:evenodd" d="M14.5 17.7l3.7 3.7-.8.8c-.3.3-.8.3-1.1 0l-2.6-2.6c-.3-.3-.3-.8 0-1.1l.8-.8z" />
        <path transform="rotate(-45 19.77 18.78)" fill="#9ba5ae" d="M17.5 18.7H22v.2h-4.5z" />
        <path transform="rotate(-45 18.43 17.5)" fill="#9ba5ae" d="M16.2 17.4h4.5v.2h-4.5z" />
      </g>
    </svg>
  );
}

export function SchemaIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="schema_icon_24">
      <title>schema</title>
      <g id="schema_24_1">
        <g id="schema_24_2">
          <path fill="#cdecff" id="polygon12" d="M6.1 10.3L.2 12.9v6.5l5.9 2.7 5.9-2.7v-6.1z" />
          <path fill="#008be1" d="M6.1 22.3l-6-2.8v-6.8l6-2.5 6 2.7v6.6l-6 2.8zm-5.8-3L6.1 22l5.8-2.6v-6.1l-5.8-2.7L.3 13v6.3z" id="path14" />
        </g>
        <path fill="#7fceff" id="polygon16" d="M6.1 22v-6.3l5.8-2.5v6.1z" />
        <g id="schema_24_3">
          <ellipse transform="rotate(-31.94 1.46 16.08)" fill="#f18b12" cx="1.5" cy="16.1" rx=".4" ry=".6" id="ellipse20" />
          <path fill="#fff" d="M1.6 16.7c-.2 0-.4-.1-.6-.4-.1-.2-.2-.4-.1-.6 0-.1.1-.2.2-.3.2-.2.6 0 .8.3.1.3.2.5.1.7 0 .1-.1.2-.2.3h-.2zm-.3-1h-.1c-.1 0-.1.1-.1.1 0 .1 0 .3.1.4.1.2.4.3.5.2.1 0 .1-.1.1-.1 0-.1 0-.3-.1-.4-.1-.1-.2-.2-.4-.2z" id="path22" />
        </g>
        <g id="schema_24_4">
          <path fill="#f18b12" d="M4.3 18l-1.6-.8c-.2-.1-.3-.4-.3-.6 0-.2.2-.4.4-.3l1.7.8c.2.1.3.4.3.6-.1.3-.3.4-.5.3z" id="path26" />
          <g id="schema_24_5">
            <path fill="#fff" d="M4.5 18.1c-.1 0-.1 0-.2-.1l-1.6-.8c-.2-.1-.4-.4-.4-.7 0-.2.1-.3.2-.4.1-.1.2-.1.3 0l1.7.9c.2.1.4.5.3.8 0 .1-.1.3-.2.3h-.1zm-.1-.2h.2c.1 0 .1-.1.1-.2 0-.2-.1-.4-.2-.5l-1.7-.8h-.1c-.1 0-.1.1-.1.2 0 .2.1.4.2.5l1.6.8z" id="path30" />
          </g>
        </g>
        <g id="schema_24_5">
          <ellipse transform="rotate(-31.94 1.46 18.07)" fill="#56aa1c" cx="1.5" cy="18.1" rx=".4" ry=".6" id="ellipse34" />
          <path fill="#fff" d="M1.6 18.7c-.2 0-.4-.1-.6-.4-.1-.2-.2-.4-.1-.6 0-.1.1-.2.2-.3.2-.2.6 0 .8.3.1.3.2.5.1.7 0 .1-.1.2-.2.3h-.2zm-.3-1.1h-.1c-.1 0-.1.1-.1.1 0 .1 0 .3.1.4.1.2.4.3.5.2.1 0 .1-.1.1-.1 0-.1 0-.3-.1-.4-.1-.1-.2-.2-.4-.2z" id="path36" />
        </g>
        <g id="schema_24_6">
          <path fill="#56aa1c" d="M4.3 20l-1.6-.8c-.2-.1-.3-.4-.3-.6 0-.2.2-.4.4-.3l1.7.8c.2.1.3.4.3.6-.1.2-.3.4-.5.3z" id="path40" />
          <g id="schema_24_7">
            <path fill="#fff" d="M4.5 20.1c-.1 0-.1 0-.2-.1l-1.6-.8c-.2-.1-.4-.4-.4-.7 0-.2.1-.3.2-.4.1-.1.2-.1.3 0l1.7.9c.2.1.4.5.3.8 0 .1-.1.3-.2.3h-.1zm-.1-.2h.2c.1 0 .1-.1.1-.2 0-.2-.1-.4-.2-.5l-1.7-.8h-.1c-.1 0-.1.1-.1.2 0 .2.1.4.2.5l1.6.8z" id="path44" />
          </g>
        </g>
        <g id="schema_24_8">
          <path fill="#cdecff" id="polygon48" d="M17.9 10.3L12 13.4v6l5.9 2.7 5.9-2.7v-6.5z" />
          <path fill="#008be1" d="M17.9 22.3l-6-2.8v-6.6l6-2.7 6 2.5v6.8l-6 2.8zm-5.8-3l5.8 2.6 5.8-2.6v-6.4l-5.8-2.4-5.8 2.7v6.1z" id="path50" />
        </g>
        <path fill="#7fceff" id="polygon52" d="M17.9 22v-6.3l5.8-2.8v6.4z" />
        <g id="schema_24_9">
          <ellipse transform="rotate(-31.94 13.26 16.08)" fill="#f18b12" cx="13.3" cy="16.1" rx=".4" ry=".6" id="ellipse56" />
          <path fill="#fff" d="M13.4 16.7c-.2 0-.4-.1-.6-.4-.1-.2-.2-.4-.1-.6 0-.1.1-.2.2-.3.2-.2.6 0 .8.3.1.2.2.4.1.6 0 .1-.1.2-.2.3 0 .1-.1.1-.2.1zm-.3-1H13c-.1 0-.1.1-.1.1 0 .1 0 .3.1.4.1.2.4.3.5.2.1 0 .1-.1.1-.1 0-.1 0-.3-.1-.4-.1-.1-.2-.2-.4-.2z" id="path58" />
        </g>
        <g id="schema_24_10">
          <path fill="#f18b12" d="M16.1 18l-1.6-.8c-.2-.1-.3-.4-.3-.6 0-.2.2-.4.4-.3l1.7.8c.2.1.3.4.3.6-.1.3-.3.4-.5.3z" id="path62" />
          <g id="schema_24_11">
            <path fill="#fff" d="M16.3 18.1c-.1 0-.1 0-.2-.1l-1.6-.8c-.2-.1-.4-.4-.4-.7 0-.2.1-.3.2-.4.1-.1.2-.1.3 0l1.7.8c.2.1.4.5.3.8 0 .1-.1.3-.2.3 0 .1-.1.1-.1.1zm-.1-.2h.2c.1 0 .1-.1.1-.2 0-.2-.1-.4-.2-.5l-1.7-.8h-.1c-.1 0-.1.1-.1.2 0 .2.1.4.2.5l1.6.8z" id="path66" />
          </g>
        </g>
        <g id="schema_24_12">
          <ellipse transform="rotate(-31.94 13.26 18.07)" fill="#56aa1c" cx="13.3" cy="18.1" rx=".4" ry=".6" id="ellipse70" />
          <path fill="#fff" d="M13.4 18.7c-.2 0-.4-.1-.6-.4-.1-.2-.2-.4-.1-.6 0-.1.1-.2.2-.3.2-.2.6 0 .8.3.1.2.2.4.1.6 0 .1-.1.2-.2.3 0 .1-.1.1-.2.1zm-.3-1.1H13c-.1 0-.1.1-.1.1 0 .1 0 .3.1.4.1.2.4.3.5.2.1 0 .1-.1.1-.1 0-.1 0-.3-.1-.4-.1-.1-.2-.2-.4-.2z" id="path72" />
        </g>
        <g id="schema_24_13">
          <path fill="#56aa1c" d="M16.1 20l-1.6-.8c-.2-.1-.3-.4-.3-.6 0-.2.2-.4.4-.3l1.7.8c.2.1.3.4.3.6-.1.2-.3.4-.5.3z" id="path76" />
          <g id="schema_24_14">
            <path fill="#fff" d="M16.3 20.1c-.1 0-.1 0-.2-.1l-1.6-.8c-.2-.1-.4-.4-.4-.7 0-.2.1-.3.2-.4.1-.1.2-.1.3 0l1.7.8c.2.1.4.5.3.8 0 .1-.1.3-.2.3 0 .1-.1.1-.1.1zm-.1-.2h.2c.1 0 .1-.1.1-.2 0-.2-.1-.4-.2-.5l-1.7-.8h-.1c-.1 0-.1.1-.1.2 0 .2.1.4.2.5l1.6.8z" id="path80" />
          </g>
        </g>
        <g id="schema_24_15">
          <g id="schema_24_16">
            <path fill="#cdecff" id="polygon86" d="M12 1.3l-5.9 3v6.1l5.9 2.7 5.9-2.7V4.3z" />
            <path fill="#008be1" d="M12 13.2l-6-2.8V4.2l6-3.1 6 3.1v6.3l-6 2.7zm-5.8-2.9l5.8 2.6 5.8-2.6V4.4L12 1.5 6.2 4.4v5.9z" id="path88" />
          </g>
          <path fill="#7fceff" id="polygon90" d="M12 12.9V6.7l5.8-2.3v5.9z" />
          <g id="schema_24_17">
            <ellipse transform="rotate(-31.94 7.36 7.05)" fill="#f18b12" cx="7.4" cy="7" rx=".4" ry=".6" id="ellipse94" />
            <path fill="#fff" d="M7.5 7.7c-.2 0-.4-.1-.6-.4-.1-.2-.1-.4-.1-.6 0-.1.1-.2.2-.3.2-.2.6 0 .8.3.1.3.2.5.1.6 0 .1-.1.2-.2.3 0 .1-.1.1-.2.1zm-.3-1.1h-.1c0 .1-.1.2-.1.2 0 .1 0 .3.1.4.1.2.4.3.5.2.1 0 .1-.1.1-.1 0-.1 0-.3-.1-.4-.1-.2-.2-.3-.4-.3z" id="path96" />
          </g>
          <g id="schema_24_17">
            <path fill="#f18b12" d="M10.2 8.9l-1.6-.8c-.2-.1-.3-.4-.3-.6 0-.2.2-.4.4-.3l1.7.8c.2.1.3.4.3.6-.1.3-.3.4-.5.3z" id="path100" />
            <g id="schema_24_18">
              <path fill="#fff" d="M10.4 9.1c-.1 0-.1 0-.2-.1l-1.6-.8c-.2-.1-.4-.4-.4-.7 0-.2.1-.3.2-.4.1-.1.2-.1.3 0l1.7.9c.2.1.4.5.3.8 0 .1-.1.3-.2.3h-.1zm-.1-.3h.2c.1 0 .1-.1.1-.2 0-.2-.1-.4-.2-.5l-1.7-.8h-.1c-.1 0-.1.1-.1.2 0 .2.1.4.2.5l1.6.8z" id="path104" />
            </g>
          </g>
          <g id="schema_24_19">
            <ellipse transform="rotate(-31.94 7.36 9.04)" fill="#56aa1c" cx="7.4" cy="9" rx=".4" ry=".6" id="ellipse108" />
            <path fill="#fff" d="M7.5 9.7c-.2 0-.4-.1-.6-.4-.1-.2-.1-.4-.1-.6 0-.1.1-.2.2-.3.2-.2.6 0 .8.3.1.2.2.5.1.6 0 .1-.1.2-.2.3 0 .1-.1.1-.2.1zm-.3-1.1h-.1c0 .1-.1.1-.1.2s0 .3.1.4c.1.2.4.3.5.2.1 0 .1-.1.1-.1 0-.1 0-.3-.1-.4-.1-.2-.2-.3-.4-.3z" id="path110" />
          </g>
          <g id="schema_24_20">
            <path fill="#56aa1c" d="M10.2 10.9l-1.6-.8c-.2-.1-.3-.4-.3-.6 0-.2.2-.4.4-.3l1.7.8c.2.1.3.4.3.6-.1.3-.3.4-.5.3z" id="path114" />
            <g id="schema_24_21">
              <path fill="#fff" d="M10.4 11.1c-.1 0-.1 0-.2-.1l-1.6-.8c-.2-.1-.4-.4-.4-.7 0-.2.1-.3.2-.4.1-.1.2-.1.3 0l1.7.8c.2.1.4.5.3.8 0 .1-.1.3-.2.3 0 .1-.1.1-.1.1zm-.1-.3h.2c.1 0 .1-.1.1-.2 0-.2-.1-.4-.2-.5l-1.7-.8h-.1c-.1 0-.1.1-.1.2 0 .2.1.4.2.5l1.6.8z" id="path118" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function PropertyCategoryIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="property_category_24">
      <path d="M6 17.8a5.8 5.8 0 1 1 4.5-9.5l.1.1H22c1 0 1.9.8 1.9 1.9v3.4c0 1-.8 1.9-1.9 1.9H10.5l-.1.1c-1 1.3-2.7 2.1-4.4 2.1z" fill="#cdecff" />
      <path d="M6 6.5c1.7 0 3.3.8 4.3 2.1l.1.2h11.5c.9 0 1.6.7 1.6 1.6v3.4c0 .9-.7 1.6-1.6 1.6H10.4l-.1.2a5.48 5.48 0 0 1-9.8-3.4C.4 9 2.9 6.5 6 6.5M6 6c-3.3 0-6 2.7-6 6a6.02 6.02 0 0 0 10.7 3.8H22c1.1 0 2.1-.9 2.1-2.1v-3.4c0-1.1-.9-2.1-2.1-2.1H10.6A6 6 0 0 0 6 6z" fill="#008be1" />
      <circle cx="5.9" cy="12" r="2.8" fill="#f18b12" />
      <path d="M5.9 9.4c1.4 0 2.6 1.2 2.6 2.6s-1.2 2.6-2.6 2.6-2.6-1.1-2.6-2.6 1.1-2.6 2.6-2.6m0-.4a3 3 0 0 0-3 3c0 1.7 1.4 3 3 3s3-1.4 3-3c0-1.7-1.3-3-3-3z" fill="#fff" />
      <path d="M12 13c-.5 0-1-.4-1-1s.4-1 1-1h8.3c.5 0 1 .4 1 1s-.4 1-1 1H12z" fill="#f18b12" />
      <path d="M20.3 11.3c.4 0 .7.3.7.7s-.3.7-.7.7H12c-.4 0-.7-.3-.7-.7s.3-.7.7-.7h8.3m0-.5H12c-.7 0-1.2.6-1.2 1.2s.6 1.2 1.2 1.2h8.3c.7 0 1.2-.6 1.2-1.2s-.5-1.2-1.2-1.2z" fill="#fff" />
      <path d="M0 6h1.5V1.5H6V.1H0zM24 18h-1.5v4.6H18V24h6zM6 24v-1.5H1.5V18H0v6zM18 .1v1.4h4.5V6H24V.1z" fill="#3c434a" />
    </svg>
  );
}

export function SchemaEditIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="schema_edit_32">
      <path d="M17.91 24.3l8.68-8.6a2.27 2.27 0 0 1 1.71-.7 2.77 2.77 0 0 1 .47.04L24 12.65l-7.35 3.68v7.34z" opacity=".13" />
      <path d="M18.5 21.7a.91.91 0 0 1 .5.74c0 .28-.22.4-.5.27a.91.91 0 0 1-.5-.74c0-.28.22-.4.5-.27zM18.5 18.68a.92.92 0 0 1 .5.75c0 .28-.22.4-.5.27a.92.92 0 0 1-.5-.74c0-.28.22-.4.5-.28zM20.5 20.65l.74.35.7-.69-1.44-.68c-.27-.13-.5 0-.5.27a.92.92 0 0 0 .5.75z" />
      <path d="M17.47 24.73l.74-.73-1.21-.6v-6.8l7-3.5 3.87 1.94a2.77 2.77 0 0 1 .43-.04 2.27 2.27 0 0 1 1.7.7l.83.82.17.08v.09l1 1V16l-8-4-8 4v8z" />
      <path d="M15.35 16.33L8 12.65.65 16.33v7.34L8 27.35l7.35-3.68z" opacity=".13" />
      <path d="M8 20.08v7.27l7.35-3.68v-7.34z" opacity=".33" />
      <path d="M2.5 21.7a.91.91 0 0 1 .5.74c0 .28-.22.4-.5.27a.91.91 0 0 1-.5-.74c0-.28.22-.4.5-.27zM4.5 22.65l2 .94a.91.91 0 0 1 .5.74c0 .28-.22.4-.5.27l-2-.94a.91.91 0 0 1-.5-.74c0-.28.23-.4.5-.27zM2.5 18.69a.92.92 0 0 1 .5.74c0 .28-.22.4-.5.27a.92.92 0 0 1-.5-.74c0-.28.22-.4.5-.27zM4.5 19.63l2 .95a.92.92 0 0 1 .5.75c0 .28-.22.4-.5.27l-2-.95a.92.92 0 0 1-.5-.74c0-.29.23-.4.5-.28z" />
      <path d="M8 13.1l7 3.5v6.8l-7 3.5-7-3.5v-6.8l7-3.5M8 12l-8 4v8l8 4 8-4v-8l-8-4z" />
      <path d="M23.35 4.33L16 .65 8.65 4.33v7.34L16 15.35l7.35-3.68z" opacity=".13" />
      <path d="M16 8.08v7.27l7.35-3.68V4.33z" opacity=".33" />
      <path d="M10.5 9.7a.91.91 0 0 1 .5.74c0 .28-.22.4-.5.27a.91.91 0 0 1-.5-.74c0-.28.22-.4.5-.27zM12.5 10.65l2 .94a.91.91 0 0 1 .5.74c0 .28-.22.4-.5.27l-2-.94a.91.91 0 0 1-.5-.74c0-.28.23-.4.5-.27zM10.5 6.69a.92.92 0 0 1 .5.74c0 .28-.22.4-.5.27a.92.92 0 0 1-.5-.74c0-.28.22-.4.5-.27zM12.5 7.63l2 .95a.92.92 0 0 1 .5.75c0 .28-.22.4-.5.27l-2-.95a.92.92 0 0 1-.5-.74c0-.29.23-.4.5-.28z" />
      <path d="M16 1.1l7 3.5v6.8l-7 3.5-7-3.5V4.6l7-3.5M16 0L8 4v8l8 4 8-4V4l-8-4zM24.8 18.8l4.4 4.4-8.8 8.8H16v-4.4zm-5 11.8l1-1-2.5-2.5-1 1v1.1h1.4v1.4zm5.6-9.8c0-.2-.1-.2-.2-.2s-.1 0-.2.1l-5.7 5.7a.35.35 0 0 0-.1.2c0 .2.1.2.2.2s.1 0 .2-.1l5.7-5.7a.35.35 0 0 0 .1-.2zm6.6-1a1.28 1.28 0 0 1-.4 1l-1.8 1.8-4.3-4.5 1.8-1.7a1.45 1.45 0 0 1 2 0l2.5 2.5a2.39 2.39 0 0 1 .2.9z" />
    </svg>
  );
}

export function EntityClassIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="entity_class_24">
      <g id="entity_class_24_2">
        <path fill="#cdecff" d="M12 .4L.3 6.3v12.1L12 23.7l11.7-5.3V6.3z" />
        <path fill="#008be1" d="M12 24h-.1L0 18.5V6.2L12 0l12 6.1v12.5L12 24zM.6 18.2L12 23.4l11.4-5.2V6.5L12 .7.6 6.5v11.7z" />
        <path fill="#7fceff" d="M12 23.4V11.1l11.4-4.7v11.8z" />
        <ellipse transform="rotate(-31.94 2.78 11.76)" stroke="#fff" strokeWidth=".432" strokeMiterlimit="10" fill="#f18b12" cx="2.8" cy="11.8" rx=".8" ry="1.1" />
        <path fill="#f18b12" stroke="#fff" strokeWidth=".432" d="M8.4 15.5l-3.2-1.6c-.4-.2-.6-.7-.6-1.2s.4-.8.8-.6l3.3 1.7c.5.2.7.7.6 1.2-.1.5-.5.7-.9.5z" />
        <ellipse transform="rotate(-31.94 2.78 15.71)" stroke="#fff" strokeWidth=".432" strokeMiterlimit="10" fill="#56aa1c" cx="2.8" cy="15.7" rx=".8" ry="1.1" />
        <path fill="#56aa1c" stroke="#fff" strokeWidth=".432" d="M8.4 19.5l-3.2-1.6c-.4-.2-.6-.7-.6-1.2s.4-.8.8-.6l3.3 1.7c.4.2.7.8.6 1.3-.1.3-.5.6-.9.4z" />
      </g>
    </svg>
  );
}

export function EnumerationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="enumeration_24">
      <g id="enumeration_24_2">
        <g id="g10">
          <g id="g12">
            <path fill="#cdecff" d="M3.1 23.8a2.9 2.9 0 0 1-2.9-2.9V3.1C.2 1.5 1.5.2 3.1.2h17.8c1.6 0 2.9 1.3 2.9 2.9v17.8c0 1.6-1.3 2.9-2.9 2.9H3.1z" id="path14" />
            <path fill="#008be1" d="M20.9.4c1.5 0 2.7 1.2 2.7 2.7v17.8c0 1.5-1.2 2.7-2.7 2.7H3.1c-1.5 0-2.7-1.2-2.7-2.7V3.1C.4 1.6 1.6.4 3.1.4h17.8m0-.4H3.1C1.4 0 0 1.4 0 3.1v17.8C0 22.6 1.4 24 3.1 24h17.8c1.7 0 3.1-1.4 3.1-3.1V3.1C24 1.4 22.6 0 20.9 0z" id="path16" />
          </g>
          <g id="g18">
            <path fill="#fff" d="M9.6.5v23.1H3.1c-1.5 0-2.7-1.2-2.7-2.7V3.1C.4 1.6 1.6.4 3.1.4h6.5z" id="path20" />
          </g>
          <path fill="#f18b12" d="M7.5 7.7H4c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7h3.4c.4 0 .7.3.7.7.1.4-.2.7-.6.7z" id="path22" />
          <path fill="#f18b12" d="M7.5 13.5H4c-.4 0-.7-.3-.7-.7 0-.5.3-.8.7-.8h3.4c.4 0 .7.3.7.7.1.5-.2.8-.6.8z" id="path24" />
          <path fill="#f18b12" d="M7.5 19.3H4c-.4 0-.7-.3-.7-.8 0-.4.3-.8.7-.8h3.4c.4 0 .7.3.7.8.1.4-.2.8-.6.8z" id="path26" />
          <path fill="#f18b12" d="M7.5 7.7H4c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7h3.4c.4 0 .7.3.7.7.1.4-.2.7-.6.7z" id="path28" />
          <path fill="#f18b12" d="M7.5 19.3H4c-.4 0-.7-.3-.7-.8 0-.4.3-.8.7-.8h3.4c.4 0 .7.3.7.8.1.4-.2.8-.6.8z" id="path30" />
          <g id="g32">
            <path fill="#56aa1c" d="M12.3 13.7c-.5 0-1-.4-1-1s.4-1 1-1h8.3c.5 0 1 .4 1 1s-.4 1-1 1h-8.3z" id="path34" />
            <path fill="#fff" d="M20.5 12c.4 0 .8.3.8.8s-.3.8-.8.8h-8.3c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h8.3m0-.4h-8.3c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h8.3c.7 0 1.2-.5 1.2-1.2s-.5-1.2-1.2-1.2z" id="path36" />
          </g>
          <g id="g38">
            <path fill="#56aa1c" d="M12.3 8c-.5 0-1-.4-1-1s.4-1 1-1h8.3c.5 0 1 .4 1 1s-.4 1-1 1h-8.3z" id="path40" />
            <path fill="#fff" d="M20.5 6.2c.4 0 .8.3.8.8s-.3.7-.8.7h-8.3c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h8.3m0-.3h-8.3c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h8.3c.7 0 1.2-.5 1.2-1.2s-.5-1.2-1.2-1.2z" id="path42" />
          </g>
          <g id="g44">
            <path fill="#56aa1c" d="M12.3 19.5c-.5 0-1-.4-1-1s.4-1 1-1h8.3c.5 0 1 .4 1 1s-.4 1-1 1h-8.3z" id="path46" />
            <path fill="#fff" d="M20.5 17.8c.4 0 .8.3.8.8 0 .4-.3.8-.8.8h-8.3c-.4 0-.8-.3-.8-.8 0-.4.3-.8.8-.8h8.3m0-.5h-8.3c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h8.3c.7 0 1.2-.5 1.2-1.2s-.5-1.2-1.2-1.2z" id="path48" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function FormatIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="format_16">
      <title>Types</title>
      <path d="M11.23073,11.12841a2.61607,2.61607,0,0,1-.413,1.60937,1.44362,1.44362,0,0,1-1.219.53767,1.388,1.388,0,0,1-1.18007-.5563A2.60533,2.60533,0,0,1,8,11.12841Q8,9,9.59866,9a1.43212,1.43212,0,0,1,1.209.55215A2.54121,2.54121,0,0,1,11.23073,11.12841Zm-2.25327.00931a2.77931,2.77931,0,0,0,.14472,1.03394.49314.49314,0,0,0,.47648.34q.66129,0,.66129-1.37392T9.59866,9.76381a.49312.49312,0,0,0-.47648.34A2.77925,2.77925,0,0,0,8.97746,11.13772Zm5.5352-2.03992-4.06345,6.80905H9.47843L13.54077,9.0978ZM16,13.85762a2.59178,2.59178,0,0,1-.41525,1.60627A1.43939,1.43939,0,0,1,14.37239,16a1.40206,1.40206,0,0,1-1.1823-.55164,2.58617,2.58617,0,0,1-.42082-1.59074q0-2.12841,1.60312-2.12841a1.4356,1.4356,0,0,1,1.20011.54957A2.52652,2.52652,0,0,1,16,13.85762Zm-2.25327,0a2.79357,2.79357,0,0,0,.147,1.03911.49716.49716,0,0,0,.47871.3436q.65628,0,.65683-1.38271,0-1.36461-.65683-1.36461a.49746.49746,0,0,0-.47871.33533A2.71,2.71,0,0,0,13.74673,13.85762Z" fill="#008be1" />
      <path d="M2.8274,9.81853H1.71781v4.09325H.748V9.81853H0v-.4852l.748-.31758V8.69817A1.85851,1.85851,0,0,1,1.126,7.41707,1.48147,1.48147,0,0,1,2.28082,7a2.89216,2.89216,0,0,1,1.00274.18084L3.02877,7.966a2.0891,2.0891,0,0,0-.68219-.1235.53753.53753,0,0,0-.47854.21858,1.15677,1.15677,0,0,0-.15023.65477V9.0334H2.8274Zm2.46575,3.34341A2.22846,2.22846,0,0,0,6,13.04334v.78023a1.82966,1.82966,0,0,1-.41279.12546A2.62563,2.62563,0,0,1,5.063,14q-1.37259,0-1.3726-1.55261V9.81853H3.06986V9.3598l.66576-.37933.32876-1.03214h.59589V9.0334H5.95479v.78513H4.66027v2.61121a.767.767,0,0,0,.17443.55381A.61433.61433,0,0,0,5.29315,13.16194Z" fill="#f18b12" />
      <path d="M9.75,5.26444a1.46861,1.46861,0,0,1-.5021,1.14279,2.53557,2.53557,0,0,1-1.44538.55465V8h-.6292V6.99057A4.82537,4.82537,0,0,1,5.25,6.62717V5.61828a4.76743,4.76743,0,0,0,.94958.33736,4.274,4.274,0,0,0,.97374.14079V4.30814l-.39706-.14823a2.79654,2.79654,0,0,1-1.13235-.71935A1.54261,1.54261,0,0,1,5.28782,2.4051a1.3542,1.3542,0,0,1,.50735-1.0854A2.50294,2.50294,0,0,1,7.17332.80329V0h.6292V.789A5.15941,5.15941,0,0,1,9.62185,1.181l-.34454.875a5.03649,5.03649,0,0,0-1.47479-.35383V3.44269l.35924.13866a3.2807,3.2807,0,0,1,1.24265.73635A1.37283,1.37283,0,0,1,9.75,5.26444ZM6.39811,2.39554a.64174.64174,0,0,0,.16807.45424,1.71313,1.71313,0,0,0,.60714.35861v-1.487a1.09857,1.09857,0,0,0-.57668.2343A.56533.56533,0,0,0,6.39811,2.39554ZM8.63445,5.33617a.59845.59845,0,0,0-.19222-.4569,1.97426,1.97426,0,0,0-.63971-.3464V6.05818C8.35609,5.97211,8.63445,5.73144,8.63445,5.33617Z" fill="#56a91c" />
    </svg>
  );
}

export function InformationListIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="information_list_24">
      <path d="M1.2 2.9h7.1V10H1.2zm0 11.1h7.1v7.1H1.2zm9.4-10.7h12.2v1H10.6zm0 2.6h12.2v1H10.6zm0 2.6h12.2v1H10.6zm0 6h12.2v1H10.6zm0 2.6h12.2v1H10.6zm0 2.6h12.2v1H10.6z" />
    </svg>
  );
}

export function KindOfQuantityIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="kind_of_quantity_64">
      <defs>
        <linearGradient id="koqGradientA" x1="32" y1="10" x2="32" y2="-14" gradientTransform="matrix(1 0 0 -1 0 50)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e3b568" />
          <stop offset="1" stopColor="#d19d54" />
        </linearGradient>
      </defs>
      <title>KindOfQuantity_64</title>
      <path d="M57 8H34.96a18 18 0 1 0 0 20H57a5 5 0 0 0 5-5V13a5 5 0 0 0-5-5z" fill="#cdecff" />
      <path d="M20 10a8 8 0 1 0 8 8 8 8 0 0 0-8-8zm32 4H36a4 4 0 1 0 0 8h16a4 4 0 1 0 0-8z" fill="#fff" />
      <path d="M36 16h16a2 2 0 1 1 0 4H36a2 2 0 1 1 0-4zm-16-4a6 6 0 1 1-6 6 6 6 0 0 1 6-6z" fill="#f18b12" />
      <path d="M19.98 0v2A15.99 15.99 0 0 1 33.3 9.1l.6.89H57a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H33.9l-.6.89a16 16 0 1 1-13.32-24.9V0m0 0A18 18 0 1 0 34.96 28H57a5 5 0 0 0 5-5V13a5 5 0 0 0-5-5H34.96a17.99 17.99 0 0 0-14.98-8z" fill="#008be1" />
      <path fill="url(#koqGradientA)" d="M2 40h60v24H2z" />
      <path d="M2 40v24h60V40zm58 22H4V42h3v8h2v-8h2v4h2v-4h2v6h2v-6h2v4h2v-4h2v8h2v-8h2v4h2v-4h2v6h2v-6h2v4h2v-4h2v8h2v-8h2v4h2v-4h2v6h2v-6h2v4h2v-4h2v8h2v-8h3z" fill="#a07033" />
    </svg>
  );
}

export function MixinIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="mixin_24">
      <g id="mixin_24_2">
        <path fill="#cdecff" d="M17.7 4.7v8.6h-.5c-1.2 0-2.2.1-3 .2-1 .1-2.5.4-4.1 1-.5.2-1 .4-1.3.9-.2.2-.3 1-.2 2.2L.3 13.8v-9L9 .2l8.7 4.5z" />
        <path fill="#008be1" d="M9 0L0 4.6v9.2l8.6 3.9v-.5L.5 13.5V4.8L9 .5l8.5 4.3v8.5h.4V4.5L9 0z" />
        <path fill="#7fceff" d="M17.5 4.8v8.5h-.3c-1.2 0-2.2.1-3 .2-1 .1-2.5.4-4.1 1-.4.2-.8.4-1.2.7v-7l8.6-3.4z" />
        <ellipse transform="rotate(-31.94 2.08 8.75)" stroke="#fff" strokeWidth=".432" strokeMiterlimit="10" fill="#f18b12" cx="2.1" cy="8.7" rx=".6" ry=".9" />
        <path fill="#f18b12" stroke="#fff" strokeWidth=".432" d="M6.3 11.5L4 10.3c-.3-.1-.5-.5-.5-.9s.3-.6.6-.4l2.5 1.2c.3.2.5.6.4.9-.1.4-.4.6-.7.4z" />
        <ellipse transform="rotate(-31.94 2.08 11.7)" stroke="#fff" strokeWidth=".432" strokeMiterlimit="10" fill="#56aa1c" cx="2.1" cy="11.7" rx=".6" ry=".9" />
        <path fill="#56aa1c" stroke="#fff" strokeWidth=".432" d="M6.3 14.5L4 13.3c-.3-.1-.5-.5-.5-.9s.3-.6.6-.4l2.5 1.2c.3.2.5.6.4.9-.1.4-.4.5-.7.4z" />
        <g>
          <path fill="#e3e6e8" d="M23.6 18.7c-.1.4-.2.8-.3 1.3-.1.4-.3.8-.5 1.2-.3.6-.8 1.1-1.5 1.5-2 1.3-7.5 1.3-9.4 0-.6-.4-1-.9-1.4-1.4l-.6-1.2c-.2-.6-.4-1.1-.4-1.6-.2-1.2 0-2.3.1-2.5.3-.4.7-.6 1.1-.7 1.3-.5 2.6-.8 3.5-.9a15.74 15.74 0 0 1 4.9 0c.2 0 .5.1.8.1.7.1 1.6.3 2.5.7.4.2.8.4 1.1.7.1.3.3 1.4.1 2.8z" />
          <path fill="#4d575f" d="M16.6 23.8c-1.9 0-3.8-.3-4.8-1a4 4 0 0 1-1.4-1.5l-.6-1.2c-.2-.5-.4-1.1-.4-1.7-.2-1.1-.1-2.3.2-2.6.4-.4.8-.6 1.2-.8 1.4-.6 2.7-.8 3.5-.9a15.74 15.74 0 0 1 4.9 0c.3 0 .5.1.8.1.9.2 1.7.4 2.5.8.4.2.8.4 1.1.8.2.3.4 1.5.2 2.8l-.3 1.3c-.1.5-.3.9-.5 1.2-.4.7-.9 1.2-1.5 1.6-1.1.8-3 1.1-4.9 1.1zm.1-9.4c-1 0-1.9.1-2.5.2-.8.1-2.1.3-3.4.9-.4.1-.7.3-1.1.7-.1.1-.3 1.1-.1 2.3.1.5.2 1.1.4 1.6.2.4.4.8.6 1.1.4.5.8 1 1.4 1.4 1.8 1.3 7.3 1.3 9.2 0a5.02 5.02 0 0 0 1.9-2.6l.3-1.2c.2-1.4 0-2.4-.1-2.6-.3-.4-.7-.6-1-.7-.7-.3-1.6-.6-2.4-.7-.3-.1-.6-.1-.8-.1-.6-.2-1.4-.3-2.4-.3z" />
          <ellipse fill="#d5d9dd" cx="16.5" cy="17.2" rx="6.9" ry="2.1" />
          <path opacity=".8" fill="#b9bfc3" d="M19.9 14.7l-.6 2.2c.5.4.6 1.1.5 1.9v.2c0 .1 0 .2-.1.2-.2 0-.4.1-.7.1-.6.1-1.3.1-2 .2 0-.1-.1-.3-.1-.4 0-.3 0-.5.1-.8.2-.9.8-1.5 1.5-1.6l.5-2.1.9.1z" />
          <path fill="#fff" d="M23.5 17.5v1.1c-1 2.3-12.3 2.9-13.8 0l-.1-1.2v-.1c0-.1.1-.2.1-.2.6 1.2 3.5 2.1 6.9 2.1s6.3-.9 6.9-2.1h.1c-.1.2-.1.3-.1.4z" />
          <path fill="#d0d4d8" d="M23 20.1c-.1.3-.3.6-.4.9 0 .1-.1.1-.1.2a15.61 15.61 0 0 1-11.7.1c0-.1-.1-.1-.1-.2-.2-.3-.3-.6-.5-.9 1.2.6 3.4 1.4 6.4 1.4 3 0 5.3-.9 6.4-1.5z" />
          <path fill="#4d575f" d="M20.8 8.9l-1.4 5.2-.1.4-.6 2.2c.5.4.7 1.2.5 2v.1c0 .1-.1.2-.1.3-.6.1-1.3.1-2 .2h-.7v-.4c0-.3 0-.5.1-.8.2-.9.8-1.5 1.5-1.6l.6-2.1.1-.4L20 8.7c.1-.2.3-.4.5-.3.1 0 .2.1.2.1.1.2.1.3.1.4z" />
          <path fill="#4d575f" d="M9.5 16.7c.1.6.7 1 1.2 1.3.6.3 1.2.5 1.9.6 1.3.3 2.6.4 3.9.4 1.3 0 2.6-.1 3.9-.4.6-.1 1.3-.3 1.9-.6.6-.3 1.2-.7 1.3-1.3h.2c0 .4-.2.7-.4 1-.2.3-.5.4-.8.6-.6.3-1.3.5-1.9.7-1.3.3-2.7.5-4 .5a13.3 13.3 0 0 1-5.9-1.2c-.3-.2-.6-.4-.8-.6-.3-.2-.4-.6-.5-1z" />
        </g>
      </g>
    </svg>
  );
}

export function PhenomenonIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" id="phenomenon_64">
      <defs>
        <linearGradient id="phenomGradientA" x1="24.03442" y1="24.0415" x2="39.9585" y2="39.96558" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e3b568" />
          <stop offset="1" stopColor="#d19d54" />
        </linearGradient>
        <linearGradient id="phenomGradientB" x1="41.998" y1="37" x2="41.998" y2="62.99319" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff" />
          <stop offset="1" stopColor="#eef0f3" />
        </linearGradient>
      </defs>
      <title>large</title>
      <path d="M19.70453,51.8161a6.03581,6.03581,0,0,1,.00012-8.38672,30.95835,30.95835,0,0,1,22.29492-9.43615,31.39247,31.39247,0,0,1,3.704.22729L61.99548,17.92859,46.07141,2.00452l-44.074,44.074L17.92151,62.00256l5.93609-5.93609Z" fill="url(#phenomGradientA)" />
      <path d="M23.85559,56.06439,17.92,62,2,46.08,3.48,44.6l5.83,5.83L10.73,49,4.9,43.18l1.42-1.42,2.99,3,1.42-1.42-3-3,1.42-1.41,4.41,4.4,1.42-1.4-4.41-4.42,1.42-1.42,3,3,1.4-1.42-2.99-3,1.42-1.41,5.83,5.83,1.42-1.42-5.83-5.83,1.42-1.42,3,3L22.06,32l-2.99-3,1.42-1.42L24.9,32l1.42-1.42-4.41-4.41,1.42-1.42,3,3,1.41-1.42-3-3,1.42-1.42L32,27.74l1.42-1.41L27.59,20.5,29,19.08l3,3,1.41-1.42-3-3,1.42-1.42,4.42,4.42,1.41-1.42-4.4-4.41,1.4-1.42,3,3,1.42-1.43-3-2.99,1.42-1.42,5.83,5.83L44.75,15,38.92,9.16l1.42-1.41,3,3,1.41-1.43-3-2.99,1.43-1.42,4.4,4.41,1.43-1.41L44.59,3.49,46.08,2,62,17.92,45.69989,34.22009c.82025.09729,1.63489.21942,2.43933.38068L63.4,19.34a2,2,0,0,0,.00153-2.82843L63.4,16.51l.01-.01L47.5.6A2,2,0,0,0,44.67157.59845L44.67.6.6,44.66A2,2,0,0,0,.59845,47.4884L.6,47.49,16.5,63.41a2,2,0,0,0,2.82843.00159L19.33,63.41l5.91931-5.91931Z" fill="#a07033" />
      <path d="M41.99948,37a26.89062,26.89062,0,0,0-19.36439,8.19886A3.71242,3.71242,0,0,0,22.635,50.388L33.85949,61.87531a3.71239,3.71239,0,0,0,2.65346,1.11788h.0018a3.71243,3.71243,0,0,0,2.65276-1.11527,3.91854,3.91854,0,0,1,5.661,0,3.71259,3.71259,0,0,0,2.65276,1.11527H47.483a3.71239,3.71239,0,0,0,2.65346-1.11788L61.361,50.388a3.71242,3.71242,0,0,0-.00007-5.18914A26.88716,26.88716,0,0,0,41.99948,37Z" fill="url(#phenomGradientB)" />
      <path d="M39.38254,60.67508l-2.275-15.12882a.23485.23485,0,0,1,.444-.13653l7.25985,15.12882a.23484.23484,0,0,1-.21173.33645H39.61477A.23484.23484,0,0,1,39.38254,60.67508Z" fill="#d30a0a" />
      <path d="M41.99959,37.9932A26.98542,26.98542,0,0,1,61.43044,46.225a2.01193,2.01193,0,0,1,0,2.79557l-12.094,12.37718a2,2,0,0,1-2.85961.00145,6.22181,6.22181,0,0,0-8.95771-.00007,2,2,0,0,1-2.85965-.00136l-12.094-12.3772a2.01191,2.01191,0,0,1,0-2.79556,26.98907,26.98907,0,0,1,19.434-8.2318m0-2a28.97367,28.97367,0,0,0-20.86447,8.834,4,4,0,0,0-.00008,5.59112l12.094,12.3772A3.99992,3.99992,0,0,0,36.088,64H36.09a4,4,0,0,0,2.85827-1.20168,4.2221,4.2221,0,0,1,6.09951,0A4.00022,4.00022,0,0,0,47.906,64H47.908a4,4,0,0,0,2.859-1.20448l12.094-12.3772a4,4,0,0,0-.00007-5.59112,28.97,28.97,0,0,0-20.86129-8.834Z" fill="#677480" />
    </svg>
  );
}

export function RelationshipClassIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="relationship_24">
      <g id="relationship_24_2">
        <g id="g10">
          <g id="g12">
            <path fill="#2a2f34" d="M14.9 19.5H5.2c-.3 0-.6-.2-.7-.4-.1-.3 0-.6.2-.8L17.1 5.9H8.8c-.4 0-.8-.4-.8-.7s.3-.7.7-.7h10c.3 0 .6.2.7.4.1.3 0 .6-.2.8L6.9 18h7.9c.4 0 .7.3.7.7s-.2.8-.6.8z" id="path14" />
          </g>
          <g id="g16">
            <g id="g18">
              <path fill="#cdecff" id="polygon20" d="M6 .2L.1 3.1v6.1L6 11.8l5.9-2.6V3.1z" />
              <path fill="#008be1" d="M6 12L0 9.2V3.1L6 0l6 3v6.2L6 12zM.3 9.1L6 11.7l5.7-2.6V3.2L6 .3.3 3.2v5.9z" id="path22" />
            </g>
            <path fill="#7fceff" id="polygon24" d="M6 11.7V5.5l5.7-2.3v5.9z" />
            <ellipse transform="rotate(-31.94 1.4 5.86)" fill="#f18b12" stroke="#fff" strokeWidth=".216" strokeMiterlimit="10" cx="1.4" cy="5.9" rx=".4" ry=".6" id="ellipse26" />
            <g id="g28">
              <path fill="#f18b12" stroke="#fff" strokeWidth=".216" d="M4.2 7.7l-1.6-.8c-.1-.1-.3-.3-.3-.6 0-.2.2-.4.4-.3l1.7.8c.2.2.3.5.3.7-.1.2-.3.3-.5.2z" id="path30" />
            </g>
            <ellipse transform="rotate(-31.94 1.4 7.83)" fill="#56aa1c" stroke="#fff" strokeWidth=".216" strokeMiterlimit="10" cx="1.4" cy="7.8" rx=".4" ry=".6" id="ellipse34" />
            <g id="g36">
              <path fill="#56aa1c" stroke="#fff" strokeWidth=".216" d="M4.2 9.7l-1.6-.8c-.1-.1-.3-.4-.3-.6.1-.3.2-.4.4-.3l1.7.8c.2.1.3.4.3.6-.1.3-.3.4-.5.3z" id="path38" />
            </g>
          </g>
          <g id="g42">
            <g id="g44">
              <path fill="#cdecff" id="polygon46" d="M18 12.1l-5.9 3v6l5.9 2.7 5.9-2.7v-6z" />
              <path fill="#008be1" d="M18 23.9l-6-2.8V15l6-3.1 6 3v6.2l-6 2.8zM12.3 21l5.7 2.6 5.7-2.6v-5.9L18 12.3l-5.7 2.9V21z" id="path48" />
            </g>
            <path fill="#7fceff" id="polygon50" d="M18 23.6v-6.2l5.7-2.3V21z" />
            <ellipse transform="rotate(-31.94 13.4 17.8)" fill="#f18b12" stroke="#fff" strokeWidth=".216" strokeMiterlimit="10" cx="13.4" cy="17.8" rx=".4" ry=".6" id="ellipse52" />
            <g id="g54">
              <path fill="#f18b12" stroke="#fff" strokeWidth=".216" d="M16.2 19.7l-1.6-.8c-.2-.1-.3-.4-.3-.6 0-.2.2-.4.4-.3l1.7.8c.2.1.3.4.3.6-.1.3-.3.4-.5.3z" id="path56" />
            </g>
            <ellipse transform="rotate(-31.94 13.4 19.78)" fill="#56aa1c" stroke="#fff" strokeWidth=".216" strokeMiterlimit="10" cx="13.4" cy="19.8" rx=".4" ry=".6" id="ellipse60" />
            <g id="g62">
              <path fill="#56aa1c" stroke="#fff" strokeWidth=".216" d="M16.2 21.7l-1.6-.8c-.2-.1-.3-.4-.3-.6 0-.2.2-.4.4-.3l1.7.8c.2.1.3.4.3.6-.1.2-.3.4-.5.3z" id="path64" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function StructClassIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="struct_class_24">
      <g id="struct_class_24_2">
        <g id="g10">
          <path fill="#cdecff" id="polygon12" d="M12 3.4L.3 9.4v6L12 20.7l11.7-5.4v-6z" />
          <path fill="#008be1" d="M12 20.7L0 15.5V9.2l12-6.1 12 6.1v6.3l-12 5.2zM.6 15.1L12 20l11.4-4.9V9.5L12 3.7.6 9.6v5.5z" id="path14" />
          <path fill="#7fceff" id="polygon16" d="M12 20v-5.9l11.4-4.6v5.6z" />
          <ellipse transform="rotate(-31.94 2.78 13.85)" stroke="#fff" strokeWidth=".432" strokeMiterlimit="10" fill="#f18b12" cx="2.8" cy="13.8" rx=".8" ry="1.1" id="ellipse18" />
          <g id="g20">
            <path fill="#f18b12" stroke="#fff" strokeWidth=".432" d="M8.5 17.5l-3.2-1.4c-.4-.2-.7-.7-.7-1.2s.3-.8.7-.6l3.4 1.4c.4.2.7.7.6 1.2 0 .5-.3.8-.8.6z" id="path22" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function UnitSystemIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="unit_system_64">
      <defs>
        <linearGradient id="unitSysGradient" x1="32" y1="20.38" x2="32" y2="43.62" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e3b568" />
          <stop offset="1" stopColor="#d19d54" />
        </linearGradient>
      </defs>
      <title>UnitSystem_64</title>
      <circle cx="32" cy="32" r="31" fill="#fff" />
      <path d="M32 63.97A31.97 31.97 0 1 1 63.97 32 32 32 0 0 1 32 63.97zm0-62A30.03 30.03 0 1 0 62.03 32 30.07 30.07 0 0 0 32 1.97z" fill="#008be1" />
      <ellipse cx="32" cy="32" rx="15.5" ry="31" fill="none" stroke="#008be1" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M63 31.6a31.14 31.14 0 0 0-.53-5.35 82 82 0 0 0-60.94 0A31.13 31.13 0 0 0 1 31.57V32.4a31.13 31.13 0 0 0 .53 5.36 82 82 0 0 0 60.94 0l.02-.14a31.14 31.14 0 0 0 .5-5.2V32v-.4z" fill="url(#unitSysGradient)" />
      <path d="M64 32.03V31.59a32.19 32.19 0 0 0-.55-5.53l-.1-.54-.51-.2a82.66 82.66 0 0 0-61.68 0l-.5.2-.1.54A32.35 32.35 0 0 0 0 31.59V32.42a32.25 32.25 0 0 0 .55 5.52l.1.54.51.2A82.93 82.93 0 0 0 32 44.66a82.93 82.93 0 0 0 30.85-5.97l.48-.2.15-.68a32.26 32.26 0 0 0 .53-5.4v-.16-.21zm-2 .37a30.34 30.34 0 0 1-.42 4.63 80.64 80.64 0 0 1-59.16 0 30.2 30.2 0 0 1-.42-4.6v-.84a30.26 30.26 0 0 1 .42-4.62l.43-.16v5.5l1.93.27V26.1l1.94-.68v1.7l1.96-.23v-2.08q.99-.3 1.98-.57v5.73h1.99v-6.25c.66-.17 1.33-.32 2-.47v3.07l2.01-.15v-3.35c.68-.13 1.35-.24 2.03-.35v11.43l2.03.11V22.15c.68-.1 1.36-.17 2.04-.25v3.94q1.02-.05 2.05-.08v-4.08c.68-.06 1.37-.1 2.05-.15v8.45h2.05v-8.56a80.3 80.3 0 0 1 2.06-.05v4.28h2.06v-4.28c.68.01 1.37.02 2.06.05V34.3l2.05-.04V21.53c.68.05 1.37.09 2.05.15v4.08q1.03.03 2.05.08V21.9c.68.08 1.36.15 2.04.25v7.83h2.03v-7.5c.68.11 1.35.22 2.03.35v3.35l2.01.15v-3.07c.67.15 1.34.3 2 .47v9.72l2-.19v-9.01q.98.27 1.97.57v2.08l1.96.23v-1.7l1.94.68v3.87h1.93V26.8l.43.16a30.24 30.24 0 0 1 .42 4.64v.17z" fill="#a07033" />
    </svg>
  );
}

export function UnitIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="unit_64">
      <defs>
        <linearGradient id="unitGradientA" x1="32" y1="-4" x2="32" y2="40" gradientTransform="matrix(1 0 0 -1 0 50)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#d19d54" />
          <stop offset="1" stopColor="#e3b568" />
        </linearGradient>
      </defs>
      <title>Phenomenon_64</title>
      <path fill="url(#unitGradientA)" d="M0 10h64v44H0z" />
      <path d="M64 8v2h-3v6h-2v-6h-6v18h-2V10h-6v6h-2v-6h-6v12h-2V10h-6v6h-2v-6h-6v12h-2V10h-6v6h-2v-6H5v18H3V10H0V8zm-1 40h-2v6h-3v-6h-2v6h-3V42h-2v12h-3v-6h-2v6h-3v-6h-2v6h-3v-6h-2v6h-3v-6h-2v6h-3V36h-2v18h-3v-6h-2v6h-3v-6h-2v6h-3v-6h-2v6H8v-6H6v6H3V42H1v12H0v2h64v-2h-1z" fill="#a07033" />
    </svg>
  );
}
