/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { StrengthDirection } from "@itwin/ecschema-metadata";
import { RelationshipClassInfo, RelationshipMultiplicityInfo } from "frontend/api/Interfaces";
import { ElementLink } from "frontend/components/controls/ElementLink";
import { Text } from "@itwin/itwinui-react";
import "./RelationshipExplanation.scss";

interface Props {
  children?: React.ReactNode;
  data: RelationshipClassInfo;
  direction: StrengthDirection;
}

export class RelationshipExplanation extends React.Component<Props> {

  public getAdjectiveForMultiplicity(multiplicity: RelationshipMultiplicityInfo): string {
    return (multiplicity.upperLimit === 1) ? "one" : "many";
  }

  public getSuffixForMultiplicity(multiplicity: RelationshipMultiplicityInfo): string {
    return (multiplicity.lowerLimit === 0) ? "(optional)" : "(required)";
  }

  public override render() {

    const leftEnd = (this.props.direction === StrengthDirection.Forward) ? this.props.data.source : this.props.data.target;
    const rightEnd = (this.props.direction === StrengthDirection.Forward) ? this.props.data.target : this.props.data.source;

    return (
      <Text variant="body" as="span" className="relationship-explanation" >
        <ElementLink {...leftEnd.abstractConstraint} />
        <span className="role-label">{leftEnd.roleLabel}</span>
        <span className="adjective">{this.getAdjectiveForMultiplicity(rightEnd.multiplicity)}</span>
        <ElementLink {...rightEnd.abstractConstraint} />
        <span className="suffix">{this.getSuffixForMultiplicity(rightEnd.multiplicity)}</span>
      </Text>
    );
  }
}
