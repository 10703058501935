/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/

export interface ISchemaColorSet {
  background: string;
  border: string;
  text: string;
}

/**
 * Responsible for determining the coloring of schema alias "tags".
 * TODO: Replace a lot of this with some CSS classes.
 */
export class SchemaColorCoder {
  public static standardSchemaColors: {[name: string]: ISchemaColorSet} = {
    // Colored text & border with white back  (these are hardest to see)
    Bentley_ECSchemaMap:                { background: "#FFF", border: "#73C7C1", text: "#73C7C1" },
    Bentley_Standard_Classes:           { background: "#FFF", border: "#85A9CF", text: "#85A9CF" },
    Bentley_Standard_CustomAttributes:  { background: "#FFF", border: "#A3779F", text: "#A3779F" },
    ECDbMap:                            { background: "#FFF", border: "#C8C2B4", text: "#C8C2B4" },
    ECv3ConversionAttributes:           { background: "#FFF", border: "#A47854", text: "#A47854" },
    EditorCustomAttributes:             { background: "#FFF", border: "#6AB9EC", text: "#6AB9EC" },
    DimensionSchema:                    { background: "#FFF", border: "#B1C854", text: "#B1C854" },

    // Colored back with contrasting-colored text & border (ugly)
    KindOfQuantitySchema:               { background: "#F7706C", border: "#B1C854", text: "#B1C854" },
    Unit_Attributes:                    { background: "#6AB9EC", border: "#F7706C", text: "#F7706C" },
    Units_Schema:                       { background: "#B1C854", border: "#A3779F", text: "#A3779F" },
    Bentley_Common_Classes:             { background: "#4585A5", border: "#FFC335", text: "#FFC335" },
    CoreClasses:                        { background: "#FFC335", border: "#4585A5", text: "#4585A5" },
    CoreCustomAttributes:               { background: "#F7963E", border: "#4585A5", text: "#4585A5" },
    ECObjects:                          { background: "#73C7C1", border: "#F7706C", text: "#F7706C" },
    SchemaLocalizationCustomAttributes: { background: "#85A9CF", border: "#FFC335", text: "#FFC335" },
    ECDb_FileInfo:                      { background: "#A3779F", border: "#B1C854", text: "#B1C854" },
    ECDb_System:                        { background: "#A47854", border: "#FFC335", text: "#FFC335" },
  };

  public static colors: ISchemaColorSet[] = [
    // Colored back & border with white text
    { background: "#6AB9EC", border: "#6AB9EC", text: "#FFF" },
    { background: "#B1C854", border: "#B1C854", text: "#FFF" },
    { background: "#F7706C", border: "#F7706C", text: "#FFF" },
    { background: "#4585A5", border: "#4585A5", text: "#FFF" },
    { background: "#FFC335", border: "#FFC335", text: "#FFF" },
    { background: "#F7963E", border: "#F7963E", text: "#FFF" },
    { background: "#73C7C1", border: "#73C7C1", text: "#FFF" },
    { background: "#85A9CF", border: "#85A9CF", text: "#FFF" },
    { background: "#A3779F", border: "#A3779F", text: "#FFF" },
    { background: "#C8C2B4", border: "#C8C2B4", text: "#FFF" },
    { background: "#A47854", border: "#A47854", text: "#FFF" },

    // White text with black back & border
    { background: "#000", border: "#000", text: "#FFF" },

    // Black text & border with white back
    { background: "#FFF", border: "#000", text: "#000" },

    // 66%-colored back with black text & border
    { background: "rgba(106,185,236,168)", border: "rgba(0,0,0,168)", text: "rgba(0,0,0,224)" },
    { background: "rgba(177,200,84,168)", border: "rgba(0,0,0,168)", text: "rgba(0,0,0,224)" },
    { background: "rgba(247,112,108,168)", border: "rgba(0,0,0,168)", text: "rgba(0,0,0,224)" },
    { background: "rgba(69,133,165,168)", border: "rgba(0,0,0,168)", text: "rgba(0,0,0,224)" },
    { background: "rgba(255,195,53,168)", border: "rgba(0,0,0,168)", text: "rgba(0,0,0,224)" },
    { background: "rgba(247,150,62,168)", border: "rgba(0,0,0,168)", text: "rgba(0,0,0,224)" },
    { background: "rgba(115,199,193,168)", border: "rgba(0,0,0,168)", text: "rgba(0,0,0,224)" },
    { background: "rgba(133,169,207,168)", border: "rgba(0,0,0,168)", text: "rgba(0,0,0,224)" },
    { background: "rgba(163,119,159,168)", border: "rgba(0,0,0,168)", text: "rgba(0,0,0,224)" },
    { background: "rgba(200,194,180,168)", border: "rgba(0,0,0,168)", text: "rgba(0,0,0,224)" },
    { background: "rgba(164,120,84,168)", border: "rgba(0,0,0,168)", text: "rgba(0,0,0,224)" },

    // Colored text & border with white back
    { background: "#FFF", border: "#F7706C", text: "#F7706C" },
    { background: "#FFF", border: "#4585A5", text: "#4585A5" },
    { background: "#FFF", border: "#FFC335", text: "#FFC335" },
    { background: "#FFF", border: "#F7963E", text: "#F7963E" },
  ];

  private static _schemaColorMap: Map<string, number> = new Map();
  private static _transparent = { background: "transparent", border: "transparent", text: "transparent" };

  public static getColorSetForSchema(schemaName: string): ISchemaColorSet {
    if (!schemaName) { return this._transparent; }
    if (Object.prototype.hasOwnProperty.call(SchemaColorCoder.standardSchemaColors, schemaName))
      return SchemaColorCoder.standardSchemaColors[schemaName];

    let colorIndex = this._schemaColorMap.get(schemaName);
    if (colorIndex === undefined) {
      colorIndex = this._schemaColorMap.size;
      this._schemaColorMap.set(schemaName, colorIndex);
    }
    if (colorIndex < 0)
      return this._transparent;

    return SchemaColorCoder.colors[colorIndex % SchemaColorCoder.colors.length];
  }

  public static clearCache() {
    this._schemaColorMap = new Map ();
  }
}
