/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ClassRelationshipTree } from "frontend/components/controls/tree/ClassRelationshipTree";
import { ElementDetailsCard } from "frontend/components/stages/classbrowser/cards/ElementDetailsCard";

export class ClassRelationshipsCard extends React.Component {
  public override render() {
    return (
      <ElementDetailsCard title="Relationships:">
        <ClassRelationshipTree />
      </ElementDetailsCard>
    );
  }
}
