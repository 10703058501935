/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { List, ListRowRenderer } from "react-virtualized";

interface Props {
  numResults: number;
  currentResultIndex: number;
  resultRenderer: ListRowRenderer;
  noResultsRenderer(): JSX.Element;
  searchBoxWidth: number;
}

const RESULTSLIST_MIN_WIDTH = 450;
const RESULTSLIST_ROW_HEIGHT = 24;
const RESULTSLIST_MAX_HEIGHT = 500;
const RESULTSLIST_MIN_HEIGHT = RESULTSLIST_ROW_HEIGHT;

export class SearchResultsList extends React.Component<Props> {

  public override componentDidUpdate(prevProps: Props) {
    if (prevProps !== this.props) {
      this.forceUpdate();
    }
  }
  public override render() {
    const idealHeight = this.props.numResults * RESULTSLIST_ROW_HEIGHT;
    const actualHeight = Math.max(RESULTSLIST_MIN_HEIGHT, Math.min(RESULTSLIST_MAX_HEIGHT, idealHeight));
    const actualWidth = (this.props.numResults) ? Math.max(RESULTSLIST_MIN_WIDTH, this.props.searchBoxWidth) : this.props.searchBoxWidth;

    return (
      <div className="imse-search-results">
        <List
          tabIndex={-1}
          height={actualHeight}
          width={actualWidth}
          autoContainerWidth={true}
          rowHeight={RESULTSLIST_ROW_HEIGHT}
          rowCount={this.props.numResults}
          rowRenderer={this.props.resultRenderer}
          noRowsRenderer={this.props.noResultsRenderer}
          scrollToIndex={this.props.currentResultIndex}
        />
      </div>
    );
  }
}
