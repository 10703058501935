/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import "./ElementDetailsCard.css";

interface Props {
  children?: React.ReactNode;
  title: string;
}

export class ElementDetailsCard extends React.Component<Props> {
  public override render() {
    return (
      <div className="card">
        <span className="iui-text-leading card-title">
          <span>{this.props.title}</span>
        </span>
        <div className="card-contents">
          {this.props.children}
        </div>
      </div>
    );
  }
}
