import { ECClass } from "@itwin/ecschema-metadata";

export class ECClassExtended {
  private _thisClass: ECClass;
  private _subClasses: ECClassExtended[];

  constructor(eCClass: ECClass) {
    this._thisClass = eCClass;
    this._subClasses = [];
  }

  public get thisClass() {
    return this._thisClass;
  }

  public get subClasses() {
    return this._subClasses;
  }

  public addSubClass(subClass: ECClassExtended) {
    this._subClasses.push(subClass);
  }
}
