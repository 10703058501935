/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { Table } from "@itwin/itwinui-react";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { NullableCell } from "frontend/components/controls/table/CellFormatters";
import { useEffect, useMemo, useState } from "react";

const EnumeratorsTable  = (props: any) => {
  const columns: any[] = useMemo(() => [
    {
      id: "name",
      accessor: "name",
      Header: "Enumerator Name",
      width: 300,
    },
    {
      id: "value",
      accessor: "value",
      Header: "Enumerator Value",
      width: 200,
    },
    {
      id: "displayLabel",
      accessor: "displayLabel",
      Header: "Display Label",
      width: 300,
      Cell: ({ value }: { value: any }) => {
        return (
          <NullableCell value={value}/>
        );
      },
    },
    {
      id: "description",
      accessor: "description",
      Header: "Description",
      width: 500,
      Cell: ({ value }: { value: any }) => {
        return (
          <NullableCell value={value}/>
        );
      },
    },
  ], []);

  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = ImseFrontend.instance.getEnumerators(props.id);
      setData(response.map((item: any) => {
        const newRow: any = {};
        columns.forEach((column: any) => {
          const columnData: any = item[column.id];
          newRow[column.id] = columnData;
        });
        return newRow;
      }));
    };
    void fetchData();
  }, [props.id]);

  return (
    <div className="imsejs-table">
      <Table columns={columns}
        data={data}
        emptyTableContent='No data.'
        density="extra-condensed"
        isResizable
        columnResizeMode="expand"
        isSortable
        isSelectable
        selectionMode="single"
        autoResetPage={false}
        style={{
          width: "80vw",
        }}
      />
    </div>
  );
};

export default EnumeratorsTable;
