/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { RelationshipMultiplicityTypeConverter } from "frontend/api/RelationshipDataProvider";
import { RelationshipClassInfo } from "frontend/api/Interfaces";
import { UmlArrow } from "frontend/components/controls/uml/UmlArrow";
import { UmlEndpoint } from "frontend/components/controls/uml/UmlEndpoint";

import "./UmlRelationshipSummary.scss";

interface Props {
  children?: React.ReactNode;
  data: RelationshipClassInfo;
  expanded?: boolean;
  small?: boolean;
  onToggleExpand?(): void;
}

export class UmlRelationshipSummary extends React.Component<Props> {

  constructor(props?: any, context?: any) {
    super(props, context);
    this.state = {
      expanded: true,
    };
  }

  public override render() {
    const multiplicity = {
      left: RelationshipMultiplicityTypeConverter.convertToString(this.props.data.source.multiplicity),
      right: RelationshipMultiplicityTypeConverter.convertToString(this.props.data.target.multiplicity),
    };

    return (
      <div className="uml-relationship-summary">
        <UmlEndpoint
          small={this.props.small}
          expanded={this.props.expanded}
          onToggleExpand={this.props.onToggleExpand}
          {...this.props.data.source} />

        <UmlArrow
          small={this.props.small}
          strength={this.props.data.relationshipStrength}
          direction={this.props.data.relationshipDirection}
          roleLabel={this.props.data.source.roleLabel}
          multiplicity={multiplicity}
        />

        <UmlEndpoint
          small={this.props.small}
          expanded={this.props.expanded}
          onToggleExpand={this.props.onToggleExpand}
          {...this.props.data.target} />
      </div >
    );
  }
}
