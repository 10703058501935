/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { connect } from "react-redux";
import SplitPane from "react-split-pane";
import { NavTree } from "frontend/components/controls/tree/NavTree";
import "./ContentLayer.css";
import { Actions } from "frontend/state/reducers/splitters";
import { Selectors } from "frontend/state/selectors";
import { RootState } from "../state/rootReducer";
import { ElementSearchBox } from "./controls/search/ElementSearchBox";
import debounce from "lodash.debounce";

interface Props {
  // These are all connected to redux:
  children?: React.ReactNode;
  splitterSize: number;
  contentWidth: number;
  onSplitterChange(size: number): any;
}

const mapState = (state: RootState) => ({
  location: state.location,
  splitterSize: Selectors.navTreeSizeSelector(state),
  contentWidth: Selectors.contentWidthSelector(state),
});

const mapDispatch = {
  onSplitterChange: Actions.changeNavTreeSplitter,
};

export class ContentLayerComponent extends React.Component<Props> {

  public override componentDidMount() {
    window.addEventListener(
      "resize",
      debounce(() => {
        this.props.onSplitterChange(this.props.splitterSize);
      }, 200)
    );
  }

  public override render() {
    return (
      /* this lib is incompatible with react18. To fix     // children: React.ReactNode; needs to be added to SplitPaneProps.
      // @ts-ignore TS2322 */
      <SplitPane size={this.props.splitterSize} onChange={this.props.onSplitterChange} minSize={200} maxSize={-400} >
        <div className="left-pane" >
          <ElementSearchBox />
          <div id="whole-tree" className="wimse-tree-container">
            <NavTree />
          </div>
        </div>
        <div style={{ backgroundColor: "#f9f9f9", height: "100%", width: this.props.contentWidth }}>
          {this.props.children}
        </div>
      </SplitPane>
    );
  }
}

export const ContentLayer = connect(mapState, mapDispatch)(ContentLayerComponent);
