/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { ElementAttributes, ElementLinkData } from "frontend/api/Interfaces";
import { ElementLink } from "frontend/components/controls/ElementLink";
import { ClassRelationshipsCard } from "frontend/components/stages/classbrowser/cards/ClassRelationshipsCard";
import { SchemaItemType } from "@itwin/ecschema-metadata";
import { UsesCard } from "frontend/components/stages/classbrowser/cards/UsesCard";

type Props = ElementAttributes;
type State = ElementLinkData;

export class MixinDetails extends React.Component<Props, State> {
  private static _dataProvider = async (id: string) => ImseFrontend.instance.getEntityClassesUsingMixin(id);

  private static _defaultState = {
    id: "",
    name: "",
    schemaAlias: "",
    schemaName: "",
    elementType: SchemaItemType.Mixin,
    description: "",
  };

  public override readonly state: State = MixinDetails._defaultState;
  public initialized?: Promise<any>;

  private async fetchData() {
    const data = ImseFrontend.instance.getSchemaItemAttributes(this.props.id);
    if (data && data.elementType === SchemaItemType.Mixin)
      this.setState({...MixinDetails._defaultState, ...(data.appliesTo)});
  }

  public override componentDidMount() {
    this.initialized = this.fetchData();
  }

  public override componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id)
      this.initialized = this.fetchData();
  }

  public override render() {
    const appliesTo = this.state.id !== "" ? (<div className="item">
      <span className="label">Applies To: </span><ElementLink {...this.state} />
    </div>) : ("");

    return (
      <div className="details-page">
        <div className="element-attributes">
          {appliesTo}
        </div>
        <div className="card-container">
          <ClassRelationshipsCard />
          <UsesCard title="Used by:" dataProvider={MixinDetails._dataProvider} id={this.props.id} />
        </div>
      </div>
    );
  }
}
