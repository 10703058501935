/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { createAction, createReducer, combineReducers } from "@reduxjs/toolkit";
import { START_IMPORT, STOP_IMPORT, UPDATE_LOADED_SCHEMAS } from "frontend/state/actionTypes";
// tslint:disable:variable-name



export const Actions = {
  updateSchemas: createAction(UPDATE_LOADED_SCHEMAS, (schemas: string[]) => { return { payload: schemas }}),
  startImport: createAction(START_IMPORT),
  stopImport: createAction(STOP_IMPORT),
};

const initialState: string[] = [];

const loadedSchemas = createReducer(initialState, (builder) => {
  builder.addCase(Actions.updateSchemas, (_state, action) => action.payload);
});

const isImportRunning = createReducer(false, (builder) => {
  builder.addCase(Actions.startImport, () => true);
  builder.addCase(Actions.stopImport, () => false);
});

export const reducer = combineReducers({
  loadedSchemas,
  isImportRunning,
});
