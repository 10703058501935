import React from "react";

export function EmbeddingBoldIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="48" height="24" id="embedding_bold_48">
      <g stroke="#000" strokeWidth="4">
        <path d="M25 2.2l20.8 9.6c.3 0 .3.3 0 .4L25 21.8c-.3.2-.7.2-1 0L3.2 12.2c-.3 0-.3-.3 0-.4L24 2.2c.3-.2.7-.2 1 0z" strokeLinecap="round" strokeLinejoin="round" />
        <path fill="none" d="M0 12h4.7" />
      </g>
    </svg>
  );
}

export function EmbeddingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="48" height="24" id="embedding_48">
      <g stroke="#000" strokeWidth="2">
        <path d="M26 2L46.8 12c.3 0 .3.3 0 .4L26 22h-1L4.2 12c-.3 0-.3-.3 0-.4L25 2h1z" strokeLinecap="round" strokeLinejoin="round" />
        <path fill="none" d="M0 12h4.7" />
      </g>
    </svg>
  );
}

export function HoldingBoldIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="48" height="24" id="holding_bold_48">
      <g stroke="#000" transform="translate(-26.87 -162.693)">
        <path fill="none" strokeWidth="4" d="M52 165c6.8 3 13.7 6.3 20.7 9.5.2 0 .2.3 0 .4-7 3-14 6.3-20.8 9.5-.4.2-1 .2-1 0L30 175c-.2-.2-.2-.4 0-.5l21-9.6c0-.3.6-.3 1 0z" strokeLinecap="round" strokeLinejoin="round" />
        <rect width="25.4" height="25.4" x="200.2" y="282.6" fill="#0f0" strokeWidth="2.3" ry=".6" transform="scale(1.2705 .62114) rotate(45)" strokeLinecap="round" strokeLinejoin="round" />
        <path fill="none" strokeWidth="4" d="M27 174.7h4.6" />
      </g>
    </svg>
  );
}

export function HoldingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="48" height="24" id="holding_48">
      <g stroke="#000" transform="translate(-26.87 -162.693)">
        <path fill="none" strokeWidth="2" d="M53 164.8l20.7 9.7c.2 0 .2.3 0 .4-7 3-14 6.3-20.8 9.6h-1L31 175c-.2-.2-.2-.4 0-.5l21-9.7h1z" strokeLinecap="round" strokeLinejoin="round" />
        <rect width="25.4" height="25.4" x="200.2" y="282.6" fill="#0f0" strokeWidth="2.3" ry=".6" transform="scale(1.2705 .62114) rotate(45)" strokeLinecap="round" strokeLinejoin="round" />
        <path fill="none" strokeWidth="2" d="M27 174.7h4.6" />
      </g>
    </svg>
  );
}

export function ReferencingBoldIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="referencing_bold_24">
      <path fill="none" stroke="#000" strokeWidth="4" d="M0 12h22" />
      <path fill="none" stroke="#000" strokeWidth="4" d="M2 2l19.8 10c.3 0 .3.3 0 .4L2 22" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function ReferencingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="referencing_24">
      <path fill="none" stroke="#000" strokeWidth="2" d="M0 12h22" />
      <path fill="none" stroke="#000" strokeWidth="2" d="M2 1.8l20.8 10c.3 0 .3.3 0 .4L2 21.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
