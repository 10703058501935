/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import "./App.scss";
import { Provider } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as React from "react";
import { rootReducer } from "frontend/state/rootReducer";
import { Welcome } from "./components/Welcome";
import { Router } from "frontend/Router";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { Actions as SchemaActions } from "frontend/state/reducers/schemas";
import { createLocationFromSearchParams, ImseLocation, Actions as NavActions, updateWindowUrlToLocation } from "frontend/state/reducers/location";
import { Actions as AppActions } from "frontend/state/reducers/app";
import { Id64String } from "@itwin/core-bentley";
import { ErrorView } from "./components/ErrorView";
import { ConfigManager } from "./config/ConfigManager";
import { ImseConfig } from "./config/ImseConfig";
import { AppInsightsClient } from "./api/AppInsightsClient";
import { ProgressLinear } from "@itwin/itwinui-react";
import { IModelRpcPropsProxy } from "./api/IModelApiProxies";
import { configureStore } from "@reduxjs/toolkit";

const iModelKey = getStoreCacheKey(10);

const persistConfig = {
  key: iModelKey,
  storage,
  blacklist: ["schemas"],
};

const isFreshInstall = localStorage.length <= 0;

// Create the redux store
const store = configureStore(
  {
    reducer: persistReducer(persistConfig, rootReducer),
  }
);

interface State {
  initialized?: boolean;
  error?: Error;
  errorInfo?: any;
  welcomeMessage?: string;
}

/**
 * This is the root component that will be rendered for our application.
 * It should mostly just be responsible for routing and maybe some global CSS.
 */
export class App extends React.Component<unknown, State> {
  /** Set state with initialized false */
  public override readonly state: State = { initialized: false };

  private static initializeAppInsightsClient() {
    const appInsightsClient = new AppInsightsClient();
    ImseFrontend.AppInsightsClient = appInsightsClient;
  }

  public static async initializeApp(imseConfig: ImseConfig, currentStore: any, freshInstall: boolean) {
    // manages version changes from previous or fresh installs and persists any changes
    // in local storage
    const newVersion = await App.manageVersionChanges(freshInstall);

    const restoredState = currentStore.getState();
    if (!restoredState)
      return;

    // This information is used to create the iModelRpcProps which must be set
    // before any RPC calls are made.
    this.storeIModelContext(imseConfig, currentStore);

    currentStore.dispatch(NavActions.clearHistory());

    const loadedSchemas = await App.loadSchemas(currentStore);
    currentStore.dispatch(SchemaActions.updateSchemas(loadedSchemas.map((s) => s.id)));
    if (newVersion) {
      currentStore.dispatch(NavActions.clearHistory());
    }

    await ImseFrontend.AppInsightsClient.trackEvent("App initialized successfully.", await ImseFrontend.AppInsightsClient.getInsightProperties());
  }

  private static async manageVersionChanges(freshInstall: boolean): Promise<string | undefined> {
    const cachedVersion = localStorage.getItem("ImseVersion");
    const isNewVersion = !freshInstall && cachedVersion !== ConfigManager.appVersion;

    if (freshInstall || isNewVersion) {
      localStorage.setItem("ImseVersion", ConfigManager.appVersion);
      await persistStore(store).purge();
    }

    return isNewVersion ? ConfigManager.appVersion : undefined;
  }

  private static async loadSchemas(currentStore: any): Promise<Array<{ id: Id64String }>> {
    currentStore.dispatch(SchemaActions.startImport());
    let loadedSchemas = await ImseFrontend.instance.getLoadedSchemaIds();
    currentStore.dispatch(SchemaActions.stopImport());

    return loadedSchemas;
  }

  private static storeIModelContext(imseConfig: ImseConfig, currentStore: any) {
    const itwinId = imseConfig.itwinId;
    const iModelId = imseConfig.iModelId;
    const changeSetId = imseConfig.changeSetId || "";
    const key = `${iModelId}:${changeSetId}`;
    const imodelProps: IModelRpcPropsProxy = { key, iTwinId: itwinId, iModelId, changeset: {id: changeSetId} };
    currentStore.dispatch(AppActions.storeIModelProps(imodelProps));

    // set on frontend
    ImseFrontend.IModelProps = imodelProps;
  }

  public override componentDidMount() {
    this.componentDidMountAsync().then((result: boolean) => {
      this.setState({ initialized: result });
    },
    (error) => {
      this.setState({ error });
    });
  }

  public async componentDidMountAsync(): Promise<boolean> {
    try {
      const imseConfig = await ConfigManager.initialize();
      App.initializeAppInsightsClient();
      // Initialize Rpc Interface and prompt user for log in if iModel and iTwin was specified.
      if (ConfigManager.shouldSignIn()) {
        // Dynamically loaded for performance reasons
        const { RpcInterface } = await import("./api/RpcInterface");
        if (!await RpcInterface.initialize(imseConfig.rpcParams))
          return false;
      }

      await App.initializeApp(imseConfig, store, isFreshInstall);

      if (store.getState().schemas.loadedSchemas.length === 0) {
        if (ImseFrontend.AppInsightsClient.prevError) {
          this.setState({ welcomeMessage: ImseFrontend.AppInsightsClient.prevError});
        } else {
          this.setState({ welcomeMessage: "Unable to load schemas for this iModel." });
        }
        return true;
      }

      await this.bootstrapStoreAsync();
      const imseLocation = createLocationFromSearchParams(window.location.search);
      const checkStage = (stage: string | undefined) => { // Set initial stage to browse on default if its not supplied.
        return stage ? stage : "browse";
      };
      const defaultLocation: ImseLocation = {stage: "browse", elementType: 0, id: "biscore.element"};
      if (imseLocation) {
        store.dispatch(NavActions.navigateTo(checkStage(imseLocation.stage), imseLocation.elementType, imseLocation.id, undefined, imseLocation.propertyName));
      } else if (JSON.stringify(store.getState().location.current).match("{}")) {
        store.dispatch(NavActions.navigateTo(checkStage(defaultLocation.stage), defaultLocation.elementType, defaultLocation.id));
        store.dispatch(NavActions.clearHistory());
      } else {
        updateWindowUrlToLocation(store.getState().location.current);
      }

      return true;
    } catch (err) {
      const errorProps = await ImseFrontend.AppInsightsClient.getInsightProperties();
      this.setState({ error: err as Error, errorInfo: errorProps });
      return false;
    }

  }

  public async bootstrapStoreAsync(): Promise<void> {
    return new Promise((resolve) => {
      persistStore(store, undefined, () => {
        resolve();
      });
    });
  }

  public override render() {
    if (this.state.error) {
      void ImseFrontend.AppInsightsClient.handleExceptionOnErrorName(this.state.error, this.state.errorInfo);
      return (<ErrorView error={this.state.error} />);
    }
    if (!this.state.initialized)
      return (<ProgressLinear indeterminate={true} labels={["Initializing..."]} />);

    if (this.state.welcomeMessage) {
      return (<Welcome status="error" message={this.state.welcomeMessage} />);
    }
    return (
      <Provider store={store}>
        <div className="App">
          <Router />
        </div>
      </Provider>
    );
  }
}

export function getStoreCacheKey(maxCachedStores: number): string {
  const params = new URLSearchParams(window.location.search.toLowerCase());
  const iModelId = params.get("imodelid");

  if (iModelId === null || iModelId === "") {
    return "primary";
  }

  const iModelKeyList = localStorage.getItem("iModelKeys");
  let iModelKeys: string[] = iModelKeyList ? JSON.parse(iModelKeyList) : [];
  iModelKeys = iModelKeys.filter((value: string) => value !== iModelId );
  iModelKeys.push(iModelId);
  while (iModelKeys.length > maxCachedStores) {
    const keyToPurge = iModelKeys.shift();
    localStorage.removeItem(`persist:${keyToPurge}`);
  }
  localStorage.setItem("iModelKeys", JSON.stringify(iModelKeys));
  return iModelId;
}
