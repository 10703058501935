/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { connect } from "react-redux";
import { AnyElementType, ElementSearchResultData } from "frontend/api/Interfaces";
import { Actions } from "frontend/state/reducers/location";
import { ElementSearchProvider } from "./ElementSearchProvider";
import { SearchBox } from "./SearchBox";
import { SearchResultListItem, SearchResultProps, SearchResultText } from "./SearchResult";
import { PropertyTypeIcon, SmallElementTypeIcon } from "../ElementIcons";
import { SchemaTag } from "../SchemaTag";

type ResultProps = SearchResultProps<ElementSearchResultData>;
export class ElementSearchResult extends React.Component<ResultProps> {
  public override render() {
    const { ...otherProps } = this.props;
    const result = this.props.data.getResult();
    const prefix = result.className && (<span className="property-class">{result.className}.</span>);
    const icon = (result.pType) ? (<PropertyTypeIcon propertyType={result.pType} />) : (<SmallElementTypeIcon elementType={result.elementType} />);

    const generateText = () => {
      if (this.props.data.getMatchedKey() === "name") {
        return (
          <>
            <SearchResultText data={this.props.data} />
            &nbsp;
            {this.props.data.getResult().label ? <span className="display-label">({this.props.data.getResult().label})</span> : null}
          </>
        );
      } else { // matched search key is the display label.
        return (
          <>
            {this.props.data.getResult().name}
            &nbsp;
            <span className="display-label">(<SearchResultText data={this.props.data}/>)</span>
          </>
        );
      }
    };
    return (
      <SearchResultListItem {...otherProps}>
        {icon}
        {prefix}
        {generateText()}
        <SchemaTag
          schemaAlias={result.schemaAlias}
          schemaName={result.schemaId || result.name!}
          isSchema={false} />
      </SearchResultListItem>
    );
  }
}

interface Props {
  navigate(id: string, elementType: AnyElementType, propertyName?: string): any; // Connected to redux
}

const mapDispatch = {
  navigate: (id: string, elementType: AnyElementType, propertyName?: string) => Actions.navigateTo("browse", elementType, id, undefined, propertyName),
};

export class ElementSearchBoxComponent extends React.Component<Props> {
  private _selectItem = (item: ElementSearchResultData) => {
    this.props.navigate(item.pId || item.id, item.elementType, item.pName);
  };

  public override render() {
    return (
      <SearchBox
        resultComponent={ElementSearchResult}
        providerComponent={ElementSearchProvider}
        onSelectResult={this._selectItem}
      />
    );
  }
}

export const ElementSearchBox = connect(undefined, mapDispatch)(ElementSearchBoxComponent);
