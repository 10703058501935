/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import "./ElementIcons.scss";

import { PropertyType, SchemaItemType } from "@itwin/ecschema-metadata";
import { AnyElementType, SchemaType } from "frontend/api/Interfaces";
import { ConstantIcon, CustomAttributeIcon, EntityClassIcon, EnumerationIcon, FormatIcon, KindOfQuantityIcon, MixinIcon, PhenomenonIcon, PropertyCategoryIcon, RelationshipClassIcon, SchemaIcon, StructClassIcon, UnitIcon, UnitSystemIcon } from "frontend/icons/Icons";
import { SmallConstantIcon, SmallCustomAttributeIcon, SmallEntityClassIcon, SmallEnumerationIcon, SmallFormatIcon, SmallKindOfQuantityIcon, SmallMixinIcon, SmallPhenomenonIcon, SmallPropertyCategoryIcon, SmallRelationshipClassIcon, SmallSchemaIcon, SmallStructClassIcon, SmallUnitIcon, SmallUnitSystemIcon } from "frontend/icons/IconsSmall";
import { BinaryArrayIcon, BinaryIcon, BooleanArrayIcon, BooleanIcon, DateArrayIcon, DateIcon, DoubleArrayIcon, DoubleIcon, EnumArrayIcon, EnumIcon, GeometryArrayIcon, GeometryIcon, IntegerArrayIcon, IntegerIcon, LongArrayIcon, LongIcon, NavigationIcon, Point2DArrayIcon, Point2DIcon, Point3DArrayIcon, Point3DIcon, StringArrayIcon, StringIcon, StructArrayIcon, StructIcon } from "frontend/icons/PropertyTypeIcons";

function mapPropertyTypeToIcon(propertyType: PropertyType): JSX.Element {
  switch (propertyType) {
    case PropertyType.Binary: return <BinaryIcon />;
    case PropertyType.Boolean: return <BooleanIcon />;
    case PropertyType.DateTime: return <DateIcon />;
    case PropertyType.Double: return <DoubleIcon />;
    case PropertyType.Integer: return <IntegerIcon />;
    case PropertyType.Long: return <LongIcon />;
    case PropertyType.Point2d: return <Point2DIcon />;
    case PropertyType.Point3d: return <Point3DIcon />;
    case PropertyType.String: return <StringIcon />;
    case PropertyType.IGeometry: return <GeometryIcon />;
    case PropertyType.Struct: return <StructIcon />;
    case PropertyType.Navigation: return <NavigationIcon />;
    case PropertyType.Integer_Enumeration: return <EnumIcon />;
    case PropertyType.String_Enumeration: return <EnumIcon />;

    case PropertyType.Binary_Array: return <BinaryArrayIcon />;
    case PropertyType.Boolean_Array: return <BooleanArrayIcon />;
    case PropertyType.DateTime_Array: return <DateArrayIcon />;
    case PropertyType.Double_Array: return <DoubleArrayIcon />;
    case PropertyType.Integer_Array: return <IntegerArrayIcon />;
    case PropertyType.Long_Array: return <LongArrayIcon />;
    case PropertyType.Point2d_Array: return <Point2DArrayIcon />;
    case PropertyType.Point3d_Array: return <Point3DArrayIcon />;
    case PropertyType.String_Array: return <StringArrayIcon />;
    case PropertyType.IGeometry_Array: return <GeometryArrayIcon />;
    case PropertyType.Struct_Array: return <StructArrayIcon />;
    case PropertyType.Integer_Enumeration_Array: return <EnumArrayIcon />;
    case PropertyType.String_Enumeration_Array: return <EnumArrayIcon />;
    default:
      throw new Error("Unknown Property Type");
  }
}

function mapElementTypeToIcon(elementType: AnyElementType | undefined, useSmallVariant: boolean): JSX.Element {
  if (elementType === undefined) {
    throw new Error("Element Type is undefined.");
  } else {
    switch (elementType) {
      case SchemaType.none: return (useSmallVariant) ? <SmallSchemaIcon /> : <SchemaIcon />;
      case SchemaType.schema: return (useSmallVariant) ? <SmallSchemaIcon /> : <SchemaIcon />;
      case SchemaItemType.EntityClass: return (useSmallVariant) ? <SmallEntityClassIcon /> : <EntityClassIcon />;
      case SchemaItemType.Mixin: return (useSmallVariant) ? <SmallMixinIcon />: <MixinIcon />;
      case SchemaItemType.StructClass: return (useSmallVariant) ? <SmallStructClassIcon /> : <StructClassIcon />;
      case SchemaItemType.CustomAttributeClass: return (useSmallVariant) ? <SmallCustomAttributeIcon />: <CustomAttributeIcon />;
      case SchemaItemType.RelationshipClass: return (useSmallVariant) ? <SmallRelationshipClassIcon /> : <RelationshipClassIcon />;
      case SchemaItemType.Enumeration: return (useSmallVariant) ? <SmallEnumerationIcon />: <EnumerationIcon />;
      case SchemaItemType.InvertedUnit:
      case SchemaItemType.Unit: return (useSmallVariant) ? <SmallUnitIcon /> : <UnitIcon />;
      case SchemaItemType.Phenomenon: return (useSmallVariant) ? <SmallPhenomenonIcon />: <PhenomenonIcon />;
      case SchemaItemType.UnitSystem: return (useSmallVariant) ? <SmallUnitSystemIcon /> : <UnitSystemIcon />;
      case SchemaItemType.Format: return (useSmallVariant) ? <SmallFormatIcon />: <FormatIcon />;
      case SchemaItemType.KindOfQuantity: return (useSmallVariant) ? <SmallKindOfQuantityIcon /> : <KindOfQuantityIcon />;
      case SchemaItemType.PropertyCategory: return (useSmallVariant) ? <SmallPropertyCategoryIcon />: <PropertyCategoryIcon />;
      case SchemaItemType.Constant: return (useSmallVariant) ? <SmallConstantIcon /> : <ConstantIcon />;
      default:
        throw new Error("Unknown Element Type");
    }
  }
}

export class PropertyTypeIcon extends React.Component<{ propertyType: PropertyType }> {
  public override render() {
    return (
      <div className="property-type-icon">
        {mapPropertyTypeToIcon(this.props.propertyType)}
      </div>
    );
  }
}

export class ElementTypeIcon extends React.Component<{ elementType: AnyElementType | undefined }> {
  public override render() {
    return (
      <div className="wimse-icon">
        {mapElementTypeToIcon(this.props.elementType, false)}
      </div>
    );
  }
}

export class SmallElementTypeIcon extends React.Component<{ elementType: AnyElementType | undefined}> {
  public override render() {
    return (
      <div className="wimse-icon">
        {mapElementTypeToIcon(this.props.elementType, true)}
      </div>
    );
  }
}
