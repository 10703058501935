/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import "./ExternalDocsSummaryCard.css";
import { ElementDetailsCard } from "frontend/components/stages/classbrowser/cards/ElementDetailsCard";

interface Props {
  resourcePath: string;
}

interface State {
  summaryTitle: string;
  summaryContent: string;
}

/**
 * A react component that fetches external html from the public (github) iModel.js documentation site.
 * The resourcePath provided in the props interface should identify an html page that contains an element
 * with the id of 'main', which is used to capture the html that is displayed within this control.
 *
 * NB: This component should only *ever* add plain text to the DOM - since we don't isolate the remote
 * documentation content in a separate process, it is not safe to load scripts or other resources in this component.
 */
export class ExternalDocsSummaryCard extends React.PureComponent<Props, State> {
  private static _maxSummaryCharacters = 400;

  /**
   * Initializes a new ExternalDocsSummaryCard
   * @param props The props for the control.
   * @param context The context. Maybe undefined.
   */
  constructor(props: any, context?: any) {
    super(props, context);
    this.state = {summaryTitle: "", summaryContent: ""};
  }

  public override async componentDidMount() {
    const document = await this.retrieveDocument(this.props);
    if (!document) {
      return;
    }

    this.updateState(document);
  }

  public override async componentDidUpdate(prevProps: any) {
    if (this.props === prevProps)
      return;

    const content = await this.retrieveDocument(this.props);
    if (!content) {
      return;
    }

    this.updateState(content);
  }

  private async retrieveDocument(props: any): Promise<HTMLElement|undefined> {
    if (!props.resourcePath)
      return;

    let res;

    try {
      res = await fetch(props.resourcePath);
    } catch {
      return;
    }

    if (!res || !res.ok)
      return;

    const responseText = await res.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(responseText, "text/html");
    const content = doc.getElementById("main");
    return content || undefined;
  }

  private updateState(content: HTMLElement) {
    let title = "";
    let summary = "";
    const headings = content.getElementsByTagName("h1");

    if (!headings || headings.length === 0)
      return this.setState({summaryTitle: "", summaryContent: ""});

    title = headings[0].textContent || "";

    const paragraphs = content.getElementsByTagName("p");
    if (!paragraphs || paragraphs.length === 0)
      return this.setState({summaryTitle: "", summaryContent: ""});

    summary = this.truncateSummary(paragraphs[0].textContent || "");
    this.setState({summaryTitle: title, summaryContent: summary});
  }

  private truncateSummary(summary: string): string {
    // truncate to max characters
    return summary.substring(0, ExternalDocsSummaryCard._maxSummaryCharacters);
  }

  public override render() {
    if (!this.state.summaryContent || this.props.resourcePath === "")
      return null;
    const readMore = "Read More »";
    return (
      <ElementDetailsCard title={`${this.state.summaryTitle}:`}>
        <div className="external-docs-summary">
          <span>{this.state.summaryContent}</span>
          <a href={this.props.resourcePath} target="_blank" rel="noopener noreferrer">{readMore}</a>
        </div>
      </ElementDetailsCard>
    );
  }
}

export type ExternalDocsSummaryCardProps = Props;
