/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { ElementAttributes, SchemaDefData } from "frontend/api/Interfaces";
import { SchemaTabView } from "frontend/components/controls/SchemaTabView";

type Props = ElementAttributes;
type State = SchemaDefData;

export class SchemaDetails extends React.Component<Props, State> {

  private static _defaultState = {
    alias: "",
    version: "",
  };

  public override readonly state: State = SchemaDetails._defaultState;
  public initialized?: Promise<any>;

  private async fetchData() {
    const data = ImseFrontend.instance.getSchemaDef(this.props.id);
    this.setState({...SchemaDetails._defaultState, ...data});
  }

  public override componentDidMount() {
    this.initialized = this.fetchData();
  }

  public override componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id)
      this.initialized = this.fetchData();
  }

  public override render() {
    return (
      <div className="details-page">
        <div className="element-attributes">
          <div className="item">
            <span className="label">Alias: </span><span className="value">{this.state.alias}</span>
          </div>
          <div className="item">
            <span className="label">Version: </span><span className="value">{this.state.version}</span>
          </div>
        </div>
        <SchemaTabView />
      </div>
    );
  }
}
