/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import "./ElementDescription.css";
import * as React from "react";

interface Props {
  description?: string;
}

export class ElementDescription extends React.PureComponent<Props> {
  public override render() {
    let description = this.props.description;
    let className = "description";
    if (!description) {
      description = "Missing Description";
      className += " not-quite-so-angry-text";
    }

    return (
      <span className={className}>{description}</span>
    );
  }
}
