/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { ElementAttributes, EnumerationAttributes } from "frontend/api/Interfaces";
import { PrimitiveType } from "@itwin/ecschema-metadata";
import { UsesCard } from "frontend/components/stages/classbrowser/cards/UsesCard";
import "../cards/CardContainer.scss";

type Props = ElementAttributes;
type State = Partial<EnumerationAttributes>;

export class EnumerationDetails extends React.Component<Props, State> {
  private static _dataProvider = async (id: string) => ImseFrontend.instance.getPropertiesThatUseEnum(id);

  private static _defaultState = {
    primitiveType: PrimitiveType.Integer,
    isStrict: false,
  };

  public override readonly state: State = EnumerationDetails._defaultState;
  public initialized?: Promise<any>;

  private async fetchData() {
    const data = ImseFrontend.instance.getSchemaItemAttributes(this.props.id) as EnumerationAttributes;
    this.setState({...EnumerationDetails._defaultState, ...data});
  }

  public override componentDidMount() {
    this.initialized = this.fetchData();
  }

  public override componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id)
      this.initialized = this.fetchData();
  }

  public override render() {
    const isStrict = this.state.isStrict ? "true" : "false"; // TODO: l10n/i18n
    const primitiveType = (this.state.primitiveType === PrimitiveType.Integer) ? "Integer" : "String"; // TODO: l10n/i18n
    return (
      <div className="details-page">
        <div className="element-attributes">
          <div className="item">
            <span className="label">Type: </span><span className="value">{primitiveType}</span>
          </div>
          <div className="item">
            <span className="label">IsStrict: </span><span className="value">{isStrict}</span>
          </div>
        </div>
        <div className="card-container">
          <UsesCard title="Used by:" dataProvider={EnumerationDetails._dataProvider} id={this.props.id} />
        </div>
      </div>
    );
  }
}
