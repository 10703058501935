/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { createAction, createReducer } from "@reduxjs/toolkit"
import { CHANGE_ELEMENT_DETAILS_SPLITTER, CHANGE_NAV_TREE_SPLITTER } from "frontend/state/actionTypes";

export interface NavContentSize {
  readonly navTreeSize: number;
  readonly contentSize: number;
  readonly elementDetailsSize?: number;
}

const getNavContentSize = (state: NavContentSize, payload: number): NavContentSize => {
  return {...state, navTreeSize: payload, contentSize: window.innerWidth - payload};
};

export const Actions = {
  changeNavTreeSplitter: createAction(CHANGE_NAV_TREE_SPLITTER, (size: number) => { return { payload: size }}),
  changeDetailsSplitter: createAction(CHANGE_ELEMENT_DETAILS_SPLITTER, (size: number) => { return { payload: size }}),
};

const defaultState: NavContentSize = { navTreeSize: 350, contentSize: window.innerWidth - 350, elementDetailsSize: 125 };

const splittersReducer = createReducer(defaultState, (builder) => {
  
  builder
    .addCase(Actions.changeNavTreeSplitter, (state, action) => getNavContentSize(state, action.payload))
    .addCase(Actions.changeDetailsSplitter, (state, action) => ({ ...state, elementDetailsSize: action.payload }));
});

export const reducer = splittersReducer;
