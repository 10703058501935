import { ImseConfig } from "./ImseConfig";
import { Guid } from "@itwin/core-bentley";
import { UrlConfigManager } from "./UrlConfigManager";
import { BentleyCloudRpcParamsProxy, OpenAPIInfoProxy } from "frontend/api/IModelApiProxies";

export interface FrontendConfiguration {
  imodeljs_serviceUrl: string;
  oidc_serviceUrl: string;
}

/**
 * Manages Imse Frontend configuration.
 */
export class ConfigManager {
  private static _itwinId: string | undefined;
  private static _iModelId: string | undefined;

  /** Gets the iModel API URI. */
  public static get iModelApiUri(): string {
    if (!process.env.IMJS_ORCHESTRATOR_K8S)
      throw new Error("IMJS_ORCHESTRATOR_K8S not set!");
    return `${process.env.IMJS_ORCHESTRATOR_K8S}/imodels`;
  }

  /** Gets the window.location.search string. */
  public static get locationSearch() {
    return window.location.search;
  }

  /** Gets the window.location.href string. */
  public static get locationHref() {
    return window.location.href;
  }

  /** The version of the app as set by webpack from package.json */
  public static get appVersion() {
    return "2.8.0";
  }

  /** The GPR id for this application. */
  public static applicationId = "2778";

  /** Indicates if the URL is a redirect from OIDC login. */
  public static get isRedirectUrl(): boolean {
    return this.locationHref.includes("/signin-oidc");
  }

  public static shouldSignIn(): boolean {
    if (this._itwinId !== "" && this._iModelId !== "") return true;
    return false;
  }
  /** Gets the project Id. */
  public static get itwinId(): string {
    if (undefined === this._itwinId)
      throw new Error("itwinId not set!");
    return this._itwinId;
  }

  /** Gets the iModel Id. */
  public static get iModelId(): string {
    if (undefined === this._iModelId)
      throw new Error("iModelId not set!");
    return this._iModelId;
  }

  /** Gets the authorization authority URL. */
  public static get authorityUrl(): string {
    if (!process.env.IMJS_AUTH_AUTHORITY)
      throw new Error("IMJS_AUTH_AUTHORITY not set!");
    return process.env.IMJS_AUTH_AUTHORITY;
  }

  /** Gets the AppInsights instrumentation key (set by Octopus)*/
  public static get appInsightsInstrumentationKey(): string {
    if (!process.env.IMJS_INSTRUMENTATION_KEY)
      throw new Error("IMJS_INSTRUMENTATION_KEY not set!");
    return process.env.IMJS_INSTRUMENTATION_KEY;
  }

  /** Gets the URL to the bis schema zipped resource (set by Octopus)*/
  public static get bisSchemaResource(): string {
    if (!process.env.IMJS_BISSCHEMA_RESOURCE)
      throw new Error("IMJS_BISSCHEMA_RESOURCE not set!");
    return process.env.IMJS_BISSCHEMA_RESOURCE;
  }
  /** Gets the ChangeSet Id. Maybe null. */
  public static changeSetId?: string;

  /** Gets the session Id. */
  public static readonly sessionId = Guid.createValue();

  /** Initializes the IMSE ConfigManager. */
  public static async initialize(): Promise<ImseConfig> {
    // Retrieves config vars from the URL and/or from Buddi.
    UrlConfigManager.initialize(this.isRedirectUrl);

    const imseConfig: ImseConfig = { iModelId: "", itwinId: "" };

    if (UrlConfigManager.iModelId && UrlConfigManager.itwinId) {
      imseConfig.iModelId = UrlConfigManager.iModelId;
      imseConfig.itwinId = UrlConfigManager.itwinId;

      if (UrlConfigManager.changeSetId)
        imseConfig.changeSetId = UrlConfigManager.changeSetId;
    }

    imseConfig.rpcParams = this._getRpcParams();
    this._itwinId = imseConfig.itwinId;
    this._iModelId = imseConfig.iModelId;
    this.changeSetId = imseConfig.changeSetId;

    return imseConfig;
  }

  private static _getRpcParams(): BentleyCloudRpcParamsProxy {
    if (!process.env.IMJS_ORCHESTRATOR_K8S)
      throw new Error("IMJS_ORCHESTRATOR_K8S not set!");

    const info: OpenAPIInfoProxy = { title: "imodel/rpc", version: "v4" };
    return { info, uriPrefix: process.env.IMJS_ORCHESTRATOR_K8S };
  }
}
