/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { NodeData, Tree, TreeNode, NodeRenderProps } from "@itwin/itwinui-react";
import { navTreeRootNodes } from "frontend/components/controls/tree/rootNodes";
import "./NavTree.scss";

import { connect } from "react-redux";
import { Actions as TreeActions } from "frontend/state/reducers/tree";
import { Actions as NavActions, parseStringToElementType, parseTypeToString } from "frontend/state/reducers/location";
import { RootState } from "frontend/state/rootReducer";
import { Selectors } from "frontend/state/selectors";
import { AnyElementType, NavTreeNode, OtherNodeType } from "frontend/api/Interfaces";
import { SmallElementTypeIcon } from "../ElementIcons";
import { SchemaTag } from "../SchemaTag";
import { SchemaFilterButton } from "../SchemaFilterButton";
import { NodeLabel } from "./NodeLabel";

interface Props {
  // These are all connected to redux:
  selectedNode: string | undefined;
  loadedSchemas: ReadonlyArray<string>;
  expandedNodes: ReadonlyArray<string>;
  onSelectionChanged(id?: string, elementType?: AnyElementType): any;
  expandTreeNode(node: string): any;
  collapseTreeNode(node: string): any;
  excludeSchemas: ReadonlyArray<string>;
  currentLocationSource: string | undefined;
  updateNavigationSource(): any;
}

interface State {
  focusedNode: string | null;
  excludingSchemas: string[];
  childToParentMap: Map<string, string>;
  rootNodes: NavTreeNode[];
  schemaFilterToggled: boolean;
}

const mapState = (state: RootState) => ({
  loadedSchemas: state.schemas.loadedSchemas,
  selectedNode: Selectors.elementIdSelector(state) || parseTypeToString(state.location.current.elementType),
  expandedNodes: state.tree.expandedNodes,
  excludeSchemas: state.settings.schemaFilters.excludeSchemas,
  currentLocationSource: state.location.source,
});

const mapDispatch = {
  onSelectionChanged: (id?: string, elementType?: AnyElementType) => NavActions.navigateTo("browse", elementType, id, "NavTree"),
  expandTreeNode: TreeActions.expandTreeNode,
  collapseTreeNode: TreeActions.collapseTreeNode,
  updateNavigationSource: () => NavActions.updateNavigationSource("NavTree"),
};

/**
 * The main navigation tree for the ClassBrowser frontstage.
 */
export class NavTreeComponent extends React.Component<Props, State> {

  constructor(props: any) {
    super(props);
    this.state = {
      focusedNode: null,
      childToParentMap: new Map(),
      excludingSchemas: props.excludeSchemas,
      rootNodes: [],
      schemaFilterToggled: false,
    };
  }

  private static groupNodesByLetter(nodes: NavTreeNode[]) {
    const letterNodes: { [key: string]: NavTreeNode } = {}; // container to track which letters have children
    for (const node of nodes) {
      const letter = node.text.slice(0, 1);
      if (!letterNodes[letter]) // create new letter branch if one doesn't exist
        letterNodes[letter] = { id: `UnitDef.${letter}`, text: letter, children: [node] };
      else // add the node to the existing letter branch
        (letterNodes[letter].children as NavTreeNode[]).push(node);
    }

    return Object.keys(letterNodes).map((letter) => letterNodes[letter]);
  }

  private buildChildToParentMap(childToParentMap: Map<string, string>, node?: NavTreeNode, parent?: NavTreeNode) {
    if (node === undefined) {
      return;
    }
    if (parent !== undefined) {
      childToParentMap.set(node.id, parent.id);
    }
    node.children?.map((child) => this.buildChildToParentMap(childToParentMap, child, node));
  }

  private expandToTop(parentId?: string) {
    if (parentId === undefined) {
      return;
    }
    this._expandNode(parentId, true);
    this.expandToTop(this.state.childToParentMap.get(parentId));
  }

  private static populateRootNodes(rootNodes: NavTreeNode[], excludeSchemas: ReadonlyArray<string>) {
    navTreeRootNodes.forEach((rootNode) => {
      rootNodes.push({
        id: rootNode.id,
        text: rootNode.text,
        children: NavTreeComponent.getChildNodes(excludeSchemas, rootNode.id),
        elementType: OtherNodeType.root,
      });
    });
  }
  public override componentDidMount() {
    const rootNodes: NavTreeNode[] = [];
    NavTreeComponent.populateRootNodes(rootNodes, []);
    const childToParentMap = new Map<string, string>();
    for (const root of rootNodes) {
      this.buildChildToParentMap(childToParentMap, root);
    }
    this.setState((prevState) => {
      return {
        ...prevState,
        rootNodes,
        childToParentMap,
      };
    });
  }

  public override componentDidUpdate(prevProps: Props) {
    // If selectedNode is different from prev selected node, update the expandedNodes by traversing recursively using the map.
    if (this.props.selectedNode !== prevProps.selectedNode) {
      this.expandToTop(this.props.selectedNode);
    }

  }

  // The next Props and the current State. This lifecycle method is called before render().
  // The returned object is a partial State, where any pair within will be updated.
  public static getDerivedStateFromProps(props: Props, state: State) {

    const nextFocusedNode = props.selectedNode;
    // Schema Filter Button was clicked
    if (state.excludingSchemas.length !== props.excludeSchemas.length) {
      return {
        focusedNode: nextFocusedNode,
        excludingSchemas: props.excludeSchemas,
        schemaFilterToggled: true,
      };
    }

    if (state.focusedNode !== nextFocusedNode) {
      return { focusedNode: nextFocusedNode, schemaFilterToggled: false };
    }

    return null;
  }

  private _selectNode = (nodeId: string, _autoExpanded?: boolean) => {
    if (this.props.selectedNode === nodeId)
      return;

    const isRoot = navTreeRootNodes.find((node) => node.id === nodeId);
    if (isRoot !== undefined) {
      this.props.onSelectionChanged(nodeId, OtherNodeType.root);
    } else {
      const node = ImseFrontend.instance.getElementLinkData(nodeId);
      this.props.onSelectionChanged(node.id, node.elementType);
    }
  };

  private _expandNode = (nodeId: string, autoExpanded?: boolean) => {
    // Resets the location source to 'NavTree' so that
    // components work properly with internal (to tree)
    // node selection and scrolling.

    // A collapse action was triggered
    if (!autoExpanded) {
      this._collapseNode(nodeId);
      return;
    }
    if (this.props.currentLocationSource !== "NavTree" && !autoExpanded)
      this.props.updateNavigationSource();

    this.props.expandTreeNode(nodeId);
  };

  private _collapseNode = (nodeId: string) => {
    // Resets the location source to 'NavTree' so that
    // components work properly with internal (to tree)
    // node selection and scrolling.
    if (this.props.currentLocationSource !== "NavTree")
      this.props.updateNavigationSource();

    this.props.collapseTreeNode(nodeId);
  };

  private _isNodeSelected(nodeId?: string): boolean {
    if (!this.state.focusedNode)
      return false;

    return  nodeId?.toLowerCase() === this.state.focusedNode.toLowerCase() ? true : false;
  }

  public static getChildNodes(excludeSchemas: ReadonlyArray<string>, nodeId: string): NavTreeNode[] {
    let filteredNodes: NavTreeNode[];
    const nodes = ImseFrontend.instance.getChildNodesByType(parseStringToElementType(nodeId)!, Array.from(excludeSchemas));
    if (/(Unit|Phenomenon)Def/.test(nodeId)) // NICE TO HAVE: add more branches to filter into letter branches here   e.g. /(Unit|Phenomenon)Def/
      filteredNodes = NavTreeComponent.groupNodesByLetter(nodes);
    else
      filteredNodes = nodes;
    return filteredNodes;
  }

  // Renders the wrapping div and root branch
  public override render() {
    return (
      <div id="nav-tree" className="nav-tree">
        <Tree<NavTreeNode>
          data={this.state.rootNodes}
          getNode={(node: NavTreeNode): NodeData<NavTreeNode> => {
            const isSelected = this._isNodeSelected(node.id);
            const isExpanded = this.props.expandedNodes.indexOf(node.id) >= 0;
            const isDisabled = (node.schemaId && !node.isSchema) ? this.state.excludingSchemas.includes(node.schemaId) : false;
            const nodeData = {
              subNodes: node.children,
              nodeId: node.id,
              node,
              isExpanded,
              isSelected,
              isDisabled,
              hasSubNodes: node.children !== undefined ? node.children.length > 0 : false,
            };
            return nodeData;
          }}
          // enableVirtualization
          nodeRenderer={(node: NodeRenderProps<NavTreeNode>) => {
            if (node.isDisabled) { // We don't show the tree node when the node's schema is filtered.
              return (<></>);
            }
            return (
              <TreeNode className="wimse-tree-node"
                nodeId={node.node.id}
                label={<NodeLabel
                  isSelected={node.isSelected && !this.state.schemaFilterToggled}
                  text={node.node.text}
                  isSchema={node.node.isSchema}
                  schemaId={node.node.schemaId}
                  schemaAlias={node.node.schemaAlias}
                  elementType={node.node.elementType}
                  modifier={node.node.modifier}
                />}
                onExpanded={this._expandNode}
                onSelected={this._selectNode}
                isDisabled={node.isDisabled}
                isExpanded={node.isExpanded}
                isSelected={node.isSelected}
                hasSubNodes={node.node.children ? true : false}
                icon={
                  node.node.elementType! === OtherNodeType.root ? <SmallElementTypeIcon elementType={parseStringToElementType(node.node.id)} /> :
                    node.node.isSchema ? <SchemaTag schemaName={node.node.schemaId!} schemaAlias={node.node.schemaAlias!} isSchema={true}/> : undefined
                }
                checkbox={node.node.isSchema ? <SchemaFilterButton schemaName={node.node.schemaId!}/> : undefined}
              />);
          }}
        />
      </div>
    );
  }
}

export const NavTree = connect(mapState, mapDispatch)(NavTreeComponent);