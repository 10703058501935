import * as React from "react";
import "./Welcome.scss";
import { SvgSmileySad } from "@itwin/itwinui-icons-react";
interface WelcomeProps {
  message: string;
  status?: string;
}
export class Welcome extends React.Component<WelcomeProps> {
  public override render() {
    const renderSprite = () => {
      if (this.props.status) {
        if (this.props.status === "error") {
          return <SvgSmileySad className="wimse-icon" />;
        }
      }
      return; // Default is no icon at all.
    };

    return (
      <div className="welcome">
        <div className="message">
          {renderSprite()}
          <span className="text">{this.props.message}</span>
        </div>
      </div>
    );
  }
}
