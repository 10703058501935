/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { ConfigManager } from "frontend/config/ConfigManager";

export interface EventDetails {
  eventName: string;
  properties?: any;
}
export class AppInsightsClient {
  private _prevError?: string;

  public get prevError(): string | undefined {
    return this._prevError;
  }
  /**
   * Get properties from a UserInfo object for MS Azure AppInsights custom events.
   * @param userInfo UserInfo object used to parse data.
   * @returns An object containing custom attributes.
   */
  public async getInsightProperties(): Promise<any> {
    const properties = {
      itwinId: ConfigManager.itwinId,
      iModelId: ConfigManager.iModelId,
    };
    // If user signed in, provide further details.
    if (ConfigManager.shouldSignIn()) {
      // Dynamically loaded for performance reasons
      const { RpcInterface } = await import("./RpcInterface");
      const userInfo = RpcInterface.authenticationClient.introspectJwtToken();
      if (userInfo && userInfo.ultimate_site) {
        if (userInfo.ultimate_site) {
          return { ...properties, userId: userInfo.sub, ultimateSite: userInfo.ultimate_site, usage_country: userInfo.usage_country_iso };
        }
        return { ...properties, userId: userInfo.sub };
      }
    }

    return properties;
  }
  public async trackPage(pageName: string) {
    const client = await this.LoadAppInsights();
    client.trackPageView({
      name: pageName,
      uri: window.location.href,
    });
  }

  public async trackEvent(eventName: string, properties?: any) {
    const client = await this.LoadAppInsights();
    client.trackEvent({
      name: eventName,
      properties,
    });
    // Calling flush here sends the event to AppInsights immediately instead of it waiting.
    client.flush();
  }

  /**
   * Shows a custom event used for error detection in React component rendering.
   * @param properties an optional object, would help provide more insight into what went wrong.
   */
  public async trackError(properties?: any) {
    await this.trackEvent("Error detected in UI rendering.", properties);
  }

  public async handleRpcException(error: Error, properties?: any, severity?: number) {
    if (await this.handleExceptionOnErrorName(error, properties, severity)) {
      await this.trackEvent("Rpc call failed.");
    }
  }

  /**
   * Handles the error based on its error name. Otherwise, handle the error based on error message.
   * @returns A boolean used by rpc exception to determine if the error should be ignored or count into App Insights automatic
   * error detection.
  */
  public async handleExceptionOnErrorName(error: Error, properties?: any, severity?: number): Promise<boolean> {
    switch (error.name.toLowerCase()) {
      case "imodelhub.userdoesnothavepermission":
        await this.trackEvent("User does not have permission to access changeset or iModel.", await this.getInsightProperties());
        this._prevError = "You don't have access to this iModel.";
        return false;
      // An IModelHubStatus.InvalidArgumentError
      case "invalid argument":
        {
          const message = `Invalid ${error.message.split(" ")[1]} provided.`;
          await this.trackEvent(message, await this.getInsightProperties());
          this._prevError = message;
        }
        return false;
      case "server error (400)":
        await this.trackEvent("User does not have permission to access context/project.", await this.getInsightProperties());
        this._prevError = "You don't have access to this context/project.";
        return false;
      default:
        await this.handleExceptionOnErrorMsg(error, properties, severity);
        return true;
    }
  }

  /**
   * Handles the error based on its error message.
   * We also emit a custom event to show ImseLocation or details at that time.
   */
  public async handleExceptionOnErrorMsg(error: Error, properties?: any, severity?: number) {
    switch (error.message) {
      case "login_required": // TODO: remove this case once move to imodeljs oidc-client is done.
        break;
      default:
        await this.trackEvent("Exception detected.", properties);
        const client = await this.LoadAppInsights();
        client.trackException({
          exception: error,
          severityLevel: severity,
        });
    }
  }

  private async LoadAppInsights() {
    const { ApplicationInsights } = await import("@microsoft/applicationinsights-web");
    const client = new ApplicationInsights({
      config: {
        instrumentationKey: ConfigManager.appInsightsInstrumentationKey,
        enableAutoRouteTracking: true,
        loggingLevelConsole: 2,
        disableFetchTracking: false,
        enableUnhandledPromiseRejectionTracking: true,
      },
    });
    client.loadAppInsights();
    return client;
  }

}
