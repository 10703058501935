/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2017 Bentley Systems, Incorporated. All rights reserved. $
*--------------------------------------------------------------------------------------------*/

const expressionRgx = /^(([A-Z]\w*:)?([A-Z]\w*|\[([A-Z]\w*:)?[A-Z]\w*\])(\(-?\d+\))?(\*(?!$)|$))+$/i;
const tokenRgx = /(?:(\[)?((?:[A-Z]\w*:)?[A-Z]\w*)\]?)(?:\((-?\d+)\))?/i;
const sp = "*";

enum Tokens {
  Bracket = 1,
  Word = 2,
  Exponent = 3,
}

export type Definition = Array<{
  name: string;
  exponent: number;
  constant: boolean;
}>;

export function parseDefinition(definition: string): Definition {
  const tokenizedDefinition: Definition = [];

  if (expressionRgx.test(definition)) {
    for (const unit of definition.split(sp)) {
      const tokens = unit.split(tokenRgx);
      const name = tokens[Tokens.Word];
      const exponent = (tokens[Tokens.Exponent]) ? Number(tokens[Tokens.Exponent]) : 1;
      const constant = tokens[Tokens.Bracket] !== undefined;

      tokenizedDefinition.push({ name, exponent, constant });
    }

    return tokenizedDefinition;
  } else
    throw new Error("Invalid definition expression.");
}
