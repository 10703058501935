import "./ErrorView.css";
import * as React from "react";

export class ErrorView extends React.Component<{error: Error}, {}> {

  public override render() {
    return (
      <div className="error-panel">
        <h2>Something went wrong.</h2>
        {this.props.error.message}
        <details style={{ whiteSpace: "pre-wrap" }}>
          {this.props.error.stack}
        </details>
      </div>
    );
  }
}
