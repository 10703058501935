/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
export const EXPAND_TREE_NODE = "EXPAND_TREE_NODE";
export const COLLAPSE_TREE_NODE = "COLLAPSE_TREE_NODE";

export const CHANGE_NAV_TREE_SPLITTER = "CHANGE_NAV_TREE_SPLITTER";
export const CHANGE_ELEMENT_DETAILS_SPLITTER = "CHANGE_ELEMENT_DETAILS_SPLITTER";

export const TOGGLE_STANDARD_SCHEMAS = "TOGGLE_STANDARD_SCHEMAS";
export const RESTORE_DEFAULTS = "RESTORE_DEFAULTS";
export const TOGGLE_EXCLUDE_SCHEMA = "TOGGLE_EXCLUDE_SCHEMA";

export const UPDATE_LOADED_SCHEMAS = "UPDATE_LOADED_SCHEMAS";
export const START_IMPORT = "START_IMPORT";
export const STOP_IMPORT = "STOP_IMPORT";

export const OPEN_FRONTSTAGE = "OPEN_FRONTSTAGE";
export const OPEN_MODAL_FRONTSTAGE = "OPEN_MODAL_FRONTSTAGE";
export const CLOSE_MODAL_FRONTSTAGE = "CLOSE_MODAL_FRONTSTAGE";
export const NAVIGATE_TO = "NAVIGATE_TO";
export const NAVIGATE_TO_ELEMENT = "NAVIGATE_TO_ELEMENT";
export const NAVIGATE_BACK = "NAVIGATE_BACK";
export const NAVIGATE_BACK_N_TIMES = "NAVIGATE_BACK_N_TIMES";
export const NAVIGATE_FORWARD = "NAVIGATE_FORWARD";
export const NAVIGATE_FORWARD_N_TIMES = "NAVIGATE_FORWARD_N_TIMES";

export const CLEAR_HISTORY = "CLEAR_HISTORY";
export const UPDATE_NAV_SOURCE = "UPDATE_NAV_SOURCE";
export const UPDATE_SCHEMA_TAB_VIEW = "UPDATE_SCHEMA_TAB_VIEW";

export const STORE_DIAGNOSTIC = "STORE_DIAGNOSTIC";
export const CLEAR_DIAGNOSTICS = "CLEAR_DIAGNOSTICS";

export const STORE_IMODEL_CONTEXT = "STORE_IMODEL_CONTEXT";
