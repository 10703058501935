/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { createAction, createReducer, combineReducers } from "@reduxjs/toolkit";
import { RESTORE_DEFAULTS, TOGGLE_EXCLUDE_SCHEMA, TOGGLE_STANDARD_SCHEMAS } from "frontend/state/actionTypes";
import { isStandardSchema, STANDARD_SCHEMAS } from "frontend/api/StandardSchemas";
// tslint:disable:variable-name

export const Actions = {
  toggleStandardSchemas: createAction(TOGGLE_STANDARD_SCHEMAS),
  restoreDefaults: createAction(RESTORE_DEFAULTS),
  toggleExcludeSchema: createAction(TOGGLE_EXCLUDE_SCHEMA, (schema: string) => { return { payload: schema }}),
};



// Array.prototype.filter callback fn -- return unique strings within array
const filterUnique = (value: string, index: number, self: string[]) => self.indexOf(value) === index;

// initial settings state
const initState = { showStandardSchemas: false, excludeSchemas: STANDARD_SCHEMAS };

const schemaFilters = createReducer(initState, (builder) => {
  builder.addCase(Actions.restoreDefaults, () => initState)
  builder.addCase(Actions.toggleStandardSchemas, (state) => {
    const showStandardSchemas = !state.showStandardSchemas;
    let excludeSchemas = [];
    if (showStandardSchemas) // if next value is to show, remove standard schemas
      excludeSchemas = state.excludeSchemas.filter((schema) => !isStandardSchema(schema));
    else
      excludeSchemas = state.excludeSchemas.concat(STANDARD_SCHEMAS);

    return { showStandardSchemas, excludeSchemas: excludeSchemas.filter(filterUnique) };
  });
  builder.addCase(Actions.toggleExcludeSchema, (state, action) => {
    const schema = action.payload;
    const excluded = state.excludeSchemas.indexOf(schema) !== -1;
    const excludeSchemas = (excluded) ? state.excludeSchemas.filter((s) => s !== schema) : state.excludeSchemas.concat(schema);

    return { showStandardSchemas: state.showStandardSchemas, excludeSchemas };
  });
});

export const reducer = combineReducers({
  schemaFilters,
});
