/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { FuzzySearchResult } from "frontend/api/FuzzySearch";

export class SearchResultText extends React.Component<{ data: FuzzySearchResult<any> }> {
  private *getHightlightedText(): Iterable<React.ReactNode> {
    if (!this.props.data.getMatchedValue())
      return undefined;

    const text = this.props.data.getMatchedValue();
    const mask = this.props.data.getBoldMask();
    let buffer = "";
    let isBold = mask[0];

    for (let i = 0; i < text.length; i++) {
      if (mask[i] !== isBold) {
        yield (isBold) ? (<b key={i}>{buffer}</b>) : buffer;
        isBold = mask[i];
        buffer = "";
      }
      buffer += text.charAt(i);
    }

    yield (isBold) ? (<b key={text.length}>{buffer}</b>) : buffer;
  }

  public override render() {
    return (
      <>
        {[...this.getHightlightedText()]}
      </>
    );
  }
}

interface SearchResultListItemProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onHover?: React.PointerEventHandler;
  onClick?: React.PointerEventHandler;
  isSelected?: boolean;
}

export class SearchResultListItem extends React.Component<SearchResultListItemProps> {
  public override render() {
    const className = `imse-search-result${(this.props.isSelected) ? " selected" : ""}`;
    return (
      <div className={className} style={this.props.style} onPointerEnter={this.props.onHover} onPointerDown={this.props.onClick}>
        {this.props.children}
      </div>
    );
  }
}

export interface SearchResultProps<T> extends SearchResultListItemProps {
  data: FuzzySearchResult<T>;
}

export type SearchResultComponent<T> = React.ComponentClass<SearchResultProps<T>>;
