/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { SchemaTag } from "frontend/components/controls/SchemaTag";
import { ElementLinkNoTag, ElementLinkNoTagProps } from "frontend/components/controls/ElementLinkNoTag";

type Props = ElementLinkNoTagProps;

export class ElementLinkComponent extends React.Component<Props> {

  public override render() {
    return (
      <ElementLinkNoTag {...this.props}>
        <SchemaTag
          schemaName={this.props.schemaName}
          schemaAlias={this.props.schemaAlias}
          isSchema={this.props.isSchema}
        />
      </ElementLinkNoTag>
    );
  }
}

export type ElementLinkProps = Props;

export const ElementLink = ElementLinkComponent;
