/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { SchemaItemType, StrengthDirection } from "@itwin/ecschema-metadata";
import { ElementAttributes, RelationshipClassInfo } from "frontend/api/Interfaces";
import { UmlRelationshipSummary } from "frontend/components/controls/uml/UmlRelationshipSummary";
import { RelationshipExplanation } from "frontend/components/controls/uml/RelationshipExplanation";
import { UsesCard } from "frontend/components/stages/classbrowser/cards/UsesCard";
import "../cards/CardContainer.scss";
import "./RelationshipClassDetails.css";
import { ImseFrontend } from "frontend/api/ImseFrontend";

type Props = ElementAttributes;

interface State {
  data?: RelationshipClassInfo;
  expanded: boolean;
}

export class RelationshipClassDetails extends React.Component<Props, State> {
  private static _dataProvider = async (id: string) => ImseFrontend.instance.getPropertiesThatUseRelationship(id);
  public override readonly state: State = { expanded: true };
  public initialized?: Promise<void>;

  private async fetchData() {
    const data = ImseFrontend.instance.getSchemaItemAttributes(this.props.id);
    if (data && SchemaItemType.RelationshipClass === data.elementType)
      this.setState({data});
  }

  public override componentDidMount() {
    this.initialized = this.fetchData();
  }

  public override componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id)
      this.initialized = this.fetchData();
  }

  public override render() {
    if (!this.state.data)
      return (<span />);

    const shouldExpand = !this.state.expanded;
    return (
      <div className="details-page">
        <div className="element-details">
          <UmlRelationshipSummary
            data={this.state.data}
            expanded={this.state.expanded}
            onToggleExpand={() => this.setState({expanded: shouldExpand})} />

          <div className="explanations">
            <RelationshipExplanation
              data={this.state.data}
              direction={StrengthDirection.Forward} />

            <RelationshipExplanation
              data={this.state.data}
              direction={StrengthDirection.Backward} />
          </div>
        </div>
        <div className="card-container">
          <UsesCard title="Used by:" dataProvider={RelationshipClassDetails._dataProvider} id={this.props.id} />
        </div>
      </div>
    );
  }
}
