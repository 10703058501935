/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";

interface Props {
  ruleCode: string;
}

const baseUrl = "https://www.itwinjs.org/bis/";

export class RuleCodeLinkComponent extends React.Component<Props> {
  private getDocUrl() {
    // return baseUrl + "/" + this.props.ruleCode;
    return baseUrl;
  }

  public override render() {
    return (
      <span className={"rulecode-link"}>
        <a href={this.getDocUrl()} target="_blank" rel="noopener noreferrer">{this.props.ruleCode}</a>
      </span>
    );
  }
}

export type RuleCodeLinkProps = Props;

export const RuleCodeLink = RuleCodeLinkComponent;
