/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from "react";
import { SmallElementTypeIcon } from "../ElementIcons";
import { parseStringToElementType } from "frontend/state/reducers/location";
import { NavTreeNode as ImseNavNodeData } from "frontend/api/Interfaces";
import { TreeNode } from "@itwin/itwinui-react";
type TreeNodeProps = Omit<React.ComponentPropsWithoutRef<typeof TreeNode>, "as">;

type NavNodeData = ImseNavNodeData & TreeNodeProps;

const roots: Array<Partial<NavNodeData>> = [
  { text: "Schemas", id: "schema" },
  { text: "Entities", id: "entityclass" },
  { text: "Mixins", id: "mixin" },
  { text: "Structs", id: "structclass" },
  { text: "Custom Attributes", id: "customattributeclass" },
  { text: "Relationships", id: "relationshipclass" },
  { text: "Enumerations", id: "enumeration" },
  { text: "Units", id: "unit" },
  { text: "Constants", id: "constant" },
  { text: "Phenomena", id: "phenomenon" },
  { text: "Unit Systems", id: "unitsystem" },
  { text: "Formats", id: "format" },
  { text: "Kind of Quantities", id: "kindofquantity" },
  { text: "Property Categories", id: "propertycategory" },
];
roots.forEach((node) => {
  if (node.id) {
    node.icon = <SmallElementTypeIcon elementType={parseStringToElementType(node.id)} />;
    node.children = [];
  }

});

export const navTreeRootNodes = roots as NavNodeData[];
