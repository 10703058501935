/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { SettingsItem } from "./SettingsItem";
import "./SettingsStage.css";
import { connect } from "react-redux";
import { Actions as SettingsActions } from "frontend/state/reducers/settings";
import { RootState } from "frontend/state/rootReducer";

interface Props {
  // All of these are connected to redux:
  showStandardSchemas: boolean;
  toggleStandardSchemas(): any;
  restoreDefaults(): any;
}

const mapState = (state: RootState) => ({
  showStandardSchemas: state.settings.schemaFilters.showStandardSchemas,
});

const mapDispatch = {
  toggleStandardSchemas: SettingsActions.toggleStandardSchemas,
  restoreDefaults: SettingsActions.restoreDefaults,
};

export class SettingsStageComponent extends React.Component<Props> {
  public override render() {
    return (
      <div className="settings-panel">
        <SettingsItem title="Show Standard Schemas" onClick={() => this.props.toggleStandardSchemas()} toggleState={this.props.showStandardSchemas}/>
        <span className="restore-button"><button className="iui-buttons-blue" onClick={() => this.props.restoreDefaults()}>Restore Defaults</button></span>
      </div>
    );
  }
}

export const SettingsStage = connect(mapState, mapDispatch)(SettingsStageComponent);
