/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { ElementAttributes, KindOfQuantityAttributes } from "frontend/api/Interfaces";
import { ElementLink } from "frontend/components/controls/ElementLink";
import { UsesCard } from "frontend/components/stages/classbrowser/cards/UsesCard";
import "../cards/CardContainer.scss";
import "./KindOfQuantityDetails.css";

type Props = ElementAttributes;
type State = Partial<KindOfQuantityAttributes>;

export class KindOfQuantityDetails extends React.Component<Props, State> {
  private static _dataProvider = async (id: string) => ImseFrontend.instance.getPropertiesThatUseKoq(id);

  private static _defaultState = {
    persistenceUnit: undefined,
    presentationUnits: undefined,
  };

  public override readonly state: State = KindOfQuantityDetails._defaultState;
  public initialized?: Promise<any>;

  private async fetchData() {
    const data = ImseFrontend.instance.getSchemaItemAttributes(this.props.id) as KindOfQuantityAttributes;
    this.setState({...KindOfQuantityDetails._defaultState, ...data});
  }

  public override componentDidMount() {
    this.initialized = this.fetchData();
  }

  public override componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id)
      this.initialized = this.fetchData();
  }

  private renderPresentationUnits(): JSX.Element | undefined {
    if (!this.state.presentationUnits)
      return undefined;

    const units: JSX.Element[] = [];
    const getLabel = (lbl?: string) => {
      if (!lbl)
        return undefined;

      return <span className="label-override">{lbl} </span>;
    };

    for (const presentationUnit of this.state.presentationUnits) {
      const unitOverrides = presentationUnit.units || [];
      units.push(
        <li className="format-override" key={units.length}>
          <ElementLink {...presentationUnit.format} />
          {unitOverrides.map(([unit, label], idx) => <React.Fragment key={idx}> [ <ElementLink {...unit} />{getLabel(label)}]</React.Fragment>)}
        </li>,
      );
    }
    const listContainer = (<ul className="itemList">{units}</ul>);

    return listContainer;
  }

  public override render() {
    const presentationUnits = this.renderPresentationUnits();
    const persistenceUnit = this.state.persistenceUnit ? (<ElementLink {...this.state.persistenceUnit} />) : ("");

    return (
      <div className="details-page">
        <div className="element-attributes">
          <div className="item">
            <span className="label">Relative Error: </span><span className="value">{this.state.precision}</span>
          </div>
          <div className="item">
            <span className="label">Persistence Unit: </span><span className="value">{persistenceUnit}</span>
          </div>
          <div className="item">
            <span className="label">Presentation Units: </span>{presentationUnits}
          </div>
        </div>
        <div className="card-container">
          <UsesCard title="Used by:" dataProvider={KindOfQuantityDetails._dataProvider} id={this.props.id} />
        </div>
      </div>
    );
  }
}
