/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";

export class DiagramStage extends React.Component {

  public override render() {
    return (
      <div className="diagram">
        <span className="iui-text-headline">
          Diagram Stage - TODO
        </span>
      </div>
    );
  }
}
