import { Anchor, Table } from "@itwin/itwinui-react";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { useEffect, useMemo, useState } from "react";
import { SmallElementTypeIcon } from "../ElementIcons";
import { SchemaTag } from "../SchemaTag";
import { Actions as NavActions } from "frontend/state/reducers/location";
import { connect } from "react-redux";

const SchemaItemsTable = (props: any) => {
  const columns: any[] = useMemo(() => [
    {
      id: "name",
      accessor: "name",
      Header: "Item Name",
      width: 300,
      sticky: "left",
      Cell: ({ value }: { value: any }) => {
        const linkStyle = {color: "black", textDecoration: "underline", paddingLeft: "10px"};
        const onClick = () => {
          props.navigateTo("browse", value.elementType, value.id);
        };
        return (
          <div style={{display: "flex", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "clip"}}>
            <SmallElementTypeIcon elementType={value.elementType}/>
            <Anchor onClick={onClick} style={linkStyle}>
              {value.name}
            </Anchor>
            <SchemaTag
              schemaName={value.schemaName}
              schemaAlias={value.schemaAlias}
              isSchema={value.isSchema}
            />
          </div>
        );
      },
    },
    {
      id: "displayLabel",
      accessor: "displayLabel",
      Header: "Display Label",
      width: 300,
      Cell: ({ value }: { value: any }) => {
        return (
          <div style={{whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "clip"}}>
            {value}
          </div>
        );
      },
    },
    {
      id: "description",
      accessor: "description",
      Header: "Description",
      width: 600,
      Cell: ({ value }: { value: any }) => {
        return (
          <div style={{whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "clip",
            opacity: !value ? "40%" : "100%",
          }}>
            {value ? value : "Missing Description"}
          </div>
        );
      },
    },
    {
      id: "itemType",
      accessor: "itemType",
      Header: "Item Type",
      width: 473,
      Cell: ({ value }: { value: any }) => {
        return (
          <div style={{whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "clip",
          }}>
            {value}
          </div>
        );
      },
    },
  ], []);

  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await ImseFrontend.instance.getAllSchemaItems(props.id);
      if (!response) {
        return;
      }
      setData(response.map((item: any) => {
        const newRow: any = {};
        columns.forEach((column: any) => {
          const columnData: any = item[column.id];
          newRow[column.id] = columnData;
        });
        return newRow;
      }));
    };
    void fetchData();
  }, [props.id]);

  return (
    <div className="imsejs-table">
      <Table columns={columns}
        data={data}
        emptyTableContent='No data.'
        density="extra-condensed"
        isResizable
        columnResizeMode="expand"
        isSortable
        isSelectable
        selectionMode="single"
        autoResetPage={false}
        style={{
        }}
      />
    </div>
  );
};

// function _handleTableSort = (sortColumn: string, sortDirection: "ASC" | "DESC" | "NONE") => {
//   const comparer = (a: SchemaItemTableRowData, b: SchemaItemTableRowData) => {
//     const aValue = (a as any)[sortColumn];
//     const bValue = (b as any)[sortColumn];

//     return sortCompare(aValue, bValue, sortDirection);
//   };

//   const sortedRows = sortDirection === "NONE" ? state.originalRows.slice(0) : state.rows.sort(comparer);

//   setState({ rows: sortedRows });
// };

export function sortCompare(a: any, b: any, sortDirection: "ASC" | "DESC" | "NONE"): number {
  const aValue = typeof (a) === "string" ? a : a !== undefined ? a.name : "";
  const bValue = typeof (b) === "string" ? b : b !== undefined ? b.name : "";
  if (sortDirection === "ASC") {
    return aValue.localeCompare(bValue);
  } else if (sortDirection === "DESC") {
    return bValue.localeCompare(aValue);
  }
  return 0;
}

// Add redux actions here
const mapDispatchToProps = (dispatch: any) => {
  return {
    navigateTo: (stage: any, elementType: any, id: any) => {
      dispatch(NavActions.navigateTo(stage, elementType, id));
    },
  };
};

// Connect to the Redux store
export default connect(null, mapDispatchToProps)(SchemaItemsTable);
