import { ECObjectsError, ECObjectsStatus, ECVersion, ISchemaLocater, Schema, SchemaContext, SchemaInfo, SchemaKey, SchemaMatchType } from "@itwin/ecschema-metadata";
import { ConfigManager } from "frontend/config/ConfigManager";
import JSZip from "jszip";

export function initializeBisSchemaLocater(): BisSchemaLocater {
  return new BisSchemaLocater();
}

export class BisSchemaLocater implements ISchemaLocater {
  public loadedSchemas: Map<SchemaKey, any> = new Map();
  /**
   * Retrieves the zipped resource, decompresses it, and then moves.
   */
  public async loadSchemaResources() {
    const response = await fetch(ConfigManager.bisSchemaResource, {
      method: "GET",
      headers : {
        type: "application/zip",
      },
    });

    if (response.statusText !== "OK")
      throw new ECObjectsError(ECObjectsStatus.UnableToLocateSchema, `An error occurred retrieving bis-schema files: ${response.statusText}`);

    const buffer = await response.blob();
    const jszip = new JSZip();
    const zipped = await jszip.loadAsync(buffer);
    const promises: any[] = [];
    zipped.forEach((_filename, file) => {
      promises.push(file.async("text"));
    });
    await Promise.all(promises).then((files) => {
      for (const file of files) {
        if (file === "")
          continue;
        const schemaBody = JSON.parse(file);
        if (!(schemaBody.name))
          throw new ECObjectsError(ECObjectsStatus.InvalidECJson, `Could not retrieve the ECSchema name in the given file.`);
        // Check if versions is present
        if (!(schemaBody.version))
          throw new ECObjectsError(ECObjectsStatus.InvalidECJson, `Could not parse the ECSchema version in the given file.`);
        this.loadedSchemas.set(new SchemaKey(schemaBody.name, ECVersion.fromString(schemaBody.version)), schemaBody);
      }
    });
  }
  public async getSchemaKey(schemaKey: any): Promise<SchemaKey> {
    // Check if the name is present
    if (!(schemaKey.name))
      throw new ECObjectsError(ECObjectsStatus.InvalidECJson, `Could not retrieve the ECSchema name in the given file.`);
    // Check if versions is present
    if (!(schemaKey.version))
      throw new ECObjectsError(ECObjectsStatus.InvalidECJson, `Could not parse the ECSchema version in the given file.`);
    const schemaName = schemaKey.name;
    const schemaVersion = schemaKey.version;
    const key = new SchemaKey(schemaName, ECVersion.fromString(schemaVersion));
    return key;
  }

  public async getSchema<T extends Schema>(schemaKey: SchemaKey, matchType: SchemaMatchType, context: SchemaContext): Promise<T | undefined> {
    await this.getSchemaInfo(schemaKey, matchType, context);

    const schema = await context.getCachedSchema(schemaKey, matchType);
    return schema as T;
  }

  public getSchemaSync<T extends Schema>(): T | undefined {
    return undefined;
  }

  public async getSchemaInfo(schemaKey: Readonly<SchemaKey>, matchType: SchemaMatchType, context: SchemaContext): Promise<SchemaInfo | undefined> {
    let foundKey: SchemaKey | undefined;
    for (const key of this.loadedSchemas.keys()) {
      if (key.matches(schemaKey, matchType)) {
        foundKey = key;
        break;
      }
    }
    if (foundKey === undefined)
      return undefined;
    const schemaText = this.loadedSchemas.get(foundKey);

    if (!schemaText)
      return undefined;

    let schemaInfo;
    try {
      schemaInfo = await Schema.startLoadingFromJson(schemaText, context);
    } catch {
      schemaInfo = context.getSchema(schemaKey, matchType);  // This is unholy and wrong, but necessary until we get better schema json.
    }
    return schemaInfo;
  }
}
