/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { connect } from "react-redux";
import { Actions } from "frontend/state/reducers/location";
import { SchemaLinkData, SchemaType } from "frontend/api/Interfaces";
import { Tooltip } from "frontend/components/controls/Tooltip";
import { ElementTip } from "frontend/components/controls/ElementTip";
import { ISchemaColorSet, SchemaColorCoder } from "frontend/components/controls/SchemaColorCoder";
import { SchemaTag } from "frontend/components/controls/SchemaTag";
import "./SchemaLink.css";
import * as React from "react";

interface Props extends SchemaLinkData {
  navigate(id: string): any;
}

type State = Partial<ISchemaColorSet>;

const mapDispatch = {
  navigate: (id: string) => Actions.navigateTo("browse", SchemaType.schema , id, undefined, undefined, "items"),
};

export class SchemaLinkComponent extends React.Component<Props, State> {
  public override readonly state: State = {};
  public initialized?: Promise<any>;

  public async getLinkStyle(name: string) {
    const colors = SchemaColorCoder.getColorSetForSchema(name);
    this.setState({ ...colors });
  }

  public override componentDidMount() {
    this.initialized = this.getLinkStyle(this.props.name);
  }

  public override async componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id)
      this.initialized = this.getLinkStyle(this.props.name);
  }

  public handleClick = (e: React.MouseEvent<any>) => {
    this.props.navigate(this.props.id);
    e.preventDefault();
  };

  public override render() {
    const { id, name, description } = this.props;
    const tagProps = { schemaAlias: ".", schemaName: name };
    const elementTipProps = { id, name, description, elementType: SchemaType.schema, ...tagProps };

    const label = (
      <Tooltip content={<ElementTip {...elementTipProps} />} >
        <a href={`/browse/SchemaDef/${id}`} onClick={this.handleClick} className="schema-link-anchor">
          {name}
        </a>
      </Tooltip>
    );

    return (
      <div className="schema-link">
        <SchemaTag {...tagProps} isSchema={true} />
        {label}
      </div>
    );
  }
}

export const SchemaLink = connect(null, mapDispatch)(SchemaLinkComponent);
