/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import "./LoaderOverlay.css";

import { connect } from "react-redux";
import { RootState } from "frontend/state/rootReducer";

interface Props {
  isVisible: boolean; // Connected to redux
}

const mapState = (state: RootState) => ({
  isVisible: state.schemas.isImportRunning,
});

class LoaderOverlayComponent extends React.Component<Props> {
  public override render() {
    return (!this.props.isVisible) ? null : (
      <div className="loader-overlay">
        <div className="iui-loaders-large white"><i></i><i></i><i></i><i></i><i></i><i></i></div>
      </div>
    );
  }
}

export const LoaderOverlay = connect(mapState)(LoaderOverlayComponent);
