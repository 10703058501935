/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { FuzzySearch, FuzzySearchResult } from "frontend/api/FuzzySearch";
import { ElementSearchResultData } from "frontend/api/Interfaces";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { RootState } from "frontend/state/rootReducer";
import * as React from "react";
import { connect } from "react-redux";

export type ElementFuzzySearchResults = Array<FuzzySearchResult<ElementSearchResultData>>;

interface Props {
  searchInput: string;
  children(results: ElementFuzzySearchResults): React.ReactNode;
  loadedSchemas: ReadonlyArray<string>; // Connected to redux
  excludeSchemas: ReadonlyArray<string>; // connected to redux
}

interface State {
  searchIndex: ElementSearchResultData[];
  searchResults: any[];
}

const mapState = (state: RootState) => ({
  loadedSchemas: state.schemas.loadedSchemas,
  excludeSchemas: state.settings.schemaFilters.excludeSchemas,
});

class ElementFuzzySearchHandler extends FuzzySearch<ElementSearchResultData> {
  private addWeightedSearchOpts(opts: any) {
    opts.sortFn = (a: any, b: any) => {
      if (a.score === b.score) {
        if (a.item.pName && !b.item.pName)
          return 1;
        if (!a.item.pName && b.item.pName)
          return -1;
        return a.output[0].value.localeCompare(b.output[0].value);
      }
      return a.score - b.score;
    };
    return opts;
  }

  public override onGetMultiWordSearchOptions() {
    return this.addWeightedSearchOpts(super.onGetMultiWordSearchOptions());
  }
  public override onGetSingleWordSearchOptions() {
    return this.addWeightedSearchOpts(super.onGetSingleWordSearchOptions());
  }
}

export class ElementSearchProviderComponent extends React.Component<Props, State> {
  private static readonly _searchHandler = new ElementFuzzySearchHandler();
  public override readonly state: State = { searchIndex: [], searchResults: [] };
  public initialized?: Promise<void>;

  private getSearchResults(pattern: string): ElementFuzzySearchResults {
    return ElementSearchProviderComponent._searchHandler.search(this.state.searchIndex, ["name", "label"], pattern).results;
  }

  private async updateSearchIndex() {
    const excludeSchemas = this.props.excludeSchemas;
    const sIndex = await ImseFrontend.instance.getSearchIndex(excludeSchemas);
    this.setState((prevState) => {
      return {
        ...prevState,
        searchIndex: sIndex,
      };
    });
  }

  public override async componentDidUpdate(prevProps: Props) {
    if (this.props.loadedSchemas !== prevProps.loadedSchemas || this.props.excludeSchemas !== prevProps.excludeSchemas) {
      await this.updateSearchIndex();
    }
    if (this.props.searchInput !== prevProps.searchInput) {
      const results = this.getSearchResults(this.props.searchInput);
      this.setState((prevState) => {
        return {
          ...prevState,
          searchResults: results,
        };
      });
    }
  }

  public override componentDidMount() {
    this.initialized = this.updateSearchIndex();
  }

  public override render() {
    return (
      <>
        {this.props.children(this.state.searchResults)}
      </>
    );
  }
}

export const ElementSearchProvider = connect(mapState)(ElementSearchProviderComponent);
