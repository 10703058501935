/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { AnyElementType, ElementAttributes } from "frontend/api/Interfaces";
import "./ElementTitleBlock.css";
import { ECClassModifier } from "@itwin/ecschema-metadata";
import { ElementDescription } from "frontend/components/ElementDescription";
import { ElementTypeIcon } from "../../controls/ElementIcons";
import * as React from "react";

interface Props extends ElementAttributes {
  children?: React.ReactNode;
  elementType: AnyElementType;
}

export class ElementTitleBlock extends React.Component<Props> {

  private modifierToString(modifier?: number | null) {
    if (modifier === undefined || modifier === null)
      return "";

    switch (modifier) {
      case 0: return "Concrete";
      case 1: return "Abstract";
      case 2: return "Sealed";
      default:
        return "";
    }
  }

  public override render() {
    const schemaName = (this.props.schemaName) ? `${this.props.schemaName}.` : "";
    const displayLabel = (this.props.displayLabel) ? ` (${this.props.displayLabel})` : "";

    const nameStyle: React.CSSProperties | undefined = (this.props.modifier === ECClassModifier.Abstract) ? { fontStyle: "italic" } : undefined;

    return (
      <div className="element-title-block">
        <div className="class-icon">
          <ElementTypeIcon elementType={this.props.elementType} />
          <span className="modifier">{this.modifierToString(this.props.modifier)}</span>
        </div>
        <div className="text">
          <span className="iui-text-title">
            <span className="schema">{schemaName}</span>
            <span className="name" style={nameStyle}>{this.props.name}&nbsp;</span>
            <span className="displayLabel">{displayLabel}</span>
          </span>
          {<ElementDescription description={this.props.description} />}
        </div>
      </div>
    );
  }
}
