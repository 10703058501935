/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { ImseLocation, Actions as NavActions } from "frontend/state/reducers/location";
import { connect } from "react-redux";
import { RootState } from "frontend/state/rootReducer";
import * as React from "react";
import "./NavigationWidget.scss";
import { SvgCaretDownSmall, SvgProgressBackward, SvgProgressForward } from "@itwin/itwinui-icons-react";
import { DropdownMenu, IconButton, MenuItem } from "@itwin/itwinui-react";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { SchemaTag } from "../controls/SchemaTag";
import { ElementLinkData, SchemaType } from "frontend/api/Interfaces";
import { SmallElementTypeIcon } from "../controls/ElementIcons";

interface Props {
  onGoBack(): any; // Connected to redux
  onGoForward(): any; // Connected to redux
  onGoBackNTimes(n: number): any; // Connected to redux
  onGoForwardNTimes(n: number): any; // Connected to redux
  canGoBackward: boolean; // Connected to redux
  canGoForward: boolean; // Connected to redux
  locationPast: readonly ImseLocation[];
  locationFuture: readonly ImseLocation[];
}

const mapState = (state: RootState) => ({
  location: state.location,
  canGoBackward: (state.location.past.length > 0),
  canGoForward: (state.location.future.length > 0),
  locationPast: state.location.past,
  locationFuture: state.location.future,
});

const mapDispatch = {
  onGoBack: NavActions.navigateBack,
  onGoForward: NavActions.navigateForward,
  onGoBackNTimes: NavActions.navigateBackNTimes,
  onGoForwardNTimes: NavActions.navigateForwardNTimes,
};

export class NavigationWidgetComponent extends React.Component<Props> {

  public override render() {
    const disabledStyle: React.CSSProperties = {
      color: "rgba(0, 0, 0, 0.23)",
    };

    const onMenuBackClick = (n: number, close: () => void) => () => {
      this.props.onGoBackNTimes(n);
      close();
    };

    const onMenuForwardClick = (n: number, close: () => void) => () => {
      this.props.onGoForwardNTimes(n);
      close();
    };

    const getSchemaTag = (item: ElementLinkData) => {
      if (item.elementType === SchemaType.schema) {
        return (
          <SchemaTag
            schemaAlias={item.schemaAlias}
            schemaName={item.id}
            isSchema={true} />
        );
      }
      return (
        <SchemaTag
          schemaAlias={item.schemaAlias}
          schemaName={item.schemaName}
          isSchema={false} />
      );
    };

    const getLabel = (item: ElementLinkData) => {
      if (item.elementType === SchemaType.schema) {
        return (
          <span className="display-label">
            {item.id}
          </span>
        );
      }
      return (
        <span className="display-label">
          {item.schemaName}.
          {item.name}
        </span>
      );
    };
    const bwMenuItems = (close: () => void) => {
      let n = this.props.locationPast.length;
      return this.props.locationPast.map((location: ImseLocation) => {
        const key = n;
        n -= 1;
        if (!location.id) {
          return (<></>);
        }
        const item = ImseFrontend.instance.getElementLinkData(location.id);
        return (
          <MenuItem className="dropdown-item" key={key} onClick={onMenuBackClick(key, close)}>
            <SmallElementTypeIcon elementType={item.elementType} />
            {getLabel(item)}
            {getSchemaTag(item)}
          </MenuItem>
        );
      }).reverse();
    };
    const fwMenuItems = (close: () => void) => {
      let n = this.props.locationFuture.length;
      return this.props.locationFuture.map((location: ImseLocation) => {
        const key = n;
        n -= 1;
        if (!location.id) {
          return (<></>);
        }
        const item = ImseFrontend.instance.getElementLinkData(location.id);
        return (
          <MenuItem className="dropdown-item" key={key} onClick={onMenuForwardClick(key, close)}>
            <SmallElementTypeIcon elementType={item.elementType} />
            {getLabel(item)}
            {getSchemaTag(item)}
          </MenuItem>
        );
      }).reverse();
    };
    const bwStyle = !this.props.canGoBackward ? disabledStyle : undefined;
    const fwStyle = !this.props.canGoForward ? disabledStyle : undefined;
    const bwOnClick = bwStyle ? () => { return; } : this.props.onGoBack;
    const fwOnClick = fwStyle ? () => { return; } : this.props.onGoForward;
    return (
      <div className="wimse-button-container">
        <span className="button-split">
          <IconButton styleType="borderless" className="borderless-button" disabled={!this.props.canGoBackward} onClick={bwOnClick}>
            <SvgProgressBackward />
          </IconButton>
          <DropdownMenu placement="bottom-end" disabled={!this.props.canGoBackward} menuItems={bwMenuItems}>
            <IconButton styleType="borderless" className="borderless-button" disabled={!this.props.canGoBackward}>
              <SvgCaretDownSmall />
            </IconButton>
          </DropdownMenu>
        </span>
        <span className="button-split">
          <DropdownMenu placement="bottom-end" disabled={!this.props.canGoForward} menuItems={fwMenuItems}>
            <IconButton styleType="borderless" className="borderless-button" disabled={!this.props.canGoForward}>
              <SvgCaretDownSmall />
            </IconButton>
          </DropdownMenu>
          <IconButton styleType="borderless" className="borderless-button" disabled={!this.props.canGoForward} onClick={fwOnClick}>
            <SvgProgressForward />
          </IconButton>
        </span>
      </div>
    );
  }
}

export const NavigationWidget = connect(mapState, mapDispatch)(NavigationWidgetComponent);
