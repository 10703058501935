/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { CustomAttributeContainerType, ECClassModifier, PrimitiveType, PropertyType, RelationshipEnd, SchemaItemType, StrengthDirection, StrengthType } from "@itwin/ecschema-metadata";
import { FormatType, ScientificType, ShowSignOption  } from "@itwin/core-quantity";
import { Id64String } from "@itwin/core-bentley";
export interface ElementLinkData {
  id: string;
  name: string;
  description: string;
  schemaAlias: string;
  schemaName: string;
  elementType: AnyElementType;
  label?: string;
  modifier?: ECClassModifier;
  propertyName?: string;
}
export enum SchemaType {
  none = 14,
  schema = 15,
}

export enum OtherNodeType {
  root = 16,
}

export type AnyElementType = OtherNodeType | SchemaType | SchemaItemType | PropertyType;
export interface SchemaNameAndVersion {
  name: string;
  version: string;
}

export interface PropertyLinkData extends ElementLinkData {
  propertyName?: string;
  propertyType?: PropertyType;
}

export interface SchemaLinkData {
  id: string;
  name: string;
  description: string;
}

export interface NavPropertyLinkData {
  roleLabel: ElementLinkData;
  endpoint: ElementLinkData;
}

export interface PresentationUnitLinkData {
  format: ElementLinkData;
  units?: Array<[ElementLinkData, string | undefined]>;
}

export type ValidationLinkData = ElementLinkData | SchemaLinkData | PropertyLinkData;

export interface NavTreeNode {
  id: string;
  text: string;
  children?: NavTreeNode[];
  schemaAlias?: string;
  schemaId?: string;
  isSchema?: boolean;
  modifier?: ECClassModifier;
  elementType?: AnyElementType; // should only be used if needed to distinguish type from node parent type (i.e. InvertedUnit)
}

export interface SchemaDefData {
  alias: string;
  version: string;
}

export interface ElementAttributes {
  id: string;
  description: string;
  displayLabel: string;
  name: string;
  schemaName: string;
  // NB: Using null here allows us to explicitly set that a modifier should not be shown.
  // If we just used undefined, React would assume the property should not _change_.
  modifier: ECClassModifier | null;
}

export interface EntityClassAttributes extends ElementAttributes {
  elementType: SchemaItemType.EntityClass;
}

export interface KindOfQuantityAttributes extends ElementAttributes {
  elementType: SchemaItemType.KindOfQuantity;
  persistenceUnit?: ElementLinkData;
  presentationUnits?: PresentationUnitLinkData[];
  precision: number;
}

export interface EnumerationAttributes extends ElementAttributes {
  elementType: SchemaItemType.Enumeration;
  primitiveType: PrimitiveType;
  isStrict: boolean;
}

export interface MixinAttributes extends ElementAttributes {
  elementType: SchemaItemType.Mixin;
  appliesTo: ElementLinkData;
}

export interface CustomAttributeAttributes extends ElementAttributes {
  elementType: SchemaItemType.CustomAttributeClass;
  containerType: CustomAttributeContainerType;
}

export interface PropertyCategoryAttributes extends ElementAttributes {
  elementType: SchemaItemType.PropertyCategory;
  priority: number;
}

export interface StructAttributes extends ElementAttributes {
  elementType: SchemaItemType.StructClass;
}

export interface RelationshipAttributes extends ElementAttributes, RelationshipClassInfo {
  elementType: SchemaItemType.RelationshipClass;
}

export interface PhenomenonAttributes extends ElementAttributes {
  elementType: SchemaItemType.Phenomenon;
  definition: string;
}

export interface UnitSystemAttributes extends ElementAttributes {
  elementType: SchemaItemType.UnitSystem;
}

export interface UnitAttributes extends ElementAttributes {
  elementType: SchemaItemType.Unit;
  definition: string;
  offset?: number;
  numerator?: number;
  denominator?: number;
  phenomenon?: ElementLinkData;
  unitSystem?: ElementLinkData;
}

export interface ConstantAttributes extends ElementAttributes {
  elementType: SchemaItemType.Constant;
  definition: string;
  numerator?: number;
  denominator?: number;
  phenomenon?: ElementLinkData;
}

export interface InvertedUnitAttributes extends ElementAttributes {
  elementType: SchemaItemType.InvertedUnit;
  unitSystem?: ElementLinkData;
  invertsUnit?: ElementLinkData;
}

export interface FormatAttributes extends ElementAttributes {
  elementType: SchemaItemType.Format;
  roundFactor: number;
  formatType: FormatType;
  precision: number;
  minWidth?: number;
  scientificType?: ScientificType;
  showSignOption: ShowSignOption;
  decimalSeparator: string;
  thousandSeparator: string;
  uomSeparator: string;
  stationSeparator: string;
  stationOffsetSize?: number;
  formatTraits: number;
  spacer?: string;
  includeZero?: boolean;
  units?: Array<[ElementLinkData, string | undefined]>;
}

export interface SchemaAttributes extends ElementAttributes, SchemaDefData {
}

export type SchemaItemAttributes = RelationshipAttributes | EntityClassAttributes | KindOfQuantityAttributes | EnumerationAttributes | MixinAttributes | CustomAttributeAttributes | PropertyCategoryAttributes | StructAttributes | UnitSystemAttributes | PhenomenonAttributes | UnitAttributes | InvertedUnitAttributes | ConstantAttributes | FormatAttributes;

export interface RelationshipClassInfo {
  relationshipStrength: StrengthType;
  relationshipDirection: StrengthDirection;
  source: RelationshipConstraintInfo;
  target: RelationshipConstraintInfo;
}

export interface RelationshipConstraintInfo {
  multiplicity: RelationshipMultiplicityInfo;
  roleLabel: string;
  isPolymorphic: boolean;
  abstractConstraint: ElementLinkData;
  classes: ElementLinkData[];
  id: string;
}

export interface RelationshipMultiplicityInfo {
  lowerLimit: number;
  upperLimit: number;
}

export interface PropertyTableRowData {
  name: {
    icon: PropertyType;
    name: string;
  };
  owningClass: ElementLinkData;
  description: string;
  typeInfo: PropertyTableRowTypeInfo;
  displayLabel: string;
  baseProperty?: PropertyLinkData;
  kindOfQuantity?: ElementLinkData;
  arrayBounds?: {
    min?: number;
    max?: number;
  };
  isReadonly: boolean;
}

export interface PropertyTableRowTypeInfo {
  isArray?: boolean;
  primitiveType?: {
    id: PrimitiveType;
    ext?: string;
  };
  struct?: ElementLinkData;
  enumeration?: ElementLinkData;
  nav?: NavPropertyLinkData;
}

export interface ClassRelationshipsDetails {
  relId: string;
  relLabel: string;
  strength: StrengthType;
  roleLabel: string;
  endpointId: string;
  relEnd: RelationshipEnd;
  strengthDir: StrengthDirection;
}

export interface EnumeratorTableRowData {
  id: string;
  name: string;
  value: string;
  displayLabel: string;
  description: string;
}

export interface SchemaItemTableRowData {
  name: ElementLinkData;
  displayLabel: string;
  description: string;
  itemType: string; // not the same as an elementType!
}

export interface ValidationResultTableRowData {
  name: ValidationLinkData;
  diagnosticCategory: string;
  diagnosticCode: string;
  message: string;
}

export interface ClassRelationshipsTreeNode {
  id: string; // Not an Id64!
  roleLabel?: string;
  relationship?: ElementLinkData;
  relatedClass?: ElementLinkData;
  children?: ClassRelationshipsTreeNode[];
  text?: string;
  selectable(): false;
}

export interface ClassRelationshipsTreeData {
  [ rootText: string ]: ClassRelationshipsTreeNode[];
}

export interface ElementSearchResultData {
  id: string;
  name?: string;
  label?: string;
  schemaAlias: string;
  schemaId?: string;
  elementType: AnyElementType;
  pType?: PropertyType;
  pName?: string;
  pId?: string;
  className?: string;
}

export interface Diagnostic {
  category: string;
  message: string;
}

export interface DiagnosticMessageArgs {
  [code: string]: string[];
}

export interface ItemDiagnostics {
  [itemId: string]: ItemDiagnostic;
}

export interface ItemDiagnostic {
  link: ValidationLinkData;
  messageArgs: DiagnosticMessageArgs;
}

export interface DiagnosticInfo {
  schemaId: Id64String;
  linkData: ValidationLinkData;
  category: string;
  code: string;
  messageText: string;
  messageArgs: string[];
}
