import React from "react";
import { ErrorView } from "../ErrorView";
import { ImseFrontend } from "frontend/api/ImseFrontend";

interface State {
  error: Error | undefined;
}

interface Props {
  children?: React.ReactNode;
}

export class ErrorBoundary extends React.Component<Props, State> {
  public override readonly state: State = {error: undefined};

  public override async componentDidCatch(err: Error, properties?: any) {
    await ImseFrontend.AppInsightsClient.trackError(properties);
    this.setState({ error: err });
  }

  public override render() {
    if (this.state.error) {
      return (
        <ErrorView error={this.state.error} />
      );
    } else {
      return this.props.children;
    }
  }
}
