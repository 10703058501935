/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { LoaderOverlay } from "frontend/components/controls/LoaderOverlay";
import { ModalFrontstage } from "frontend/components/ModalFrontstage";
import { SettingsStage } from "frontend/components/stages/SettingsStage";
import { Frontstage } from "frontend/components/Frontstage";
import { ClassBrowserStage } from "frontend/components/stages/ClassBrowserStage";
import { DiagramStage } from "frontend/components/stages/DiagramStage";
import { connect } from "react-redux";
import { ImseLocation } from "frontend/state/reducers/location";
import { RootState } from "frontend/state/rootReducer";
import { LogViewer } from "./components/stages/LogViewer";
import * as React from "react";
import { Welcome } from "./components/Welcome";

interface Props {
  children?: React.ReactNode;
  location: ImseLocation; // connected to redux
}

const mapState = (state: RootState) => ({
  location: state.location.current,
});

export class RouterComponent extends React.Component<Props> {

  /**
   * Returns the component class for a given modal frontstage id.
   * @param id The frontstage id ("?modal=..." query string value).
   */
  private getModalFrontstage(id?: string): [ string, React.ReactChild | undefined ] {
    switch (id) {
      case "settings": return [ "Settings", <SettingsStage key={id}/>];
      case "logs": return [ "Application Log", <LogViewer key={id}/>];
      case "open": return [ "", undefined ];
      default: return [ "", undefined ];
    }
  }

  private renderModalFrontstages(modalName?: string) {
    const [ title, stage ] = this.getModalFrontstage(modalName);

    return (
      <ModalFrontstage isOpen={Boolean(title)} title={title}>
        {stage}
      </ModalFrontstage>
    );
  }

  private renderFrontstage(stage?: string) {
    switch (stage) {
      case "browse": return (this.props.location.elementType !== undefined) ? <ClassBrowserStage /> : <Welcome status="error" message="Sorry! We weren't able to find a correct element type for the schema you were looking for."/>;
      case "diagram": return <DiagramStage />;
      default:
        return <Welcome message=""/>;
    }
  }

  public override render() {
    return (
      <>
        <LoaderOverlay />
        {/* WIP: Modal Frontstages */}
        {this.renderModalFrontstages(this.props.location.modal)}
        {/* Regular Frontstages */}
        <Frontstage>
          {this.renderFrontstage(this.props.location.stage)}
        </Frontstage>
      </>
    );
  }
}

export const Router = connect(mapState)(RouterComponent);

