/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { RelationshipMultiplicity } from "@itwin/ecschema-metadata";
import { RelationshipMultiplicityInfo } from "frontend/api/Interfaces";

// FIXME: This shouldn't be here...
export class RelationshipMultiplicityTypeConverter {
  private static endToString(end: number) { return (end === RelationshipMultiplicity.zeroMany.upperLimit) ? "*" : end.toString(); } // FIXME

  public static convertToString(value: RelationshipMultiplicityInfo): string {
    return `${this.endToString(value.lowerLimit)}..${this.endToString(value.upperLimit)}`;
  }
}
