/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { CustomAttributeAttributes, ElementAttributes } from "frontend/api/Interfaces";
import { containerTypeToString } from "@itwin/ecschema-metadata";
import "../cards/CardContainer.scss";

type Props = ElementAttributes;
type State = Partial<CustomAttributeAttributes>;

export class CustomAttributeDetails extends React.Component<Props, State> {
  private static _defaultState = {
    containerType: undefined,
  };

  public override readonly state: State = CustomAttributeDetails._defaultState;
  public initialized?: Promise<any>;

  private async fetchData() {
    const data = ImseFrontend.instance.getSchemaItemAttributes(this.props.id) as CustomAttributeAttributes;
    this.setState({...CustomAttributeDetails._defaultState, ...data});
  }

  public override componentDidMount() {
    this.initialized = this.fetchData();
  }

  public override componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id)
      this.initialized = this.fetchData();
  }

  public override render() {
    const formattedContainerType = (undefined === this.state.containerType) ? "" : containerTypeToString(this.state.containerType);
    return (
      <div className="details-page">
        <div className="element-attributes">
          <div className="item">
            <span className="label">Container Type: </span><span className="value">{formattedContainerType}</span>
          </div>
        </div>
      </div>
    );
  }
}
