/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { createAction, createReducer, combineReducers } from "@reduxjs/toolkit"
import { COLLAPSE_TREE_NODE, EXPAND_TREE_NODE } from "frontend/state/actionTypes";

export const Actions = {
  expandTreeNode: createAction(EXPAND_TREE_NODE, (node: string) => { return { payload: node }}),
  collapseTreeNode: createAction(COLLAPSE_TREE_NODE, (node: string)=> { return { payload: node }}),
};

type ExpandedNodesState = string[];

const initialState: ExpandedNodesState = [];
const expandedNodes = createReducer(initialState, (builder) => {

  builder.addCase(Actions.expandTreeNode, (state, action) => {
      const alreadyExpanded = state.indexOf(action.payload) >= 0;
      return alreadyExpanded ? state : state.concat(action.payload);
    })
    .addCase(Actions.collapseTreeNode, (state, action) => state.filter((id: any) => id !== action.payload));
  });

export const reducer = combineReducers({
  expandedNodes,
});
