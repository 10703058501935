/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { StrengthDirection, StrengthType } from "@itwin/ecschema-metadata";

import "./UmlArrow.scss";
import { EmbeddingBoldIcon, EmbeddingIcon, HoldingBoldIcon, HoldingIcon, ReferencingBoldIcon, ReferencingIcon } from "frontend/icons/IconsUml";
import { Text } from "@itwin/itwinui-react";

interface Props {
  children?: React.ReactNode;
  strength: StrengthType;
  direction: StrengthDirection;
  multiplicity: {
    left: string;
    right: string;
  };
  roleLabel: string;
  small?: boolean;
}

export class UmlArrow extends React.Component<Props> {
  private getIcon() {
    const bold = this.props.small;
    switch (this.props.strength) {
      case StrengthType.Embedding: return (bold) ? <EmbeddingBoldIcon /> : <EmbeddingIcon />;
      case StrengthType.Holding:  return (bold) ? <HoldingBoldIcon />: <HoldingIcon />;
      case StrengthType.Referencing: return (bold) ? <ReferencingBoldIcon /> : <ReferencingIcon />;
    }
    throw new Error("Unknown ECRelationshipStrength!");
  }

  public override render() {
    const isBackward = (StrengthDirection.Backward === this.props.direction);
    const isReferencing = (StrengthType.Referencing === this.props.strength);

    const classNames = ["uml-arrow"];
    if (isReferencing)
      classNames.push("referencing");

    if (this.props.small)
      classNames.push("small");

    // The arrow should be "reversed" (icon on the left) if:
    //  - Direction is Backward AND the icon is "Referencing" (-->)
    //  - OR Direction is Forward AND the icon is either "Embedding" or "Holding" (--<>)
    const iconClass = (isReferencing === isBackward) ? "reverse iui-icons-small uml-icon" : "iui-icons-small uml-icon";

    return (
      <div className={classNames.join(" ")}>
        <div className={iconClass}>
          {this.getIcon()}
        </div>
        <Text variant="body" as="span" className="multiplicity left">{this.props.multiplicity.left}</Text>
        <Text variant="body" as="span" className="multiplicity right">{this.props.multiplicity.right}</Text>
        <Text variant="body" as="span" className="role-label">{this.props.roleLabel}</Text>
        <Text variant="body" as="span" className="filler"></Text>
      </div >
    );
  }
}
