/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ElementAttributes } from "frontend/api/Interfaces";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { UsesCard } from "frontend/components/stages/classbrowser/cards/UsesCard";

type Props = ElementAttributes;

export class UnitSystemDetails extends React.Component<Props> {
  private static _dataProvider = async (id: string) => ImseFrontend.instance.getUnitsUsedByUnitSystem(id);

  public override render() {
    return (
      <div className="details-page">
        <div className="element-attributes">
        </div>
        <div className="card-container">
          <UsesCard title="Units:" dataProvider={UnitSystemDetails._dataProvider} id={this.props.id} />
        </div>
      </div>
    );
  }
}
