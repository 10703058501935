/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import EnumeratorsTable from "frontend/components/controls/table/EnumeratorsTable";
import { ElementTitleBlock } from "frontend/components/stages/classbrowser/ElementTitleBlock";
import { connect } from "react-redux";
import { Actions } from "frontend/state/reducers/splitters";
import { Selectors } from "frontend/state/selectors";
import { RootState } from "frontend/state/rootReducer";
import "./ElementInfoPage.css";
import { AnyElementType, ElementAttributes, SchemaType } from "frontend/api/Interfaces";
import { FractionalDefinitionNotation } from "frontend/components/controls/FractionalDefinitionNotation";
import { SchemaItemType } from "@itwin/ecschema-metadata";
import AspectPropertiesTable from "frontend/components/controls/table/AspectPropertiesTable";
import SchemaItemsTable from "frontend/components/controls/table/SchemaItemsTable";
import ClassPropertiesTable from "frontend/components/controls/table/ClassPropertiesTable";

interface ElementInfoPageProps extends ElementAttributes {
  elementType: AnyElementType;
  propertyName?: string;
}

interface Props extends ElementInfoPageProps {
  children?: React.ReactNode;
  activeTab?: string; // Connected to redux
}

const mapState = (state: RootState) => ({
  activeTab: Selectors.activeTabSelector(state),
});

const mapDispatch = {
  onSplitterChange: Actions.changeDetailsSplitter,
};

export class ElementInfoPageComponent extends React.Component<Props> {
  private _label = () => {
    if (this.props.displayLabel) {
      return this.props.displayLabel;
    } else {
      return this.props.name;
    }
  };
  private renderContentBelowSplitter() {
    switch (this.props.elementType) {
      case (SchemaType.schema):
        if (this.props.activeTab === "items") { // Check this
          return (<SchemaItemsTable id={this.props.id}/>);
        }
        break;
      case (SchemaItemType.Mixin):
      case (SchemaItemType.CustomAttributeClass):
      case (SchemaItemType.StructClass):
      case (SchemaItemType.RelationshipClass):
        return (<ClassPropertiesTable id={this.props.id} propertyName={this.props.propertyName}/>);
      case (SchemaItemType.EntityClass):
        if (this.props.activeTab === "aspect") { // By default, we show the class properties first.
          return (<AspectPropertiesTable id={this.props.id} propertyName={this.props.propertyName}/>);
        } else {
          return (<ClassPropertiesTable id={this.props.id} propertyName={this.props.propertyName}/>);
        }
      case (SchemaItemType.Enumeration):
        return (<EnumeratorsTable id={this.props.id} />);
      case (SchemaItemType.Constant):
      case (SchemaItemType.Phenomenon):
      case (SchemaItemType.Unit):
        return (<FractionalDefinitionNotation id={this.props.id} currentUnit={this._label()} />);
    }
    return <span />;
  }

  public override render() {
    return (
      <div className="element-info">
        <ElementTitleBlock {...this.props} />
        {this.props.children}
        {this.renderContentBelowSplitter()}
      </div>
    );
  }
}

export const ElementInfoPage = connect(mapState, mapDispatch)(ElementInfoPageComponent);
