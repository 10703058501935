/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { ElementAttributes, FormatAttributes } from "frontend/api/Interfaces";
import { ElementLink } from "frontend/components/controls/ElementLink";
import { UsesCard } from "../cards/UsesCard";
import { formatTraitsToArray, FormatType, formatTypeToString, scientificTypeToString, showSignOptionToString } from "@itwin/core-quantity";
import { SchemaItemType } from "@itwin/ecschema-metadata";

type Props = ElementAttributes;
type State = Partial<FormatAttributes>;

export class FormatDetails extends React.Component<Props, State> {
  private static _dataProvider = async (id: string) => ImseFrontend.instance.getKoqThatUseFormat(id);
  private static _defaultState: State = {
    elementType: SchemaItemType.Format,
    roundFactor: undefined,
    formatType: undefined,
    precision: undefined,
    minWidth: undefined,
    scientificType: undefined,
    showSignOption: undefined,
    decimalSeparator: "",
    thousandSeparator: "",
    uomSeparator: "",
    stationSeparator: "",
    stationOffsetSize: undefined,
    formatTraits: 0,
    spacer: "",
    includeZero: undefined,
    units: [],
  };

  public override readonly state: State = FormatDetails._defaultState;
  public initialized?: Promise<any>;

  private async fetchData() {
    const data = ImseFrontend.instance.getSchemaItemAttributes(this.props.id) as FormatAttributes;
    this.setState({ ...FormatDetails._defaultState, ...data});
  }

  public override componentDidMount() {
    this.initialized = this.fetchData();
  }

  public override componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id)
      this.initialized = this.fetchData();
  }

  private renderUnits(): JSX.Element[] {
    return this.state.units!.map((value, idx) => (<span key={idx} className="element-link"><ElementLink {...value[0]} /></span>));
  }

  private renderCharacter(char: string | undefined): string {
    let parsedChar = char || "";
    if (parsedChar === " ")
      parsedChar = "<space>";

    return parsedChar;
  }

  public override render() {
    const units = this.renderUnits();
    const scientificType = this.state.formatType === FormatType.Scientific ? scientificTypeToString(this.state.scientificType!) : "";
    const formatTraits = formatTraitsToArray(this.state.formatTraits!).join(", ");

    return (
      <div className="details-page">
        <div className="element-attributes">
          <div className="item">
            <span className="label">Round Factor: </span><span className="value">{this.state.roundFactor}</span>
          </div>
          <div className="item">
            <span className="label">Type: </span><span className="value">{this.state.formatType !== undefined ? formatTypeToString(this.state.formatType) : undefined}</span>
          </div>
          <div className="item">
            <span className="label">Precision: </span><span className="value">{this.state.precision}</span>
          </div>
          <div className="item">
            <span className="label">Minimum Width: </span><span className="value">{this.state.minWidth}</span>
          </div>
          <div className="item">
            <span className="label">Scientific Type: </span><span className="value">{scientificType}</span>
          </div>
          <div className="item">
            <span className="label">Show Sign Option: </span><span className="value">{this.state.showSignOption !== undefined ? showSignOptionToString(this.state.showSignOption) : undefined}</span>
          </div>
          <div className="item">
            <span className="label">Decimal Separator: </span><span className="value">{this.renderCharacter(this.state.decimalSeparator)}</span>
          </div>
          <div className="item">
            <span className="label">Thousand Separator: </span><span className="value">{this.renderCharacter(this.state.thousandSeparator)}</span>
          </div>
          <div className="item">
            <span className="label">UOM Separator: </span><span className="value">{this.renderCharacter(this.state.uomSeparator)}</span>
          </div>
          <div className="item">
            <span className="label">Station Separator: </span><span className="value">{this.renderCharacter(this.state.stationSeparator)}</span>
          </div>
          <div className="item">
            <span className="label">Station Offset Size: </span><span className="value">{this.state.stationOffsetSize}</span>
          </div>
          <div className="item">
            <span className="label">Format Traits: </span><span className="value">{formatTraits}</span>
          </div>
          <div className="item">
            <span className="label">Spacer: </span><span className="value">{this.renderCharacter(this.state.spacer)}</span>
          </div>
          <div className="item">
            <span className="label">Include Zero: </span><span className="value">{(this.state.includeZero) ? "True" : "False"}</span>
          </div>
          <div className="item">
            <span className="label">Units: </span> <span className="value">{units}</span>
          </div>
        </div>
        <div className="card-container">
          <UsesCard title="Used by:" dataProvider={FormatDetails._dataProvider} id={this.props.id} />
        </div>
      </div>
    );
  }
}
