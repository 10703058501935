import { ECClassModifier, SchemaItemType } from "@itwin/ecschema-metadata";
import { AnyElementType, OtherNodeType } from "frontend/api/Interfaces";
import * as React from "react";
import { SchemaTag } from "../SchemaTag";

interface Props{
  isSelected?: boolean;
  text: string;
  isSchema?: boolean;
  schemaId?: string;
  schemaAlias?: string;
  elementType?: AnyElementType;
  modifier?: ECClassModifier;
}
export class NodeLabel extends React.Component<Props> {
  private _spanRef = React.createRef<HTMLSpanElement>();

  public emphasizeFocus() {
    if (this._spanRef.current && (this._spanRef.current as any).scrollIntoViewIfNeeded)
      (this._spanRef.current as any).scrollIntoViewIfNeeded();
  }

  public override componentDidMount() {
    if (this.props.isSelected) {
      this.emphasizeFocus();
    }
  }

  public override componentDidUpdate(prevProps: Props) {
    if (this.props.isSelected && !prevProps.isSelected) {
      this.emphasizeFocus();
    }
  }

  public override render() {
    if (this.props.elementType! === OtherNodeType.root) {
      return (
        <span ref={this._spanRef}>
          {this.props.text}
        </span>
      );
    } else {
      const isAbstract = this.props.modifier === ECClassModifier.Abstract || this.props.elementType === SchemaItemType.Mixin;
      const italicStyle = (isAbstract) ? { fontStyle: "italic" } : undefined;
      return (
        <>
          <span ref={this._spanRef} style={italicStyle}>
            {this.props.text}
          </span>
          {(!this.props.isSchema) ? <SchemaTag schemaName={this.props.schemaId!} schemaAlias={this.props.schemaAlias!} isSchema={this.props.isSchema} /> : undefined}
        </>
      );
    }
  }
}
