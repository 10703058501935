/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { Logger } from "@itwin/core-bentley";
import { AnyElementType, ClassRelationshipsDetails, ClassRelationshipsTreeData, ElementAttributes, ElementLinkData, ElementSearchResultData, EnumeratorTableRowData, NavTreeNode, PresentationUnitLinkData, PropertyTableRowData, RelationshipConstraintInfo, SchemaAttributes, SchemaDefData, SchemaItemAttributes, SchemaItemTableRowData, SchemaLinkData, SchemaType } from "frontend/api/Interfaces";
import { AnyClass, AnyProperty, AnySchemaItem, Constant, ECClass, ECVersion, EntityClass, Enumeration, EnumerationProperty, Format, InvertedUnit, ISchemaLocater, KindOfQuantity, NavigationProperty, OverrideFormat, Phenomenon, Property, PropertyCategory, RelationshipClass, RelationshipConstraint, RelationshipEnd, Schema, SchemaContext, SchemaItemType, schemaItemTypeToString, SchemaKey, SchemaMatchType, StrengthDirection, StrengthType, StructProperty, Unit } from "@itwin/ecschema-metadata";
import { ECClassExtended } from "frontend/utils/ECClassExtended";
import { parseDefinition } from "./DefinitionParser";
import { AppInsightsClient } from "./AppInsightsClient";
import { initializeBisSchemaLocater } from "frontend/utils/BisSchemaLocater";
import { ConfigManager } from "frontend/config/ConfigManager";
import { IModelRpcPropsProxy } from "./IModelApiProxies";

// Configure iModelJs frontend logging to go to the console
Logger.initializeToConsole();

declare let AppVersion: any;

export class ImseFrontend {
  private static _instance: ImseFrontend;
  private static _iModelProps: IModelRpcPropsProxy;
  private static _appInsightsClient: AppInsightsClient;

  private _schemas: Schema[];
  private _loadingSchemas: Promise<any> | undefined;
  private _context: SchemaContext;
  private _extendedClassCache: Map<string, ECClassExtended>;

  private constructor() {
    this._schemas = [];
    this._loadingSchemas = undefined;
    this._context = new SchemaContext();
    this._extendedClassCache = new Map<string, ECClassExtended>();
  }

  public static get instance() {
    if (!ImseFrontend._instance)
      ImseFrontend._instance = new ImseFrontend();
    return ImseFrontend._instance;
  }

  public static set IModelProps(iModelProps: IModelRpcPropsProxy) {
    this._iModelProps = iModelProps;
  }

  public static get IModelProps() {
    return this._iModelProps;
  }

  public static set AppInsightsClient(appInsightsClient: AppInsightsClient) {
    this._appInsightsClient = appInsightsClient;
  }

  public static get AppInsightsClient() {
    return this._appInsightsClient;
  }

  public static resetIModelProps() {
    this._iModelProps = { key: "" };
  }

  public getCurrentSchemaContext(): SchemaContext {
    return this._context;
  }

  private getSchemaById(id: string): Schema | undefined {
    const schemaName = id.split(".")[0].toLowerCase();
    return this._schemas.find((x) => x.name.toLowerCase() === schemaName);
  }

  private getSchemaItemById(id: string): AnySchemaItem | undefined {
    const schema = this.getSchemaById(id);
    if (schema) {
      const schemaItem = id.split(".")[1];
      if (schemaItem) {
        return schema.getItemSync(schemaItem);
      }
    }
    return undefined;
  }

  public getElementLinkData(id: string): ElementLinkData {
    const schemaItem = this.getSchemaItemById(id);

    if (schemaItem) {
      return {
        id,
        name: schemaItem.name,
        description: schemaItem.description || "",
        schemaAlias: schemaItem.schema.alias || "",
        schemaName: schemaItem.schema.name,
        elementType: schemaItem.schemaItemType,
      };
    } else {
      return {
        id,
        name: "",
        description: "",
        schemaAlias: "",
        schemaName: "",
        elementType: SchemaType.schema,
      };
    }
  }

  public getUnitLinkData(item: Format | OverrideFormat): Array<[ElementLinkData, string | undefined]> {
    const links = new Array<[ElementLinkData, string | undefined]>();

    const itemUnits = item.units;

    if (!itemUnits) { return links; }

    for (const unitNode of itemUnits) {
      links.push([this.getElementLinkData(unitNode[0].fullName), unitNode[1]]);
    }
    return links;
  }

  public getPresentationUnitLinkData(koq: KindOfQuantity): PresentationUnitLinkData[] {
    const presentationUnits = Array<PresentationUnitLinkData>();
    if (!koq.presentationFormats) { return presentationUnits; }
    let formatData;

    for (const f of koq.presentationFormats) {
      if (f instanceof Format) {
        formatData = this.getElementLinkData(f.fullName);
      } else {
        formatData = this.getElementLinkData(f.parent.fullName);
      }
      presentationUnits.push({
        format: formatData,
        units: this.getUnitLinkData(f),
      });
    }
    return presentationUnits;
  }

  public async getNavPropertyLinkData(prop: NavigationProperty) {
    const relClass = await prop.relationshipClass;

    const endpointConstraint = (StrengthDirection.Forward === prop.direction) ? relClass.target : relClass.source;
    const roleLabelConstraint = (StrengthDirection.Forward === prop.direction) ? relClass.source : relClass.target;

    const endpoint = this.getElementLinkData(endpointConstraint.abstractConstraint?.fullName ?? "");
    const roleLabel = this.getElementLinkData(relClass.fullName);

    roleLabel.label = roleLabelConstraint.roleLabel;
    return { endpoint, roleLabel };
  }

  public getSchemaLinkData(id: string): SchemaLinkData | undefined {
    const schema = this.getSchemaById(id);

    if (!schema) { return undefined; }

    const data: SchemaLinkData = {
      id,
      name: schema.name,
      description: schema.description ? schema.description : "",
    };

    return data;
  }

  public async getAppVersion(): Promise<string> {
    return AppVersion;
  }

  public addSchemaLocaterToContext(schemaLocater: ISchemaLocater): void {
    this._context.addLocater(schemaLocater);
  }

  public async importAllSchemas(): Promise<any> {
    try {
      let schemaKeys: SchemaKey[];
      // If true, then use rpc, else use web locater.
      if (ConfigManager.shouldSignIn()) {
        const { IModelApiWrapper } = await import("./IModelApiWrapper");
        await IModelApiWrapper.validateChangeSetId(ImseFrontend.IModelProps); // If changesetId is empty, then retrieve latest changesetId.
        this.addSchemaLocaterToContext(IModelApiWrapper.initializeRpcSchemaLocater(ImseFrontend.IModelProps));
        schemaKeys = await IModelApiWrapper.getSchemaKeysFromRpcInterface(ImseFrontend.IModelProps);
      } else {
        const bisSchemaLocater = initializeBisSchemaLocater();
        await bisSchemaLocater.loadSchemaResources();
        this.addSchemaLocaterToContext(bisSchemaLocater);
        schemaKeys = Array.from(bisSchemaLocater.loadedSchemas.keys());
      }
      const context = this.getCurrentSchemaContext();
      for (const key of schemaKeys) {
        if (!key)
          continue;
        const schema = await context.getSchema(key, SchemaMatchType.Latest);
        if (!schema)
          continue;
        this._schemas.push(schema);
      }
    } catch (err) {
      await ImseFrontend.AppInsightsClient.handleRpcException(err as Error);
    }
  }

  public async getChildNodes(rootNodeKey: AnyElementType | undefined, schemasToExclude?: ReadonlyArray<string>): Promise<NavTreeNode[]> {
    if (rootNodeKey !== undefined) {
      if (schemasToExclude)
        return this.getChildNodesByType(rootNodeKey, Array.from(schemasToExclude));
      return this.getChildNodesByType(rootNodeKey);
    }

    throw new Error("Not Implemented!!!!");
  }

  private getChildNavNodes(eCClass: ECClassExtended, schemas: Schema[]): NavTreeNode[] {
    const childrenNodes: NavTreeNode[] = [];
    if (eCClass.subClasses.length > 0)
      eCClass.subClasses.forEach((subClass: ECClassExtended) => {
        if (schemas.includes(subClass.thisClass.schema)) {
          childrenNodes.push(this.createTreeNode(subClass, schemas));
        } else {
          // Look into nested subclasses to see if there are classes from unfiltered schemas.
          subClass.subClasses.forEach((nestedSubClass: ECClassExtended) => {
            if (schemas.includes(nestedSubClass.thisClass.schema))
              childrenNodes.push(this.createTreeNode(nestedSubClass, schemas));
            else {
              this.getChildNavNodes(nestedSubClass, schemas).forEach((child) => {
                childrenNodes.push(child);
              });
            }
          });
        }
      });

    return childrenNodes;
  }

  private createTreeNode(ecClass: ECClassExtended, schemas: Schema[], childNodes?: NavTreeNode[]): NavTreeNode {
    if (!childNodes) {
      childNodes = this.getChildNavNodes(ecClass, schemas);
    }
    return {
      id: ecClass.thisClass.fullName,
      text: ecClass.thisClass.name,
      children: (childNodes.length > 0) ? childNodes : undefined,
      schemaAlias: ecClass.thisClass.schema.alias,
      schemaId: ecClass.thisClass.schema.name,
      isSchema: false,
      modifier: ecClass.thisClass.modifier,
    };
  }

  private isRoot(ecClass: ECClass, schemas: Schema[]): boolean {
    for (const baseClass of ecClass.getAllBaseClassesSync()) {
      if (schemas.includes(baseClass.schema))
        return false;
    }
    return true;
  }

  public getChildNodesByType(elementType: AnyElementType, schemasToExclude?: string[]): NavTreeNode[] {
    const nodes: NavTreeNode[] = [];
    let schemas: Schema[] = this._schemas;

    if (schemasToExclude)
      schemas = schemas.filter((x) => !schemasToExclude.includes(x.name));

    switch (elementType) {
      case SchemaType.schema:
        this._schemas.forEach((schema: Schema) => {
          nodes.push({
            id: schema.name,
            text: schema.name,
            schemaAlias: schema.alias,
            schemaId: schema.name,
            isSchema: true,
          });
        });
        break;

      case SchemaItemType.EntityClass:
      case SchemaItemType.Mixin:
      case SchemaItemType.StructClass:
      case SchemaItemType.CustomAttributeClass:
      case SchemaItemType.RelationshipClass:
        Array.from(
          this._extendedClassCache.values()).filter((e) => e.thisClass.schemaItemType === elementType && schemas.includes(e.thisClass.schema) && this.isRoot(e.thisClass, schemas)).forEach((element) => nodes.push(this.createTreeNode(element, schemas)));
        break;
      case SchemaItemType.Enumeration:
      case SchemaItemType.Constant:
      case SchemaItemType.Phenomenon:
      case SchemaItemType.UnitSystem:
      case SchemaItemType.Format:
      case SchemaItemType.KindOfQuantity:
      case SchemaItemType.PropertyCategory:
      case SchemaItemType.Unit:
        this.getAllItemsOfType(schemas, elementType).forEach((item: AnySchemaItem) => {
          nodes.push({
            id: item.fullName,
            text: item.name,
            schemaAlias: item.schema.alias,
            schemaId: item.schema.name,
            isSchema: false,
          });
        });
        break;
    }

    return nodes;
  }

  private getAllItemsOfType(schemas: Schema[], itemType: SchemaItemType): AnySchemaItem[] {
    const items = new Array<AnySchemaItem>();
    schemas.forEach((schema: Schema) => {
      Array.from(schema.getItems()).forEach((item: AnySchemaItem) => {
        if (item.schemaItemType === itemType) {
          items.push(item);
        }
      });
    });

    return items;
  }

  public fillClassCache(schemas: Schema[]): void {
    for (const schema of schemas) {
      for (const ecClass of schema.getClasses()) {
        this._extendedClassCache.set(ecClass.fullName, new ECClassExtended(ecClass));
      }
    }
    for (const ecClassExtended of this._extendedClassCache.values()) {
      const baseClass = ecClassExtended.thisClass.getBaseClassSync();
      if (baseClass) {
        const baseClassExtended = this._extendedClassCache.get(baseClass.fullName);
        if (baseClassExtended) {
          baseClassExtended.addSubClass(ecClassExtended);
        }
      }
    }
  }

  public async getLoadedSchemas(): Promise<Schema[]> {
    if (!this._schemas || this._schemas.length === 0) {
      if(this._loadingSchemas === undefined) {
        this._loadingSchemas = this.importAllSchemas();
      }
      await this._loadingSchemas;
      this.fillClassCache(this._schemas);
    }
    return this._schemas;
  }

  public async getLoadedSchemaIds(): Promise<Array<{ id: string, name: string }>> {
    await this.getLoadedSchemas();

    // Combine backend response with loaded schemas in this instance
    let loadedSchemas = new Array<{ id: string, name: string }>();
    this._schemas.forEach((schema: Schema) => {
      loadedSchemas = [...loadedSchemas, { id: schema.name.toString(), name: schema.name }];
    });

    return loadedSchemas;
  }

  public getSchemaDef(id: string): SchemaDefData {
    const schema = this.getSchemaById(id);
    if (!schema) return { alias: "", version: "" };
    const version = new ECVersion(schema.readVersion, schema.writeVersion, schema.minorVersion);
    return { alias: schema.alias, version: version.toString() };
  }

  public getSchemaAttributes(id: string): SchemaAttributes | undefined {
    const schema = this.getSchemaById(id);

    if (!schema) { return undefined; }

    return {
      id,
      name: schema.name,
      alias: schema.alias,
      version: schema.schemaKey.version.toString(),
      description: schema.description ? schema.description : "",
      displayLabel: schema.label ? schema.label : "",
      schemaName: "",
      modifier: null,
    };
  }

  public getConstraintAttributes(constraint: RelationshipConstraint): RelationshipConstraintInfo {
    const classLinkData = new Array<ElementLinkData>();

    const constraintClasses = constraint.constraintClasses;
    if (constraintClasses) {
      for (const node of constraintClasses) {
        const link = this.getElementLinkData(node.fullName);
        classLinkData.push(link);
      }
    }

    return {
      // TODO: Fix RelationshipConstraint so multiplicity and polymorphic are not nullable
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      multiplicity: constraint.multiplicity!,
      roleLabel: constraint.roleLabel ?? "",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      isPolymorphic: constraint.polymorphic!,
      abstractConstraint: constraint.abstractConstraint ? this.getElementLinkData(constraint.abstractConstraint.fullName) : this.getElementLinkData(""),
      classes: classLinkData,
      id: constraint.fullName,
    };
  }

  public getSchemaItemAttributes(id: string): SchemaItemAttributes | undefined {
    const schemaItem = this.getSchemaItemById(id);
    const schemaClass = schemaItem as AnyClass;

    if (!schemaItem) { return undefined; }

    const data: ElementAttributes = {
      id,
      description: schemaItem.description || "",
      displayLabel: schemaItem.label || "",
      name: schemaItem.name,
      schemaName: schemaItem.schema.name,
      modifier: schemaClass ? schemaClass.modifier : null,
    };

    switch (schemaItem.schemaItemType) {
      case SchemaItemType.CustomAttributeClass:
        return { ...data, elementType: schemaItem.schemaItemType, containerType: schemaItem.containerType };
      case SchemaItemType.Enumeration:
        // TODO: Fix when Enumeration.type is no longer nullable
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return { ...data, elementType: schemaItem.schemaItemType, primitiveType: schemaItem.type!, isStrict: schemaItem.isStrict };
      case SchemaItemType.PropertyCategory:
        return { ...data, elementType: schemaItem.schemaItemType, priority: schemaItem.priority };
      case SchemaItemType.EntityClass:
        return { ...data, elementType: schemaItem.schemaItemType };
      case SchemaItemType.StructClass:
        return { ...data, elementType: schemaItem.schemaItemType };
      case SchemaItemType.UnitSystem:
        return { ...data, elementType: schemaItem.schemaItemType };
      case SchemaItemType.Phenomenon:
        return { ...data, elementType: schemaItem.schemaItemType, definition: schemaItem.definition };
      case SchemaItemType.Mixin:
        return {
          ...data, elementType: schemaItem.schemaItemType,
          appliesTo: schemaItem.appliesTo ? this.getElementLinkData(schemaItem.appliesTo.fullName) : this.getElementLinkData(""),
        };
      case SchemaItemType.Format:
        return {
          ...data, elementType: schemaItem.schemaItemType, roundFactor: schemaItem.roundFactor, formatType: schemaItem.type,
          precision: schemaItem.precision, minWidth: schemaItem.minWidth, scientificType: schemaItem.scientificType,
          showSignOption: schemaItem.showSignOption, decimalSeparator: schemaItem.decimalSeparator, thousandSeparator: schemaItem.thousandSeparator,
          uomSeparator: schemaItem.uomSeparator, stationSeparator: schemaItem.stationSeparator, stationOffsetSize: schemaItem.stationOffsetSize,
          formatTraits: schemaItem.formatTraits, spacer: schemaItem.spacer, includeZero: schemaItem.includeZero,
          units: this.getUnitLinkData(schemaItem),
        };
      case SchemaItemType.KindOfQuantity:
        return {
          ...data, elementType: schemaItem.schemaItemType, precision: schemaItem.relativeError,
          persistenceUnit: schemaItem.persistenceUnit ? this.getElementLinkData(schemaItem.persistenceUnit.fullName) : undefined,
          presentationUnits: this.getPresentationUnitLinkData(schemaItem),
        };
      case SchemaItemType.Constant:
        return {
          ...data, elementType: schemaItem.schemaItemType, definition: schemaItem.definition, numerator: schemaItem.numerator, denominator: schemaItem.denominator,
          phenomenon: schemaItem.phenomenon ? this.getElementLinkData(schemaItem.phenomenon.fullName) : undefined,
        };
      case SchemaItemType.Unit:
        return {
          ...data, elementType: schemaItem.schemaItemType, definition: schemaItem.definition, offset: schemaItem.offset,
          numerator: schemaItem.numerator, denominator: schemaItem.denominator,
          phenomenon: schemaItem.phenomenon ? this.getElementLinkData(schemaItem.phenomenon.fullName) : undefined,
          unitSystem: schemaItem.unitSystem ? this.getElementLinkData(schemaItem.unitSystem.fullName) : undefined,
        };
      case SchemaItemType.InvertedUnit:
        return {
          ...data, elementType: schemaItem.schemaItemType,
          invertsUnit: schemaItem.invertsUnit ? this.getElementLinkData(schemaItem.invertsUnit.fullName) : undefined,
          unitSystem: schemaItem.unitSystem ? this.getElementLinkData(schemaItem.unitSystem.fullName) : undefined,
        };
      case SchemaItemType.RelationshipClass:
        return {
          ...data, elementType: schemaItem.schemaItemType,
          relationshipDirection: schemaItem.strengthDirection,
          relationshipStrength: schemaItem.strength,
          source: this.getConstraintAttributes(schemaItem.source),
          target: this.getConstraintAttributes(schemaItem.target),
        };
    }
  }

  private createElementSearchResultDataFromECClass(ecClass: ECClass): ElementSearchResultData {
    return {
      id: ecClass.fullName,
      name: ecClass.name,
      label: ecClass.label,
      schemaAlias: ecClass.schema.alias,
      schemaId: ecClass.schema.name,
      elementType: ecClass.schemaItemType,
    };
  }

  private async createElementSearchResultDataFromProperty(property: Property): Promise<ElementSearchResultData> {
    return {
      id: property.fullName,
      name: property.name,
      label: property.label,
      schemaAlias: property.schema.alias,
      schemaId: property.schema.name,
      elementType: property.class.schemaItemType,
      pType: property.propertyType,
      pName: property.name,
      pId: property.class.fullName,
      className: property.class.name,
    };
  }
  // Get the Schemas, classes, entities and properties and put them in a resulting Array.
  public async getSearchIndex(schemasToExclude?: ReadonlyArray<string>): Promise<ElementSearchResultData[]> {
    const searchResults: ElementSearchResultData[] = [];
    let schemas: Schema[] = await this.getLoadedSchemas();
    if (schemasToExclude) {
      schemas = schemas.filter((x) => !schemasToExclude.includes(x.name)); // Consider case insensitivity?
    }
    schemas.forEach((schema: Schema) => { // Get all schemas
      searchResults.push({
        id: schema.name,
        name: schema.name,
        schemaAlias: schema.alias,
        elementType: SchemaType.schema,
      });
      for (const schemaItem of schema.getItems()) { // Get all schema items.
        switch (schemaItem.schemaItemType) {
          case SchemaItemType.EntityClass:
          case SchemaItemType.Mixin:
          case SchemaItemType.StructClass:
          case SchemaItemType.CustomAttributeClass:
          case SchemaItemType.RelationshipClass:
            searchResults.push(this.createElementSearchResultDataFromECClass(schemaItem));
            if (schemaItem.properties) {
              Array.from(schemaItem.properties).map(async (property: Property | undefined) => { // Get all properties
                if (property) {
                  searchResults.push(await this.createElementSearchResultDataFromProperty(property));
                }
              });
            }
            break;
          case SchemaItemType.Enumeration:
          case SchemaItemType.KindOfQuantity:
          case SchemaItemType.PropertyCategory:
          case SchemaItemType.Unit:
          case SchemaItemType.InvertedUnit:
          case SchemaItemType.Constant:
          case SchemaItemType.Phenomenon:
          case SchemaItemType.UnitSystem:
          case SchemaItemType.Format:
            searchResults.push({
              id: schemaItem.fullName,
              name: schemaItem.name,
              label: schemaItem.label,
              schemaAlias: schemaItem.schema.alias,
              schemaId: schemaItem.schema.name,
              elementType: schemaItem.schemaItemType,
            });
            break;
          default:
            throw new Error("Invalid schema item type when loading search index.");
        }
      }
    });
    return searchResults;
  }

  // id -> Entity Class
  public async getMixinsUsedByEntityClass(id: string): Promise<ElementLinkData[]> {
    const links = new Array<ElementLinkData>();
    const inputEntity = this.getSchemaItemById(id) as EntityClass;
    if (!inputEntity || inputEntity.schemaItemType !== SchemaItemType.EntityClass) { return links; }

    for (const mixin of inputEntity.getMixinsSync()) {
      const data: ElementLinkData = {
        id: mixin.fullName,
        name: mixin.name,
        description: mixin.description || "",
        schemaName: mixin.schema.name,
        schemaAlias: mixin.schema.alias,
        elementType: mixin.schemaItemType,
      };
      links.push(data);
    }

    return links;
  }

  private async getSchemasThatAreOrReferenceSchema(schemaName: string): Promise<Schema[]> {
    return (await this.getLoadedSchemas()).filter((schema) => schema.name === schemaName || schema.getReferenceSync(schemaName) !== undefined);
  }

  private async *propertiesThatCouldContainReferenceToItem(schemaItem: AnySchemaItem | undefined, expectedItemType: SchemaItemType): AsyncIterable<Property> {
    if (!schemaItem || schemaItem.schemaItemType !== expectedItemType) {
      return;
    }
    for (const schema of await this.getSchemasThatAreOrReferenceSchema(schemaItem.schema.name)) {
      for (const ecClass of schema.getClasses()) {
        const classProps = ecClass.properties;
        if (!classProps) { continue; }

        for (const prop of classProps) {
          yield prop;
        }
      }
    }
  }

  private createElementLinkDataForProperty(prop: Property): ElementLinkData {
    return {
      id: prop.class.fullName,
      name: prop.class.name,
      propertyName: prop.name,
      description: prop.description || "",
      schemaAlias: prop.class.schema.alias || "",
      schemaName: prop.class.schema.name,
      elementType: prop.class.schemaItemType,
    };
  }
  /**
   * Get all KindOfQuantity objects that use a format.
   * @param id A schema or SchemaItem's Id of type Format.
   * @returns An array of ElementLinkData objects.
   */
  public async getKoqThatUseFormat(id: string): Promise<ElementLinkData[]> {
    const links = new Array<ElementLinkData>();
    const inputFormat = this.getSchemaItemById(id);
    if (!inputFormat || inputFormat.schemaItemType !== SchemaItemType.Format) { return links; }
    const kindOfQuantityItems = this.getAllItemsOfType(await this.getSchemasThatAreOrReferenceSchema(inputFormat.schema.name), SchemaItemType.KindOfQuantity) as KindOfQuantity[];
    for (const koqItem of kindOfQuantityItems) {
      const presentationFormats = koqItem.presentationFormats;
      for (const presentationFormat of presentationFormats) {
        const koqFormat = presentationFormat instanceof OverrideFormat ? presentationFormat.parent : presentationFormat;
        if (koqFormat === inputFormat) {
          const data: ElementLinkData = {
            id: koqItem.fullName,
            name: koqItem.name,
            description: koqItem.description || "",
            schemaAlias: koqItem.schema.alias || "",
            schemaName: koqItem.schema.name,
            elementType: koqItem.schemaItemType,
          };
          links.push(data);
          break;
        }
      }
    }
    return links;
  }
  /**
   * Get all Property objects that use a PropertyCategory.
   * @param id A schema or SchemaItem's Id of type PropertyCategory.
   * @returns An array of ElementLinkData objects.
   */
  public async getPropertiesThatUsePropertyCategory(id: string): Promise<ElementLinkData[]> {
    const links = new Array<ElementLinkData>();
    const inputPropCat = this.getSchemaItemById(id);
    if (!inputPropCat || inputPropCat.schemaItemType !== SchemaItemType.PropertyCategory) { return links; }
    for await (const prop of this.propertiesThatCouldContainReferenceToItem(inputPropCat, SchemaItemType.PropertyCategory)) {
      const categoryProp = await prop.category as PropertyCategory;
      if (categoryProp && categoryProp === inputPropCat) {
        links.push(this.createElementLinkDataForProperty(prop));
      }
    }
    return links;
  }

  /**
   * A helper function for getKoqThatUseUnit. By returning a boolean, this will avoid adding duplicate Koq objects to the main function's resulting array.
   * @param presentationFormat of type OverrideFormat or Format.
   * @param inputUnit The unit object to check against.
   * @returns A boolean checking if a unit of the format is the same as inputUnit.
   */
  private checkIfFormatUnitIsEqual(presentationFormat: OverrideFormat | Format, inputUnit: Unit | InvertedUnit): boolean {
    const formatUnits = presentationFormat instanceof OverrideFormat ? presentationFormat.parent.units : presentationFormat.units;
    if (formatUnits) {
      for (const [unit] of formatUnits) {
        if (unit === inputUnit) {
          return true;
        }
      }
      return false;
    } else { return false; }
  }
  /**
   * Get all KindOfQuantity objects that use a Unit. The function also uses implicit formats within KindOfQuantity objects to return results.
   * @param id A schema or SchemaItem's Id of type Unit.
   * @returns An array of ElementLinkData objects.
   */
  public async getKoqThatUseUnit(id: string): Promise<ElementLinkData[]> {
    const links = new Array<ElementLinkData>();
    const inputUnit = this.getSchemaItemById(id);
    if (!inputUnit || (inputUnit.schemaItemType !== SchemaItemType.Unit && inputUnit.schemaItemType !== SchemaItemType.InvertedUnit)) { return links; }
    const possibleSchemas = await this.getSchemasThatAreOrReferenceSchema(inputUnit.schema.name);
    const koqItems = this.getAllItemsOfType(possibleSchemas, SchemaItemType.KindOfQuantity) as KindOfQuantity[];
    for (const koqItem of koqItems) {
      const koqUnit = await koqItem.persistenceUnit;
      if (koqUnit && inputUnit === koqUnit) {
        const data: ElementLinkData = {
          id: koqItem.fullName,
          name: koqItem.name,
          description: koqItem.description || "",
          schemaAlias: koqItem.schema.alias || "",
          schemaName: koqItem.schema.name,
          elementType: koqItem.schemaItemType,
        };
        links.push(data);
      } else {
        for (const presentationFormat of koqItem.presentationFormats) {
          if (this.checkIfFormatUnitIsEqual(presentationFormat, inputUnit)) {
            const data: ElementLinkData = {
              id: koqItem.fullName,
              name: koqItem.name,
              description: koqItem.description || "",
              schemaAlias: koqItem.schema.alias || "",
              schemaName: koqItem.schema.name,
              elementType: koqItem.schemaItemType,
            };
            links.push(data);
            break;
          }
        }
      }
    }
    return links;
  }
  /**
   * Get all Format objects that use a Unit.
   * @param id A schema or SchemaItem's Id of type Unit.
   * @returns An array of ElementLinkData objects.
   */
  public async getFormatsThatUseUnit(id: string): Promise<ElementLinkData[]> {
    const links = new Array<ElementLinkData>();
    const inputUnit = this.getSchemaItemById(id);
    if (!inputUnit || (inputUnit.schemaItemType !== SchemaItemType.Unit && inputUnit.schemaItemType !== SchemaItemType.InvertedUnit)) { return links; }
    const formatItems = this.getAllItemsOfType(await this.getSchemasThatAreOrReferenceSchema(inputUnit.schema.name), SchemaItemType.Format) as Format[];
    for (const formatItem of formatItems) {
      const formatUnits = formatItem.units;
      if (formatUnits) {
        for (const [unit] of formatUnits) {
          if (unit === inputUnit) {
            const data: ElementLinkData = {
              id: formatItem.fullName,
              name: formatItem.name,
              description: formatItem.description || "",
              schemaAlias: formatItem.schema.alias || "",
              schemaName: formatItem.schema.name,
              elementType: formatItem.schemaItemType,
            };
            links.push(data);
            break;
          }
        }
      }
    }
    return links;
  }
  private async getPhenomenaFromUnit(unit: Unit | InvertedUnit): Promise<Phenomenon | undefined> {
    if (unit instanceof Unit) {
      return unit.phenomenon;
    } else if (unit instanceof InvertedUnit) {
      const invertedUnit = await unit.invertsUnit;
      if (invertedUnit) {
        return invertedUnit.phenomenon;
      }
    }
    return undefined;
  }
  /**
   * Get all KindOfQuantity objects that use a Phenomenon.
   * @param id A schema or SchemaItem's Id of type Phenomenon.
   * @returns An array of ElementLinkData objects.
   */

  public async getKoqThatUsePhenomenon(id: string): Promise<ElementLinkData[]> {
    const links = new Array<ElementLinkData>();
    const inputPhenomenon = this.getSchemaItemById(id);
    if (!inputPhenomenon || inputPhenomenon.schemaItemType !== SchemaItemType.Phenomenon) { return links; }
    const koqItems = this.getAllItemsOfType(await this.getSchemasThatAreOrReferenceSchema(inputPhenomenon.schema.name), SchemaItemType.KindOfQuantity) as KindOfQuantity[];
    for (const koqItem of koqItems) {
      const koqUnit = await koqItem.persistenceUnit;
      if (koqUnit) {
        const koqPhenomena = await this.getPhenomenaFromUnit(koqUnit);
        if (koqPhenomena && koqPhenomena === inputPhenomenon) {
          const data: ElementLinkData = {
            id: koqItem.fullName,
            name: koqItem.name,
            description: koqItem.description || "",
            schemaAlias: koqItem.schema.alias || "",
            schemaName: koqItem.schema.name,
            elementType: koqItem.schemaItemType,
          };
          links.push(data);
        }
      }
    }
    return links;
  }
  /**
   * Get all Format objects that use a Phenomenon.
   * @param id A schema or SchemaItem's Id of type Phenomenon.
   * @returns An array of ElementLinkData objects.
   */
  public async getFormatsThatUsePhenomenon(id: string): Promise<ElementLinkData[]> {
    const links = new Array<ElementLinkData>();
    const inputPhenomenon = this.getSchemaItemById(id);
    if (!inputPhenomenon || inputPhenomenon.schemaItemType !== SchemaItemType.Phenomenon) { return links; }
    const formatItems = this.getAllItemsOfType(await this.getSchemasThatAreOrReferenceSchema(inputPhenomenon.schema.name), SchemaItemType.Format) as Format[];
    for (const formatItem of formatItems) {
      const formatUnits = formatItem.units;
      if (formatUnits) {
        for (const [unitArr] of formatUnits) {
          const formatPhenom = await this.getPhenomenaFromUnit(unitArr);
          if (formatPhenom && formatPhenom === inputPhenomenon) {
            const data: ElementLinkData = {
              id: formatItem.fullName,
              name: formatItem.name,
              description: formatItem.description || "",
              schemaAlias: formatItem.schema.alias || "",
              schemaName: formatItem.schema.name,
              elementType: formatItem.schemaItemType,
            };
            links.push(data);
            break;
          }
        }
      }
    }
    return links;
  }

  /**
   * Get all Unit objects that use a Constant.
   * @param id A schema or SchemaItem's Id of type .
   * @returns An array of ElementLinkData objects.
   * @Important This function needs to be revisited later. The expression used by parseDefinition is not correct.
   */
  public async getUnitsThatUseConstant(id: string): Promise<ElementLinkData[]> {
    const links = new Array<ElementLinkData>();
    const inputConstant = this.getSchemaItemById(id);
    if (!inputConstant || inputConstant.schemaItemType !== SchemaItemType.Constant) { return links; }

    const units = this.getAllItemsOfType(await this.getSchemasThatAreOrReferenceSchema(inputConstant.schema.name), SchemaItemType.Unit) as Unit[];
    for (const unitItem of units) {
      const definitionArr = parseDefinition(unitItem.definition);
      definitionArr.forEach((objDef) => {
        if (objDef.constant && objDef.name === inputConstant.fullName) {
          const data: ElementLinkData = {
            id: unitItem.fullName,
            name: unitItem.name,
            description: unitItem.description || "",
            schemaAlias: unitItem.schema.alias || "",
            schemaName: unitItem.schema.name,
            elementType: unitItem.schemaItemType,
          };
          links.push(data);
        }
      });
    }
    return links;
  }
  // id -> StructClass
  public async getPropertiesThatUseStructClass(id: string): Promise<ElementLinkData[]> {
    const links = new Array<ElementLinkData>();
    const inputStruct = this.getSchemaItemById(id);

    for await (const prop of this.propertiesThatCouldContainReferenceToItem(inputStruct, SchemaItemType.StructClass)) {
      const structProp = prop as StructProperty;
      if (structProp.structClass === inputStruct) {
        links.push(this.createElementLinkDataForProperty(structProp));
      }
    }
    return links;
  }

  // id -> KindOfQuantity
  public async getPropertiesThatUseKoq(id: string): Promise<ElementLinkData[]> {
    const links = new Array<ElementLinkData>();
    const inputKoq = this.getSchemaItemById(id);

    for await (const prop of this.propertiesThatCouldContainReferenceToItem(inputKoq, SchemaItemType.KindOfQuantity)) {
      const propKOQ = await prop.kindOfQuantity;
      if (propKOQ === inputKoq) {
        links.push(this.createElementLinkDataForProperty(prop));
      }
    }
    return links;
  }

  // id -> Enumeration
  public async getPropertiesThatUseEnum(id: string): Promise<ElementLinkData[]> {
    const links = new Array<ElementLinkData>();
    const inputEnum = this.getSchemaItemById(id);

    for await (const prop of this.propertiesThatCouldContainReferenceToItem(inputEnum, SchemaItemType.Enumeration)) {
      const enumProp = prop as EnumerationProperty;
      const enumPropExtended = await enumProp.enumeration;
      if (enumPropExtended) {
        if (enumPropExtended === inputEnum) {
          links.push(this.createElementLinkDataForProperty(prop));
        }
      }
    }
    return links;
  }

  // id -> RelationshipClass
  public async getPropertiesThatUseRelationship(id: string): Promise<ElementLinkData[]> {
    const links = new Array<ElementLinkData>();
    const inputRelationship = this.getSchemaItemById(id);

    for await (const prop of this.propertiesThatCouldContainReferenceToItem(inputRelationship, SchemaItemType.RelationshipClass)) {
      const relationshipProp = prop as NavigationProperty;
      if (await relationshipProp.relationshipClass === inputRelationship) {
        links.push(this.createElementLinkDataForProperty(prop));
      }
    }
    return links;
  }

  // id -> Mixin
  public async getEntityClassesUsingMixin(id: string): Promise<ElementLinkData[]> {
    const links = new Array<ElementLinkData>();
    const inputMixin = this.getSchemaItemById(id);
    if (!inputMixin || inputMixin.schemaItemType !== SchemaItemType.Mixin) { return links; }

    const entityClasses = this.getAllItemsOfType(await this.getSchemasThatAreOrReferenceSchema(inputMixin.schema.name), SchemaItemType.EntityClass);
    for (const c of entityClasses) {
      const ec = c as EntityClass;
      const mixinsEC = ec.getMixinsSync();
      for (const mix of mixinsEC) {
        if (mix === inputMixin) {
          const data: ElementLinkData = {
            id: ec.fullName,
            name: ec.name,
            description: ec.description || "",
            schemaAlias: ec.schema.alias || "",
            schemaName: ec.schema.name || "",
            elementType: ec.schemaItemType,
          };
          links.push(data);
        }
      }
    }
    return links;
  }

  // id -> UnitSystem
  public async getUnitsUsedByUnitSystem(id: string): Promise<ElementLinkData[]> {
    const links: ElementLinkData[] = [];
    const inputUnitSystem = this.getSchemaItemById(id);
    if (!inputUnitSystem || inputUnitSystem.schemaItemType !== SchemaItemType.UnitSystem) { return links; }

    const possibleSchemas = await this.getSchemasThatAreOrReferenceSchema(inputUnitSystem.schema.name);

    const unitItems = this.getAllItemsOfType(possibleSchemas, SchemaItemType.Unit);

    const invertedUnitItems = this.getAllItemsOfType(possibleSchemas, SchemaItemType.InvertedUnit);
    for (const item of invertedUnitItems) {
      const invertedItem = item as InvertedUnit;
      const inverted = await invertedItem.invertsUnit;
      if (undefined !== inverted) {
        unitItems.push(inverted);
      }
    }

    for (const item of unitItems) {
      const unitItem = item as Unit;
      const unitUnitSystem = await unitItem.unitSystem;
      if (unitUnitSystem && unitUnitSystem === inputUnitSystem) {
        const data: ElementLinkData = {
          id: unitItem.fullName,
          name: unitItem.name,
          description: unitItem.description || "",
          schemaAlias: unitItem.schema.alias || "",
          schemaName: unitItem.schema.name,
          elementType: unitItem.schemaItemType,
        };
        links.push(data);
      }
    }
    return links;
  }

  // id -> Phenomenon
  public async getConstantsUsedByPhenomenon(id: string): Promise<ElementLinkData[]> {
    const links: ElementLinkData[] = [];
    const inputPhenomenon = this.getSchemaItemById(id);
    if (!inputPhenomenon || inputPhenomenon.schemaItemType !== SchemaItemType.Phenomenon) { return links; }

    const constants = this.getAllItemsOfType(await this.getSchemasThatAreOrReferenceSchema(inputPhenomenon.schema.name), SchemaItemType.Constant);
    for (const item of constants) {
      const constantItem = item as Constant;
      const constantPhenom = await constantItem.phenomenon;
      if (constantPhenom && constantPhenom === inputPhenomenon) {
        const data: ElementLinkData = {
          id: constantItem.fullName,
          name: constantItem.name,
          description: constantItem.description || "",
          schemaAlias: constantItem.schema.alias || "",
          schemaName: constantItem.schema.name,
          elementType: constantItem.schemaItemType,
        };
        links.push(data);
      }
    }
    return links;
  }

  // id -> Phenomenon
  public async getUnitsUsedByPhenomenon(id: string): Promise<ElementLinkData[]> {
    const links: ElementLinkData[] = [];
    const inputPhenomenon = this.getSchemaItemById(id);
    if (!inputPhenomenon || inputPhenomenon.schemaItemType !== SchemaItemType.Phenomenon) { return links; }

    const possibleSchemas = await this.getSchemasThatAreOrReferenceSchema(inputPhenomenon.schema.name);

    const unitItems = this.getAllItemsOfType(possibleSchemas, SchemaItemType.Unit);

    const invertedUnitItems = this.getAllItemsOfType(possibleSchemas, SchemaItemType.InvertedUnit);
    for (const item of invertedUnitItems) {
      const invertedItem = item as InvertedUnit;
      const inverted = await invertedItem.invertsUnit; // TODO: Is this correct?  Shouldn't it put the inverted unit here?
      if (undefined !== inverted) {
        unitItems.push(inverted);
      }
    }

    for (const item of unitItems) {
      const unitItem = item as Unit;
      const unitPhenom = await unitItem.phenomenon;
      if (unitPhenom && unitPhenom === inputPhenomenon) {
        const data: ElementLinkData = {
          id: unitItem.fullName,
          name: unitItem.name,
          description: unitItem.description || "",
          schemaAlias: unitItem.schema.alias || "",
          schemaName: unitItem.schema.name,
          elementType: unitItem.schemaItemType,
        };
        links.push(data);
      }
    }
    return links;
  }

  // passed a definition name and alias from the result of getDefinition()
  public async searchDefinitionTermByName(name: string, schemaAlias?: string): Promise<ElementLinkData> {
    // const elementLink: ElementLinkData = {schemaName: name, schemaAlias: (!schemaAlias) ? name : schemaAlias, id: name, name, description: name, elementType: name };

    const nameParts = name.split(":");
    const code = nameParts.length === 2 ? nameParts[1] : nameParts[0];
    const alias = nameParts.length === 2 ? nameParts[0] : schemaAlias;

    if (!alias) { return Promise.reject(`The schema alias could not be determined when retrieving the definition for the term '${name}'.`); }

    const allSchemas = await this.getLoadedSchemas();
    const schema = allSchemas.find((s) => s.alias === alias);
    if (!schema) {
      return Promise.reject(`Could not build link data for '${name}' because the schema with alias '${alias}' could not be found`);
    }

    const schemaItem = await schema.getItem(code);
    if (!schemaItem) {
      return Promise.reject(`Could not build link data for '${name}' because no item with that name was found in schema '${schema.fullName}'.`);
    }

    return { label: schemaItem.label, ...this.getElementLinkData(schemaItem.fullName) };
  }

  public async getDefinition(id: string): Promise<any> {
    const schemaItem = this.getSchemaItemById(id);

    if (!schemaItem) { return undefined; }

    if (schemaItem.schemaItemType === SchemaItemType.Unit) {
      const unitItem = schemaItem;
      return {
        definition: unitItem.definition,
        numerator: unitItem.numerator,
        denominator: unitItem.denominator,
        offset: unitItem.offset,
        alias: unitItem.schema.alias,
      };
    }

    if (schemaItem.schemaItemType === SchemaItemType.Constant) {
      const constantItem = schemaItem;
      return {
        definition: constantItem.definition,
        numerator: constantItem.numerator,
        denominator: constantItem.denominator,
        offset: null,
        alias: constantItem.schema.alias,
      };
    }

    if (schemaItem.schemaItemType === SchemaItemType.Phenomenon) {
      const phenomenonItem = schemaItem;
      return {
        definition: phenomenonItem.definition,
        numerator: null,
        denominator: null,
        offset: null,
        alias: phenomenonItem.schema.alias,
      };
    }
  }

  public async getAllSchemaItems(id: string): Promise<SchemaItemTableRowData[]> {
    const allSchemaItems: SchemaItemTableRowData[] = [];
    const schema: Schema | undefined = this.getSchemaById(id);

    if (schema) {
      Array.from(schema.getItems()).forEach((schemaItem: AnySchemaItem) => {
        allSchemaItems.push({
          name: this.getElementLinkData(schemaItem.fullName),
          displayLabel: schemaItem.label ? schemaItem.label : "",
          description: schemaItem.description ? schemaItem.description : "",
          itemType: schemaItemTypeToString(schemaItem.schemaItemType),
        });
      });
    }
    return allSchemaItems;
  }

  public async getPropertyTableRowDataFromProperty(property: AnyProperty): Promise<PropertyTableRowData> {
    const baseProp = async () => {
      const baseClassProperty = await property.class.getInheritedProperty(property.name);
      if (undefined !== baseClassProperty) {
        const classLink = this.getElementLinkData(baseClassProperty.class.fullName);
        return {
          ...classLink,
          propertyName: property.name,
          propertyType: property.propertyType,
        };
      }
      return undefined;
    };
    return {
      name: {
        icon: property.propertyType,
        name: property.name,
      },
      owningClass: this.getElementLinkData(property.class.fullName),
      description: property.description || "",
      typeInfo: {
        isArray: property.isArray(),
        primitiveType: property.isPrimitive() ? {
          id: property.primitiveType,
          ext: property.extendedTypeName || "",
        } : undefined,
        struct: property.isStruct() ? this.getElementLinkData(property.structClass.fullName) : undefined,
        // TODO: Remove suppression once EnumerationProperty.enumeration is no longer nullable
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        enumeration: property.isEnumeration() ? this.getElementLinkData((await property.enumeration)!.fullName) : undefined,
        nav: property.isNavigation() ? await this.getNavPropertyLinkData(property) : undefined,
      },
      displayLabel: property.label || "",
      baseProperty: await baseProp(),
      kindOfQuantity: property.kindOfQuantity ? this.getElementLinkData((await property.kindOfQuantity).fullName) : undefined,
      arrayBounds: property.isArray() ? {
        min: property.minOccurs,
        max: property.maxOccurs,
      } : undefined,
      isReadonly: property.isReadOnly,
    };
  }

  public async getClassProperties(id: string): Promise<{ id: string, properties: PropertyTableRowData[] }> {
    const properties: PropertyTableRowData[] = [];
    const schemaClass = this.getSchemaItemById(id) as ECClass;

    if (!schemaClass || schemaClass.schemaItemType > 4) { return { id, properties }; }
    const classProperties = await schemaClass.getProperties();
    for (const prop of classProperties) {
      const classProp = prop as AnyProperty;
      properties.push(await this.getPropertyTableRowDataFromProperty(classProp));
    }

    return { id, properties };
  }

  public getEnumerators(id: string): EnumeratorTableRowData[] {
    const tableData = Array<EnumeratorTableRowData>();
    const inputClass = this.getSchemaItemById(id) as Enumeration;

    if (!inputClass) { return tableData; }

    const enumArray = inputClass.enumerators;
    if (undefined !== enumArray) {
      for (const e of enumArray) {
        const data: EnumeratorTableRowData = {
          id,
          name: e.name,
          value: e.value.toString(),
          displayLabel: e.label || "",
          description: e.description || "",
        };
        tableData.push(data);
      }
    }
    return tableData;
  }

  private static getStrengthText(end: RelationshipEnd, strength: StrengthType, strengthDir: StrengthDirection) {
    const reversed = (RelationshipEnd.Source === end && StrengthDirection.Backward === strengthDir)
      || (RelationshipEnd.Target === end && StrengthDirection.Forward === strengthDir);

    switch (strength) {
      case StrengthType.Referencing:
        return (reversed) ? "Referenced by" : "References";
      case StrengthType.Holding:
        return (reversed) ? "Held by" : "Holds";
      case StrengthType.Embedding:
        return (reversed) ? "Embedded by" : "Embeds";
    }

    throw new Error("Unknown Strength!");
  }

  private static async formatClassRelationships(rows: ClassRelationshipsDetails[]): Promise<ClassRelationshipsTreeData> {
    const results: ClassRelationshipsTreeData = {};
    for (const row of rows) {
      const root = this.getStrengthText(row.relEnd, row.strength, row.strengthDir);
      if (!results[root])
        results[root] = [];

      results[root].push({
        id: row.relId + row.relEnd,
        roleLabel: row.roleLabel,
        relationship: ImseFrontend.instance.getElementLinkData(row.relId),
        relatedClass: ImseFrontend.instance.getElementLinkData(row.endpointId),
        selectable: () => false,
      });
    }
    return results;
  }

  // missing a couple relationships, but I wonder if this is maybe because we are still missing some things in the schemas loaded in
  public async getClassRelationships(id: string): Promise<ClassRelationshipsTreeData> {
    const results = new Array<ClassRelationshipsDetails>();
    const inputClass = this.getSchemaItemById(id) as AnyClass;
    if (inputClass === undefined) {
      return {};
    }
    // NOTE: Not filtering schemas by reference here because a relationship can support a class by having a base class as a constraint and being polymorphic
    const schemaItems = this.getAllItemsOfType(this._schemas, SchemaItemType.RelationshipClass);
    for (const item of schemaItems) {
      const relItem = item as RelationshipClass;
      const relTarget = relItem.target;
      const relSource = relItem.source;

      if (relTarget && await relTarget.supportsClass(inputClass)) {
        const targetData: ClassRelationshipsDetails = {
          relId: relItem.fullName,
          relLabel: relItem.label || "",
          strength: relItem.strength,
          strengthDir: relItem.strengthDirection,
          roleLabel: relTarget.roleLabel || "",
          endpointId: (await relSource.abstractConstraint)?.fullName ?? "",
          relEnd: relTarget.relationshipEnd,
        };
        results.push(targetData);
      }
      if (relSource && await relSource.supportsClass(inputClass)) {
        const sourceData: ClassRelationshipsDetails = {
          relId: relItem.fullName,
          relLabel: relItem.label || "",
          strength: relItem.strength,
          strengthDir: relItem.strengthDirection,
          roleLabel: relSource.roleLabel || "",
          endpointId: (await relTarget.abstractConstraint)?.fullName ?? "",
          relEnd: relSource.relationshipEnd,
        };
        results.push(sourceData);
      }
    }
    return ImseFrontend.formatClassRelationships(results);
  }

  public async getAspectsForClass(id: string): Promise<ECClass[]> {
    const aspectsForClass: ECClass[] = [];
    const inputClass = this.getSchemaItemById(id) as AnyClass;

    if (inputClass === undefined) {
      return aspectsForClass;
    }
    // Get aspectRelationships that supports the class
    const aspectRelationships = await this.getAllAspectRelationshipsSupportsClass(inputClass);

    await Promise.all(aspectRelationships.map(async (relationship: RelationshipClass) => {
      const constraintClasses = relationship.target.constraintClasses;
      if (!constraintClasses) return;
      for (const lazyConstraint of constraintClasses) {
        aspectsForClass.push(await lazyConstraint);
      }
    }));
    return aspectsForClass;
  }
  /**
   * Iteratively goes through all relationship classes and returning those that supports the inputClass.
   * @param inputClass
   * @returns An array of RelationshipClass
   */
  public async getAllAspectRelationshipsSupportsClass(inputClass: AnyClass): Promise<RelationshipClass[]> {
    const aspectRelationships: RelationshipClass[] = [];
    const stack: ECClassExtended[] = [];
    const elementOwnsMultiAspect = this._extendedClassCache.get("BisCore.ElementOwnsMultiAspects");
    const elementOwnsUniqueAspect = this._extendedClassCache.get("BisCore.ElementOwnsUniqueAspect");

    if (!elementOwnsMultiAspect || !elementOwnsUniqueAspect) { return aspectRelationships; }

    stack.push(elementOwnsMultiAspect, elementOwnsUniqueAspect);
    while (stack.length !== 0) {
      const currRelationshipExtended = stack.pop();
      const currRelationship = currRelationshipExtended!.thisClass as RelationshipClass;
      if (await currRelationship.source.supportsClass(inputClass)) {
        aspectRelationships.push(currRelationship);
      }
      currRelationshipExtended!.subClasses.map(async (subClass: ECClassExtended) => {
        stack.push(subClass);
      });
    }
    return aspectRelationships;
  }
  /**
   * Get all properties of an aspect.
   * We exclude properties from base classes of the aspect.
   */
  public async getPropertiesOfAspect(aspect: ECClass): Promise<Property[]> {
    const properties = await aspect.getProperties();
    return properties.filter((property) => (property.class.name === aspect.name && (property.class.name !== "ElementMultiAspect" && property.class.name !== "ElementUniqueAspect")));
  }

  /**
   * Gets the aspect properties of a class, if it has any.
   */
  public async getAllAspectPropertiesOfClass(id: string): Promise<Property[]> {
    const results: Property[] = [];
    const aspects = await this.getAspectsForClass(id);
    for (const aspect of aspects) {
      const aspectProps = await this.getPropertiesOfAspect(aspect);
      for (const property of aspectProps) {
        results.push(property);
      }
    }
    return results;
  }

  public async getAspectProperties(id: string): Promise<{ id: string, properties: PropertyTableRowData[] }> {
    const properties: PropertyTableRowData[] = [];

    if (id === "") { // Handles case where on start up and componentDidMount, the id passed in is from _defaultState.
      return { id, properties };
    }
    const aspectProps = await this.getAllAspectPropertiesOfClass(id);
    for (const prop of aspectProps) {
      const aspectProp = prop as AnyProperty;
      properties.push(await this.getPropertyTableRowDataFromProperty(aspectProp));
    }

    return { id, properties };
  }
}
