/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import * as React from "react";
import { ImseFrontend } from "frontend/api/ImseFrontend";
import { ElementAttributes, PhenomenonAttributes } from "frontend/api/Interfaces";
import { UsesCard } from "frontend/components/stages/classbrowser/cards/UsesCard";

type Props = ElementAttributes;
type State = Partial<PhenomenonAttributes>;

export class PhenomenonDetails extends React.Component<Props, State> {
  private static _dataProviders = {
    Constants: async (id: string) => ImseFrontend.instance.getConstantsUsedByPhenomenon(id),
    Units: async (id: string) => ImseFrontend.instance.getUnitsUsedByPhenomenon(id),
    KindOfQuantities: async (id: string) => ImseFrontend.instance.getKoqThatUsePhenomenon(id),
    Formats: async (id: string) => ImseFrontend.instance.getFormatsThatUsePhenomenon(id),
  };

  private static _defaultState = {
  };

  public override readonly state: State = PhenomenonDetails._defaultState;
  public initialized?: Promise<any>;

  private async fetchData() {
    const data = ImseFrontend.instance.getSchemaItemAttributes(this.props.id) as PhenomenonAttributes;
    this.setState({...PhenomenonDetails._defaultState, ...data});
  }

  public override componentDidMount() {
    this.initialized = this.fetchData();
  }

  public override componentDidUpdate(prevProps: Props) {
    if (prevProps.id !== this.props.id)
      this.initialized = this.fetchData();
  }

  public override render() {

    return (
      <div className="details-page">
        <div className="element-attributes">
        </div>
        <div className="card-container">
          <UsesCard title="Units:" dataProvider={PhenomenonDetails._dataProviders.Units} id={this.props.id} />
          <UsesCard title="Constants:" dataProvider={PhenomenonDetails._dataProviders.Constants} id={this.props.id} />
          <UsesCard title="Used by kind of quantities:" dataProvider={PhenomenonDetails._dataProviders.KindOfQuantities} id={this.props.id} />
          <UsesCard title="Used by formats:" dataProvider={PhenomenonDetails._dataProviders.Formats} id={this.props.id} />
        </div>
      </div>
    );
  }
}
