/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2019 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
import { RootState } from "frontend/state/rootReducer";

/**
 * Class wrapper for Redux selector functions which select data from the RootState store.
 */
export class Selectors {

  /**
   * Asserts that an argument within the messageArgs[] of an ItemDiagnostic is defined.
   */
  public static assertDefined<T>(value: T | null | undefined, message?: string): T {
    if (value === undefined || value === null)
      return message as never;
    return value;
  }

  /**
   * Formats a message string, replacing /{\d+}/ objects with arguments in messageArgs[].
   * @returns string
   */
  public static formatStringFromArgs(text: string, args: ArrayLike<string>, baseIndex = 0): string {
    return text.replace(/{(\d+)}/g, (_match, index: string) => Selectors.assertDefined(args[+index + baseIndex]));
  }

  /**
   * Selects the current element id from the location object.
   * @returns string | undefined
   */
  public static elementIdSelector(state: RootState): string | undefined {
    return state.location.current.id;
  }

  /**
   * Selects the current stage from the location object.
   * @returns string | undefined
   */
  public static frontstageNameSelector(state: RootState): string | undefined {
    return state.location.current.stage;
  }

  /**
   * Selects the active tab from the location object.
   * @returns string | undefined
   */
  public static activeTabSelector(state: RootState): string | undefined {
    return state.location.tab;
  }

  /**
   * Selects the distance from the left side of the window to the NavTree splitter from the splitters object.
   * @returns number
   */
  public static navTreeSizeSelector(state: RootState): number {
    return state.splitters.navTreeSize;
  }

  /**
   * Selects the width of the right pane of the NavTree splitter.
   * @returns number
   */
  public static contentWidthSelector(state: RootState): number {
    return state.splitters.contentSize;
  }
}
